import React from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { TextFieldProps as TextfieldPropsType } from '@mui/material/TextField';
import { DatePicker, DatePickerProps as DatePickerPropsType } from '@mui/x-date-pickers';

type Props = {
  DatePickerProps?: Partial<DatePickerPropsType<Date>>;
  TextFieldProps?: TextfieldPropsType;
  defaultValue?: Date;
  name: string;
};

const HookFormDatePicker = ({
  name,
  defaultValue = new Date(),
  DatePickerProps = {},
  TextFieldProps = {},
}: Props) => {
  const {
    formState: { errors },
  } = useFormContext();
  const error = errors[name];
  const displayError = Boolean(error?.message);

  return (
    <>
      <Controller
        name={name}
        defaultValue={defaultValue}
        render={({ field: { onChange, value } }) => (
          <DatePicker
            {...DatePickerProps}
            onChange={onChange}
            value={value}
            orientation="landscape"
            slotProps={{
              textField: {
                helperText: TextFieldProps.helperText,
                error: displayError,
              },
            }}
          />
        )}
      />
    </>
  );
};

export default HookFormDatePicker;
