import { CognitoUser } from 'amazon-cognito-identity-js';
import { SignOutParams, UpdateCognitoContext } from './types';

// eslint-disable-next-line max-len
const signOut =
  (user: CognitoUser, updateCognitoContext: UpdateCognitoContext) =>
  ({ onSuccess, onFailure }: SignOutParams) => {
    user.globalSignOut({
      onSuccess() {
        updateCognitoContext({
          userAttributes: undefined,
          user: undefined,
          userSession: undefined,
          authenticated: false,
          newPasswordRequired: false,
        });

        onSuccess();
      },

      onFailure(err) {
        onFailure(err.message);
      },
    });
  };

export default signOut;
