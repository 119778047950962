/* eslint-disable import/prefer-default-export */
import { useMutation } from '@tanstack/react-query';
import { AxiosResponse, get } from '@truefit/http-utils';

const useClipDownloadMutation = () =>
  useMutation({
    mutationFn: async ({ clipId, cameraId }: { clipId: number; cameraId: number }) => {
      if (cameraId) {
        const {
          data: { downloadKey },
        }: AxiosResponse = await get(`/clips/${clipId}/download?cameraId=${cameraId}`, () => ({
          maxRedirects: 0,
        }));

        window.open(downloadKey, '_blank');
      } else {
        const {
          data: { downloadKey },
        }: AxiosResponse = await get(`/clips/${clipId}/download?metadata=true`, () => ({
          maxRedirects: 0,
        }));

        window.open(downloadKey, '_blank');
      }
    },
    gcTime: 0,
  });

export default useClipDownloadMutation;
