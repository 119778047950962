import React, { MouseEvent, MouseEventHandler, ReactNode, useCallback } from 'react';
import { Controller, useFormContext } from 'react-hook-form';

import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import { FilledInputProps } from '@mui/material/FilledInput';
import { FormHelperTextProps as FormHelperTextPropsType } from '@mui/material/FormHelperText';
import IconButton from '@mui/material/IconButton';
import { InputProps as StandardInputProps } from '@mui/material/Input';
import InputAdornment from '@mui/material/InputAdornment';
import { InputLabelProps as InputLabelPropsType } from '@mui/material/InputLabel';
import { OutlinedInputProps } from '@mui/material/OutlinedInput';
import TextField from '@mui/material/TextField';

type Props = {
  name: string;
  autoComplete?: string;
  autoFocus?: boolean;
  defaultValue?: string;
  disabled?: boolean;
  FormHelperTextProps?: Partial<FormHelperTextPropsType>;
  fullWidth?: boolean;
  helperText?: ReactNode;
  InputLabelProps?: Partial<InputLabelPropsType>;
  InputProps?:
    | Partial<StandardInputProps>
    | Partial<FilledInputProps>
    | Partial<OutlinedInputProps>;
  label?: ReactNode;
  margin?: 'none' | 'dense' | 'normal';
  placeholder?: string;
  required?: boolean;
  size?: 'small' | 'medium';
  variant?: 'standard' | 'outlined' | 'filled';
  onEnter?: MouseEventHandler<HTMLButtonElement>;
};

const HookFormPasswordField = ({
  autoComplete,
  autoFocus= false,
  defaultValue = '',
  disabled= false,
  fullWidth = true,
  helperText='',
  InputLabelProps,
  InputProps,
  label='',
  margin = 'normal',
  name,
  onEnter = () => {},
  placeholder,
  required,
  size= 'medium',
  variant = 'outlined',
}: Props) => {
  const {
    formState: { errors },
  } = useFormContext();

  const [showPassword, setShowPassword] = React.useState(false);
  const error = errors[name];
  const displayError = Boolean(error?.message);

  const overriddenInputProps = InputProps ?? {};

  const onVisibilityChangePasswordHandler = useCallback(
    (event: MouseEvent<HTMLButtonElement>) => {
      event.stopPropagation();
      event.preventDefault();
      setShowPassword(!showPassword);
    },
    [showPassword],
  );

  overriddenInputProps.endAdornment = <InputAdornment position="end" />;

  const handleKeyPress = (event) => {
    if (event.key === 'Enter') {
      event.preventDefault(); // Prevent default form submission
      onEnter(event);
    }
  };

  const magicId = `${showPassword}_${name}`;
  return (
    <div style={{ position: 'relative' }}>
      <Controller
        name={name}
        defaultValue={defaultValue}
        render={({ field: { onChange, onBlur, value, ref } }) => (
          <TextField
            ref={ref}
            name={name}
            autoComplete={autoComplete}
            autoFocus={autoFocus}
            disabled={disabled}
            error={displayError}
            fullWidth={fullWidth}
            helperText={displayError ? String(error.message) : helperText}
            id={magicId}
            InputLabelProps={InputLabelProps}
            InputProps={overriddenInputProps}
            label={label}
            margin={margin}
            onBlur={onBlur}
            onChange={onChange}
            onKeyUp={handleKeyPress}
            placeholder={placeholder}
            required={required}
            size={size}
            type={showPassword ? 'text' : 'password'}
            value={value ?? ''}
            variant={variant}
          />
        )}
      />
      <div style={{ position: 'absolute', right: 10, top: '25%' }}>
        <IconButton
          aria-label="toggle password visibility"
          onClick={onVisibilityChangePasswordHandler}
        >
          {showPassword ? <Visibility /> : <VisibilityOff />}
        </IconButton>
      </div>
    </div>
  );
};

export default HookFormPasswordField;
