import { useQuery } from '@tanstack/react-query';
/* eslint-disable import/prefer-default-export */
import { get } from '@truefit/http-utils';

export type User = {
  tz?: string;
  email?: string;
  lastSeen?: string;
  firstName?: string;
  lastName?: string;
};

const getCurrentUserQuery = () =>
  useQuery({
    queryKey: ['User'],
    queryFn: async () => get<User>('user'),
    enabled: false,
  });

export default getCurrentUserQuery;
