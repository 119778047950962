import React from 'react';
import { Link as RouterLink } from 'react-router-dom';

import Link from '@mui/material/Link';
import Typography from '@mui/material/Typography';
import { Loading } from '@/shared/components';

import useCognito from '../hooks/useCognito';
import AuthenticationPage from './AuthenticationPage';
import ResetPasswordForm from './ResetPasswordForm';

type MainContentProps = {
  loading: boolean;
  newPasswordRequired: boolean;
};

const PasswordRequired = () => (
  <>
    <Typography variant="body1" sx={{ my: 3 }}>
      Enter your new password and click Submit.
    </Typography>

    <ResetPasswordForm />
  </>
);

const NewPasswordNotRequired = () => (
  <Typography>
    New password not required. Click.{' '}
    <Link component={RouterLink} to="/">
      here
    </Link>{' '}
    to return to log in.
  </Typography>
);

const MainContent = ({ loading, newPasswordRequired }: MainContentProps) => {
  if (loading) return <Loading />;
  if (newPasswordRequired) return <PasswordRequired />;
  return <NewPasswordNotRequired />;
};
const ResetPasswordPage = () => {
  const { newPasswordRequired } = useCognito();
  const isLoading = false;

  return (
    <AuthenticationPage>
      <Typography variant="h6">Reset Password</Typography>
      <MainContent loading={isLoading} newPasswordRequired={newPasswordRequired} />
    </AuthenticationPage>
  );
};

export default ResetPasswordPage;
