import { useHotkeys } from 'react-hotkeys-hook';

import { KeyboardShortcuts } from '@/shared/constants/keyboardShortcuts';

import { CameraPtzAdjustment } from '../types';

const useCameraPtzKeyboardBindings = (onPtzChange: (adjustment: CameraPtzAdjustment) => void) => {
  useHotkeys(
    KeyboardShortcuts.TiltUp.hotkey,
    (e) => {
      e.preventDefault();
      onPtzChange({ tiltDiff: 1 });
    },
    [onPtzChange],
  );
  useHotkeys(
    KeyboardShortcuts.TiltDown.hotkey,
    (e) => {
      e.preventDefault();
      onPtzChange({ tiltDiff: -1 });
    },
    [onPtzChange],
  );
  useHotkeys(
    KeyboardShortcuts.PanLeft.hotkey,
    (e) => {
      e.preventDefault();
      onPtzChange({ panDiff: -1 });
    },
    [onPtzChange],
  );
  useHotkeys(
    KeyboardShortcuts.PanRight.hotkey,
    (e) => {
      e.preventDefault();
      onPtzChange({ panDiff: 1 });
    },
    [onPtzChange],
  );
  useHotkeys(
    KeyboardShortcuts.ZoomIn.hotkey,
    (e) => {
      e.preventDefault();
      onPtzChange({ zoomDiff: -5 });
    },
    [onPtzChange],
  );
  useHotkeys(
    KeyboardShortcuts.ZoomOut.hotkey,
    (e) => {
      e.preventDefault();
      onPtzChange({ zoomDiff: 5 });
    },
    [onPtzChange],
  );
};

export default useCameraPtzKeyboardBindings;
