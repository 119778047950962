import * as React from 'react';

import SlowMotionVideoIcon from '@mui/icons-material/SlowMotionVideo';
import { Box, Slider, Typography } from '@mui/material';
import IconButton from '@mui/material/IconButton';
import Menu from '@mui/material/Menu';
import { usePlaybackSpeedState } from '@/shared/hooks/websocket/usePlaybackSpeed';

const MAX_SPEED = 5;
type Props = { disabled?: boolean };

const PlaybackSpeedSelectionButton = ({ disabled = false}: Props) => {
  const [playbackSpeed, setPlaybackSpeed] = usePlaybackSpeedState();

  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <>
      <IconButton
        onClick={(event) => setAnchorEl(event.currentTarget)}
        size="small"
        aria-controls={open ? 'playback-speed-menu' : undefined}
        aria-haspopup="true"
        aria-expanded={open ? 'true' : undefined}
        disabled={disabled}
        sx={{ color: open ? 'text.primary' : 'text.secondary' }}
      >
        <SlowMotionVideoIcon />
      </IconButton>

      <Menu
        disablePortal
        anchorEl={anchorEl}
        id="playback-speed-menu"
        open={open}
        onClose={handleClose}
        onClick={handleClose}
        PaperProps={{
          elevation: 24,
        }}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
        sx={(theme) => ({
          // Make sure the menu shows above the tooltip for the button
          zIndex: theme.zIndex.tooltip + 1,
        })}
      >
        <Box width={400} px={3} py={1}>
          <Typography variant="subtitle1" textAlign="center">
            Playback Speed
          </Typography>
          <Slider
            min={-MAX_SPEED}
            max={MAX_SPEED}
            step={0.05}
            track={false}
            value={playbackSpeed}
            size="small"
            onChange={(_e, newVal) => {
              if (Array.isArray(newVal)) throw new Error('Not Implemented');
              setPlaybackSpeed(newVal);
            }}
            marks={[
              { value: 0, label: '0' },
              { value: 1, label: '1x' },
              { value: -1, label: '-1x' },
              { value: MAX_SPEED, label: `${MAX_SPEED}x` },
              { value: -MAX_SPEED, label: `-${MAX_SPEED}x` },
            ]}
          />
          <Typography variant="subtitle2" textAlign="center">
            {playbackSpeed.toFixed(2)}x
          </Typography>
        </Box>
      </Menu>
    </>
  );
};


export default PlaybackSpeedSelectionButton;
