export function getRandomPastelColor(char1: string, char2: string): string {
  if (!char1 || !char2) return '#333';
  const seed = char1.charCodeAt(0) + char2.charCodeAt(0);
  const randomHue = (seed % 360) + 30; // Offset to avoid very similar colors
  const randomLightness = 70 + (seed % 5) * 5; // Vary lightness slightly

  return `hsl(${randomHue}, 100%, ${randomLightness}%)`;
}

export default getRandomPastelColor;
