import React from 'react';
import { Link as RouterLink } from 'react-router-dom';

import Button from '@mui/material/Button';
import Divider from '@mui/material/Divider';
import Grid from '@mui/material/Grid';
import { Theme } from '@mui/material/styles';
import { makeStyles } from '@mui/styles';
import { LoadingButton } from '@mui/lab';

type Props = {
  submitting: boolean;
  buttonText?: string;
  cancelTo?: string;
  cancelHref?: string;
  onCancel?: () => void;
  hasDivider?: boolean;
  onSubmit: (e?) => void;
  cancelButtonStyle?: React.CSSProperties;
  mainButtonStyle?: React.CSSProperties;
};

type CancelButtonProps = {
  cancelTo?: string;
  cancelHref?: string;
  onCancel?: () => void;
  classes: {
    button: string;
    cancelButton: string;
  };
  style: React.CSSProperties;
};

const useStyles = makeStyles((theme: Theme) => ({
  button: {
    marginLeft: theme.spacing(1),
    color: '#000',
    backgroundColor: '#fff',
    transition: 'all 0.3s ease-in-out',
    '&:hover': {
      backgroundColor: '#fff',
      opacity: 0.5,
    },
  },
  cancelButton: {
    marginLeft: theme.spacing(1),
    color: theme.palette.secondary.main,
    backgroundColor: 'transparent',
    transition: 'all 0.3s ease-in-out',
    '&:hover': {
      backgroundColor: 'transparent',
      opacity: 0.5,
    },
  },
}));

const CancelButton = ({ cancelTo, cancelHref, onCancel, classes, style }: CancelButtonProps) => {
  if (onCancel) {
    return (
      <Grid item>
        <Button
          className={classes.cancelButton}
          variant="outlined"
          onClick={onCancel}
          color="secondary"
          style={style}
        >
          Cancel
        </Button>
      </Grid>
    );
  }
  if (cancelTo) {
    return (
      <Grid item>
        <Button
          className={classes.cancelButton}
          component={RouterLink}
          to={cancelTo}
          color="secondary"
          style={style}
        >
          Cancel
        </Button>
      </Grid>
    );
  }

  if (cancelHref) {
    return (
      <Grid item>
        <Button className={classes.cancelButton} href={cancelHref} color="secondary" style={style}>
          Cancel
        </Button>
      </Grid>
    );
  }

  return null;
};


const FormSubmitBar = ({
  submitting,
  cancelTo,
  cancelHref,
  onCancel,
  buttonText,
  hasDivider,
  onSubmit,
  mainButtonStyle,
  cancelButtonStyle,
}: Props) => {

  const classes = useStyles();

  return (
    <>
      {hasDivider && <Divider sx={{ mt: 2 }} />}
      <Grid container justifyContent="center" alignItems="center" sx={{ mt: 5 }}>
        <CancelButton
          cancelTo={cancelTo}
          cancelHref={cancelHref}
          onCancel={onCancel}
          classes={classes}
          style={cancelButtonStyle}
        />

        <LoadingButton
          loading={submitting}
          className={classes.button}
          color="primary"
          disabled={submitting}
          onClick={onSubmit}
          style={mainButtonStyle}
        >
          {buttonText || 'Submit'}
        </LoadingButton>
      </Grid>
    </>
  );
};

export default FormSubmitBar;
