import { useContext } from 'react';

import { KeyboardShortcutsDialogContext } from '../components/KeyboardShortcutsDialogProvider';

const useKeyboardShortcutsDialog = () => {
  const { showing, setShowing } = useContext(KeyboardShortcutsDialogContext);

  return {
    showing,
    show: () => setShowing(true),
    hide: () => setShowing(false),
  };
};

export default useKeyboardShortcutsDialog;
