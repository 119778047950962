import { useQuery } from '@tanstack/react-query';
/* eslint-disable import/prefer-default-export */
import { get } from '@truefit/http-utils';

export const queryKey = ['AailableTags'];

const useTagsQuery = () =>
  useQuery({
    queryKey,
    queryFn: async () => {
      const { data } = await get('tags');
      return data;
    },
    placeholderData: [],
  });

export default useTagsQuery;
