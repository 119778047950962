import { useInfiniteQuery, useQuery } from '@tanstack/react-query';
/* eslint-disable import/prefer-default-export */
import { get } from '@truefit/http-utils';
import { SessionData } from '../types';

export function getQueryKey(orgId) {
  return ['AllUsers', orgId];
}
const LIMIT = 5;
const fetchUserSessions = async ({ pageParam = null }) => {
  const urlSearchParams = new URLSearchParams();

  if (pageParam) {
    urlSearchParams.set('cursor', pageParam);
    urlSearchParams.set('limit', LIMIT);
  }

  const { data } = await get<SessionData[]>(`user/sessions?${urlSearchParams.toString()}`);
  return data;
};

const getNextPageParam = (lastPage) => {
  return lastPage.hasNextPage ? lastPage.nextCursor : undefined;
};

const useAccountSessionsQuery = () => {
  return useInfiniteQuery({
    queryKey: ['userSessions'],
    queryFn: fetchUserSessions,
    enabled: true,
    initialPageParam: 0,
    getNextPageParam,
  });
};

export default useAccountSessionsQuery;
