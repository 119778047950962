import { useCallback, useEffect, useMemo, useRef, useState } from 'react';

import { Tracking } from '@/shared/services/tracking';

// features imports
import { AutoIsoId } from '@/features/autoIso/types';
import useCamerasQuery from '@/features/camera/hooks/useCamerasQuery';

// websocket imports
import { useGameStateRef } from './websocket/useGameState';
import { useSelectCamRef } from './websocket/useSelectCam';
import { useSelectMosaicRef } from './websocket/useSelectMosaic';
import { usePTZStore } from './store/usePTZStore';

import useCurrentGameId from './useCurrentGameId';
import { Cameras } from '@/features/camera/types/CameraSettingsState';
import { ServerState, ViewType } from '@/features/camera/types/VideoSource';
import { CameraModel } from '@/features/camera/types/CameraSettingsModel';
import { usePTZSocket } from './websocket/usePTZSocket';

export const DEFAULT_CAMERA_SETTINGS_STATE: Cameras = [];

export const NO_TRACKING_ID = 'none';
export const MOSAIC_VIEW = ViewType.MOSAIC;

export const DEFAULT_SERVER_STATE: ServerState = {
  cameras: [],
  selectedCamera: 0,
  viewType: ViewType.MOSAIC,
};

const handleTrackingEntityUpdated = (currentState: Cameras, trackingId: AutoIsoId): Cameras => {
  currentState.forEach((camera) => {
    camera.trackingId = trackingId;
  });
  return currentState;
};

const ViewSourceToViewType = (source: string): ViewType => {
  switch (source) {
    case 'mosaic':
      return ViewType.MOSAIC;
    case 'autoTally':
      return ViewType.AUTO_TALLY;
    case 'single':
      return ViewType.SINGLE_CAMERA;
    default:
      return ViewType.SINGLE_CAMERA;
  }
};

/**
 * Supplies state tracking on top of the {@link useServerConnection} hook.
 * @returns functions for altering the server's state
 */
const useServerState = () => {
  const gameId = useCurrentGameId();

  // Since the API currently doesn't provide a way for us to know what video source the
  // server is starting with when the app loads, we need to manage the cameras query here.
  const cameraSettingsStateQuery = useCamerasQuery();
  const [serverState, setServerState] = useState<ServerState>(DEFAULT_SERVER_STATE);

  //websocket operations
  const [, setSelectCam] = useSelectCamRef();
  const [, setSelectMosaic] = useSelectMosaicRef();

  const { init: initPTZStore } = usePTZStore();
  const { reset: resetCameraPositions } = usePTZSocket();

  // handleChange on the State
  const { handleChange: updatePTZstore } = usePTZStore();

  useGameStateRef({
    onChange: (gameState) => {
      if (gameState) {
        const {
          view: { type, idx },
          vcam,
        } = gameState;

        if (typeof gameState === 'undefined') return;

        const selectedIdx = typeof idx === 'number' ? idx : Number.parseInt(idx, 10);

        if (vcam) {
          updatePTZstore(vcam, selectedIdx);
        }

        setServerState((currentState) => ({
          ...currentState,
          selectedCamera: selectedIdx,
          viewType: ViewSourceToViewType(type),
        }));
      }
    },
  });

  const selectMosaic = (source: string) => {
    setSelectMosaic({ dims: { width: 0, height: 0 }, idxs: [] });

    Tracking.getInstance().track('Switch View', {
      source,
      category: 'Controls',
      type: 'mosaic',
    });
  };

  const selectCamera = (idx: number, source: string) => {
    setSelectCam(idx);

    Tracking.getInstance().track('Switch View', {
      source,
      category: 'Controls',
      type: 'single',
    });

  };

  const trackEntity = (ent: any, receivedSource?: string) => {
    let source: string = receivedSource;
    if (!ent) return;
    if (ent === 'none') {
      source = 'Manual Tracking';
    }

    let entity = ent;

    if (ent && !ent.id) {
      entity = { id: ent };
    }
    if (entity.id === 'none') {
      entity.type = 'Manual';
    }
    if (parseInt(entity.id, 10) === 1) {
      entity.type = 'BallOrPuck';
    }

    const { id } = entity;
    // TODO: use the websocket to set the tracking entity
    //  await put('tracking/entity', { entityId: id });

    if (source) {
      Tracking.getInstance().track('Switch Tracked Entity', {
        category: 'Iso',
        source,
        entity,
      });
    }

    setServerState((currentState) => ({
      ...currentState,
      cameras: handleTrackingEntityUpdated(currentState.cameras, id),
    }));
  };

  useEffect(() => {
    cameraSettingsStateQuery.refetch();
  }, []);

  useEffect(() => {
    if (
      typeof gameId === 'number' &&
      cameraSettingsStateQuery.status === 'success' &&
      cameraSettingsStateQuery.data.data
    ) {
      initPTZStore(cameraSettingsStateQuery.data.data);

      resetCameraPositions();

      setServerState((currentState) => ({
        ...currentState,
        cameras: cameraSettingsStateQuery.data.data || DEFAULT_CAMERA_SETTINGS_STATE,
      }));
    }
  }, [cameraSettingsStateQuery.data, gameId]);

  const singleCurrentlyTrackedObjectId = useMemo(() => {
    if (!serverState.cameras) {
      return NO_TRACKING_ID;
    }

    const { selectedCamera, viewType } = serverState;
    if (viewType === ViewType.SINGLE_CAMERA) {
      return serverState.cameras[selectedCamera]?.trackingId || NO_TRACKING_ID;
    }

    // If no camera is selected as the current video source (i.e. you're in quad
    // view mode) then for situations that aren't camera-specific (e.g. the playing
    // surface view), just show one of the currently tracked objects rather than
    // saying nothing is selected. Since 95% of the time the cameras will all be
    // tracking the same object (according to our current impl) this impl will only
    // rarely fail to show the complete picture of what's being tracked.
    return (
      Object.values(serverState.cameras).filter(
        (c: CameraModel) => c.trackingId && c.trackingId !== NO_TRACKING_ID,
      )[0]?.trackingId || NO_TRACKING_ID
    );
  }, [serverState.cameras, serverState.selectedCamera]);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const serverStateAndFunctions = {
    availableCameras: serverState.cameras,
    trackEntity,
    selectCamera,
    selectMosaic,
    singleCurrentlyTrackedObjectId,
    selectedCameraId: serverState.selectedCamera,
    selectedCamera: serverState.cameras[serverState.selectedCamera],
    viewType: serverState.viewType,
  };

  const serverStateAndFunctionsCopyRef = useRef(serverStateAndFunctions);

  useEffect(() => {
    serverStateAndFunctionsCopyRef.current = serverStateAndFunctions;
  }, [serverStateAndFunctions]);

  const getServerStateAndFunctions = useCallback(() => serverStateAndFunctionsCopyRef.current, []);

  return { ...serverStateAndFunctions, getServerStateAndFunctions };
};

export type ServerStateAndFunctions = ReturnType<typeof useServerState>;
export type ServerStateAndFunctionWithoutGetter = Omit<
  ReturnType<typeof useServerState>,
  'getServerStateAndFunctions'
>;

export default useServerState;
