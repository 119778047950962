import { addMilliseconds, min } from 'date-fns';

import { ClipType } from '../types';

export const toClipWithSyncedTimes = <
  TModel extends {
    id?: number;
    startTimestamp: Date;
    endTimestamp: Date;
    bookmarkTimestamp: Date;
    type: ClipType;
  },
>(
  model: TModel,
  recordingStartTimestamp: Date,
  recordingEndTimestamp: Date,
  playbackPositionTimestamp: Date,
): TModel => {
  if (model.type !== ClipType.Bookmark && model.id) return model;

  const endTimestamp = min([
    addMilliseconds(playbackPositionTimestamp, 60 * 1000),
    recordingEndTimestamp,
  ]);

  if (!model.id && model.type === ClipType.Clip) {
    return { ...model, endTimestamp };
  }

  return {
    ...model,
    startTimestamp: model.bookmarkTimestamp,
    endTimestamp,
  };
};

export default toClipWithSyncedTimes;
