import { CognitoUser } from 'amazon-cognito-identity-js';
import { ChangePasswordParams } from './types';

const changePassword =
  (user: CognitoUser) =>
  ({ oldPassword, newPassword, onSuccess, onFailure }: ChangePasswordParams) => {
    user.changePassword(oldPassword, newPassword, (err) => {
      if (err) {
        onFailure(err.message);
        return;
      }
      onSuccess();
    });
  };

export default changePassword;
