import { useQuery } from '@tanstack/react-query';
/* eslint-disable import/prefer-default-export */
import { get } from '@truefit/http-utils';

import toGameModel from '../services/toGameModel';
import { GameModelFromServer } from '../types';

export const queryKey = (gameId: number) => ['Games', gameId];

const useGameDetailsQuery = (gameId: number) =>
  useQuery({
    queryKey: queryKey(gameId),
    queryFn: async () => {
      if (Number.isNaN(gameId)) return null;
      const { data } = await get<GameModelFromServer>(`games/${gameId}`);
      return toGameModel(data);
    },
  });

export default useGameDetailsQuery;
