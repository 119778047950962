import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  MenuItem,
  Select,
  Stack,
  TextField,
  Typography,
} from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useAllUsersQuery } from '../hooks/useAllUsersQuery';
import { flattenUsers } from '../services/flattenUsers';
import { listGroups } from '../services/listGroups';
import { UsersTable } from './UsersTable';

import { Group, User } from '../types';
import { UserStateContext } from '@/shared/components/UserStateProvider';

const UserManagement: React.FC = () => {
  const [users, setUsers] = useState<User[]>([]);
  const [groups, setGroups] = useState<Group[]>([]);
  const [inviteEmail, setInviteEmail] = useState<string>('');
  const [selectedUser, setSelectedUser] = useState<User | null>(null);
  const [groupName, setGroupName] = useState<string>('');
  const [inviteOpen, setInviteOpen] = useState<boolean>(false);
  const [groupOpen, setGroupOpen] = useState<boolean>(false);
  const [defaultRole, setDefaultRole] = useState<string>('');
  const { currentOrg } = React.useContext(UserStateContext);

  const allUsersQuery = useAllUsersQuery(currentOrg.id);

  const handleInvite = () => {
    setInviteEmail('');
    setInviteOpen(false);
  };

  const handleAddGroup = () => {
    if (selectedUser) {
      setGroupName('');
      setGroupOpen(false);
    }
  };

  const handleDelete = (userId: number) => {
    return;
  };

  useEffect(() => {
    allUsersQuery.refetch();
  }, []);

  useEffect(() => {
    if (allUsersQuery.status === 'success') {
      setUsers(flattenUsers(allUsersQuery.data));
      setGroups(listGroups(allUsersQuery.data));
    }
  }, [allUsersQuery.status]);

  return (
    <Box>
      <Stack direction="column" justifyContent="center" spacing={2} p={3}>
        <Typography variant="subtitle">Organization Members</Typography>
      </Stack>

      <Stack direction="column" justifyContent="center" alignItems="flex-end" spacing={2} p={3}>
        <Button variant="contained" color="primary" onClick={() => setInviteOpen(true)}>
          Invite User
        </Button>
      </Stack>

      {users && <UsersTable users={users} handleDelete={handleDelete} />}

      <Box>
        <Stack direction="column" alignItems="left" spacing={2} p={3}>
          <Typography sx={{ minWidth: 400 }} variant="subtitle">
            Default Members Role
          </Typography>
          <Typography sx={{ minWidth: 400 }} variant="body2">
            Set the default role for new members added to the organization.
          </Typography>
          <Select
            sx={{
              maxWidth: 400,
              mt: 2,
              mb: 2,
            }}
            value={defaultRole}
            onChange={(e) => setDefaultRole(e.target.value as string)}
            fullWidth
          >
            {groups &&
              groups.map((group) => (
                <MenuItem key={group.id} value={group.name}>
                  {group.name}
                </MenuItem>
              ))}
          </Select>
        </Stack>
      </Box>

      {/* Invite User Dialog */}
      <Dialog open={inviteOpen} onClose={() => setInviteOpen(false)}>
        <DialogTitle>Invite User</DialogTitle>
        <DialogContent sx={{ minWidth: 400 }}>
          <TextField
            label="Email"
            type="email"
            value={inviteEmail}
            onChange={(e) => setInviteEmail(e.target.value)}
            fullWidth
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setInviteOpen(false)}>Cancel</Button>
          <Button onClick={handleInvite} color="primary">
            Invite
          </Button>
        </DialogActions>
      </Dialog>

      {/* Add to Group Dialog */}
      <Dialog open={groupOpen} onClose={() => setGroupOpen(false)}>
        <DialogTitle>Add to Group</DialogTitle>
        <DialogContent sx={{ minWidth: 400 }}>
          <Select
            label="Group"
            value={groupName}
            onChange={(e) => setGroupName(e.target.value as string)}
            fullWidth
          >
            {groups &&
              groups.map((group) => (
                <MenuItem key={group.id} value={group.name}>
                  {group.name}
                </MenuItem>
              ))}
          </Select>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setGroupOpen(false)}>Cancel</Button>
          <Button onClick={handleAddGroup} color="primary">
            Add
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
};

export default UserManagement;
