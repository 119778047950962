import { useRef } from 'react';

import useWebSocket from './useWebSocket';
import { WebSocketRefHook, withStateFromRef } from '../withStateFromRef';
import { GameState } from '@/shared/types/GameStateModel';

export const useGameStateRef: WebSocketRefHook<GameState> = (options = {}) => {
  const ref = useRef<GameState | null>(null);

  useWebSocket<GameState>('/state/0.1.0', {
    onMessage: (message) => {
      ref.current = message;
      if (options.onChange) {
        options.onChange(message);
      }
    },
  });

  return [ref];
};

export const useGameState = withStateFromRef(useGameStateRef);
