import findAttributeValue from './findAttributeValue';
import { GetUserAttributesParams } from './types';

const getUserAttributes = ({ user, onSuccess, onFailure }: GetUserAttributesParams) => {
  user.getUserAttributes((err, result) => {
    if (err) {
      onFailure(err.message);
      return;
    }

    onSuccess({
      email: findAttributeValue('email', result),
      email_verified: findAttributeValue('email_verified', result) === 'true',
    });
  });
};

export default getUserAttributes;
