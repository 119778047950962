import { useCallback, useEffect, useState } from 'react';
import { allTimezones } from 'react-timezone-select';
import useCognito from '@/features/authentication/hooks/useCognito';
import useCurrentCloudUser, { User } from './getCurrentUser';
import useUpdateUser from './useUpdateUser';
import { getLeagueLogo } from '@/shared/services/getLeagueLogo';
import { getOrgsWithAccess } from '@/shared/services/getOrgsWithAccess';
import { League, Org } from '@/types/user';
import { useUpdateSession } from './useUpdateSession';
import { useHistory } from 'react-router';
import { useSnackbar } from 'notistack';

export type UserState = {
  user: User;
  setOpen: (open?: boolean) => void;
  updateUserTimezone: (tz: string) => void;
  updateUserFirstNameAndLastName: (firstName: string, lastName) => void;
  isLoading: boolean;
  open: boolean;
  currentOrg: Org;
  currentLeague: League;
  availableOrgs: Org[];
  updateUserDefaultOrg: (org: Org) => void;
  updateUserDefaultLeague: (league: League) => void;
  currentLeagueLogo: string;
};

function preloadImage(url: string) {
  const img = new Image();
  img.onload = () => {};
  img.onerror = (e) => {
    console.error(url);
    console.error('Image failed to preload', e);
  };
  img.src = url;
}

const useUserState = () => {
  const history = useHistory();

  const updateUserMutation = useUpdateUser();
  const useUpdateSessionMutation = useUpdateSession();
  const currentCloudUser = useCurrentCloudUser();
  const browserTz = Intl.DateTimeFormat().resolvedOptions().timeZone;
  const cognito = useCognito();
  const [user, setUser] = useState<User>({ tz: browserTz });
  const [open, setOpen] = useState<boolean>(false);

  const [currentUser, setCurrentUser] = useState<User>(null);

  const [currentOrg, setCurrentOrg] = useState<Org>(null);
  const [currentLeague, setCurrentLeague] = useState<League>({});
  const [availableOrgs, setAvailableOrgs] = useState<Org[]>([]);
  const [currentLeagueLogo, setCurrentLeagueLogo] = useState<string>(null);

  useEffect(() => {
    if (!currentUser) return;
    const orgWithAccess = getOrgsWithAccess(currentUser);
    setAvailableOrgs(orgWithAccess);
  }, [currentUser]);

  useEffect(() => {
    if (currentOrg && currentOrg.access) {
      currentOrg.access.forEach((access) => {
        if (!access.league.id) return;
        preloadImage(getLeagueLogo(access.league.id));
      });
    }

    if (currentLeague) {
      if (!currentLeague.id) return;
      preloadImage(getLeagueLogo(currentLeague.id));
      setCurrentLeagueLogo(getLeagueLogo(currentLeague.id));
    }
  }, [currentOrg, currentLeague]);

  useEffect(() => {
    if (currentCloudUser.status === 'success') {
      const {
        data: {
          data: { org, league, user: clouduser },
        },
      } = currentCloudUser;

      // no user returned from the API
      if (!clouduser || !org || !league) return;

      setCurrentOrg(org);
      setCurrentLeague(league);
      setCurrentUser(clouduser);

      setUser({
        tz: allTimezones[clouduser.tz] ? clouduser.tz : browserTz,
        firstName: clouduser.firstName,
        lastName: clouduser.lastName,
      });
    }
  }, [currentCloudUser.fetchStatus]);

  useEffect(() => {
    if (cognito.authenticated) {
      currentCloudUser.refetch();
    }
  }, [cognito.authenticated]);

  const updateUserTimezone = async (tz: string) => {
    await updateUserMutation.mutateAsync({ tz });
  };

  const updateUserFirstNameAndLastName = async (firstName: string, lastName: string) => {
    await updateUserMutation.mutateAsync({ firstName, lastName });
  };

  const updateUserDefaultOrg = async (org: Org) => {
    await useUpdateSessionMutation.mutateAsync({ org: org.id, league: org.leagues[0].id });
    // this shouldn't be strighforward as the platform has to change.
    // you can have a reserved instance in aws when you are at COSM but not a reserved instance when you are at NHL or NBA or COSM - LK8s.
    // the idea is to display a dialog to confirm and then create a new session with the new organization ! Daymn !
    // poop.
    history.push('/games');
  };

  const updateUserDefaultLeague = useCallback(
    async (league: League) => {
      await useUpdateSessionMutation.mutateAsync({ org: currentOrg.id, league: league.id });
      history.push('/games');
    },
    [currentOrg],
  );

  return {
    user,
    open,
    currentOrg,
    currentLeague,
    availableOrgs,
    updateUserDefaultOrg,
    updateUserDefaultLeague,
    currentLeagueLogo,
    updateUserTimezone,
    updateUserFirstNameAndLastName,
    setOpen,
    isLoading: currentCloudUser.fetchStatus !== 'idle',
  };
};

export default useUserState;
