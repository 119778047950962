import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router';
import { Link } from 'react-router-dom';

import MenuOpenIcon from '@mui/icons-material/MenuOpen';
import OndemandVideoIcon from '@mui/icons-material/OndemandVideo';
import VideoLibrary from '@mui/icons-material/VideoLibrary';
import { Avatar, ListItem, Popover } from '@mui/material';
import Box from '@mui/material/Box';
import MuiDrawer from '@mui/material/Drawer';
import IconButton from '@mui/material/IconButton';
import List from '@mui/material/List';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import Typography from '@mui/material/Typography';
import { useFlags } from 'launchdarkly-react-client-sdk';

import useCognito from '@/features/authentication/hooks/useCognito';
import { PageAndAccountMenu } from './OrgAndAccountMenu';
import { getFirstLettersOfName, getRandomPastelColor } from '@/shared/services';

export type Props = {
  open: boolean;
  setOpen: (open: boolean) => void;
  width: number;
};

const NavigationMenu = ({ open, setOpen, width }: Props) => {
  const { batchedClipExport } = useFlags();

  const location = useLocation();
  const cognito = useCognito();
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);

  const [email, setEmail] = useState<string>(null);

  const settingsMenuOpen = Boolean(anchorEl);
  const id = settingsMenuOpen ? 'simple-popover' : undefined;

  const handleCloseSettingsMenu = () => {
    setAnchorEl(null);
  };

  const handleOpenSettingsMenu = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  useEffect(() => {
    setAvatarBgColor(getRandomPastelColor(email?.charAt(0), email?.charAt(1)));
  }, [email]);

  const handleClose = () => {
    setOpen(false);
  };

  useEffect(() => {
    setEmail(cognito.userAttributes?.email);
  }, [cognito.authenticated]);
  const [avatarBgColor, setAvatarBgColor] = useState<string>('#333');

  return (
    <MuiDrawer anchor="left" open={open} onClose={handleClose}>
      <Box
        sx={(theme) => ({
          display: 'flex',
          alignItems: 'center',
          padding: theme.spacing(0, 1),
          borderBottom: `1px solid ${theme.palette.divider}`,
          // necessary for content to be below app bar
          ...theme.mixins.toolbar,
          width,
        })}
      >
        <IconButton onClick={handleClose}>
          <MenuOpenIcon />
        </IconButton>
      </Box>

      <Box sx={{ flexGrow: 1 }}>
        <Typography
          variant="subtitle2"
          sx={{
            mx: 2,
            marginTop: 3,
            marginBottom: 2,
            fontWeight: 500,
            textTransform: 'uppercase',
          }}
        >
          Watch
        </Typography>

        <List sx={{ paddingTop: 0 }}>
          <ListItem disablePadding>
            <ListItemButton selected={location.pathname === '/games'} component={Link} to="/games">
              <ListItemIcon>
                <OndemandVideoIcon />
              </ListItemIcon>
              <ListItemText primary="Games" />
            </ListItemButton>
          </ListItem>
          {!!batchedClipExport && (
            <ListItem disablePadding>
              <ListItemButton
                selected={location.pathname === '/clips'}
                component={Link}
                to="/clips"
              >
                <ListItemIcon sx={{ color: 'Background.paper' }}>
                  <VideoLibrary />
                </ListItemIcon>
                <ListItemText primary="Bookmarks & Clips" />
              </ListItemButton>
            </ListItem>
          )}
        </List>
      </Box>
      <Box>
        <List>
          <ListItem
            onClick={handleOpenSettingsMenu}
            sx={{
              cursor: 'pointer', // Sets the cursor to pointer on hover
            }}
          >
            <ListItemIcon>
              <Avatar sx={{ bgcolor: avatarBgColor }}>{getFirstLettersOfName(email)}</Avatar>
            </ListItemIcon>
            <ListItemText secondary={email} />
          </ListItem>

          <Popover
            id={id}
            open={settingsMenuOpen}
            onClose={handleCloseSettingsMenu}
            anchorEl={anchorEl}
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'left',
            }}
            transformOrigin={{
              vertical: 'top',
              horizontal: 'left',
            }}
          >
            <PageAndAccountMenu handleClose={handleCloseSettingsMenu}></PageAndAccountMenu>
          </Popover>
        </List>
      </Box>
    </MuiDrawer>
  );
};

export default NavigationMenu;
