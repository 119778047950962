import { useHotkeys } from 'react-hotkeys-hook';

import { KeyboardShortcuts } from '@/shared/constants/keyboardShortcuts';

const usePlayerHotkeys = (
  onPlayPause: (e: Event) => void,
  onJumpForwardOneSecond: (e: Event) => void,
  onJumpBackwardOneSecond: (e: Event) => void,
  onJumpForwardOneFrame: (e: Event) => void,
  onJumpBackwardOneFrame: (e: Event) => void,
) => {
  useHotkeys(
    KeyboardShortcuts.PlayPause.hotkey,
    (e) => {
      e.preventDefault();
      onPlayPause(e);
    },
    [onPlayPause],
  );
  useHotkeys(
    KeyboardShortcuts.ShuttleForward.hotkey,
    (e) => {
      e.preventDefault();
      onJumpForwardOneSecond(e);
    },
    [onJumpForwardOneSecond],
  );
  useHotkeys(
    KeyboardShortcuts.ShuttleBackward.hotkey,
    (e) => {
      e.preventDefault();
      onJumpBackwardOneSecond(e);
    },
    [onJumpBackwardOneSecond],
  );
  useHotkeys(
    KeyboardShortcuts.MoveFrameForward.hotkey,
    (e) => {
      e.preventDefault();
      onJumpForwardOneFrame(e);
    },
    [onJumpForwardOneFrame],
  );
  useHotkeys(
    KeyboardShortcuts.MoveFrameBackward.hotkey,
    (e) => {
      e.preventDefault();
      onJumpBackwardOneFrame(e);
    },
    [onJumpBackwardOneFrame],
  );
};

export default usePlayerHotkeys;
