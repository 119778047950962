import { format, parseISO } from 'date-fns';

const displayWallClock = (date: Date, options?: { includeSubseconds?: boolean }) => {
  try {
    if (typeof date === 'string') {
      return format(parseISO(date), options?.includeSubseconds ? 'h:mm:ss.SSS aaa' : 'h:mm:ss aaa');
    }
    return format(date, options?.includeSubseconds ? 'h:mm:ss.SSS aaa' : 'h:mm:ss aaa');
  } catch (e) {
    console.error(`date is ${date} and typeof date is ${typeof date}`);
    return 'INVALID DATE';
  }
};

export default displayWallClock;
