import { useMutation, useQueryClient } from '@tanstack/react-query';
/* eslint-disable import/prefer-default-export */
import { put } from '@truefit/http-utils';
import useCurrentGameId from '@/shared/hooks/useCurrentGameId';

import { toClipModel } from '../services';
import { ClipModel, ClipModelFromServer } from '../types';
import { getQueryKey as getCurrentGameClipsQueryKey } from './useCurrentGameClipsQuery';

const useUpdateClipMutation = () => {
  const gameId = useCurrentGameId();
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: async (model: ClipModel) => {
      const response = await put<ClipModelFromServer>(`clips/${model.id}`, model);
      return toClipModel(response.data);
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: getCurrentGameClipsQueryKey(gameId) });
    },
  });
};

export default useUpdateClipMutation;
