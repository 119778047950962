import { useSnackbar } from 'notistack';
import React from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useHistory } from 'react-router-dom';
import * as Yup from 'yup';

import { yupResolver } from '@hookform/resolvers/yup';
import { nameofFactory } from '@/shared/services';

import {
  FormSubmitBar,
  FormValidationSummary,
  HookFormPasswordField,
  HookFormTextField,
} from '../../forms/components';
import useCognito from '../hooks/useCognito';

type ChangePasswordFormValues = {
  verificationCode: string;
  newPassword: string;
  passwordConfirmation: string;
};

const nameOf = nameofFactory<ChangePasswordFormValues>();

const validationSchema = Yup.object().shape({
  [nameOf('verificationCode')]: Yup.string().required().label('Verification Code'),
  [nameOf('newPassword')]: Yup.string().required().max(50).min(8).label('New Password'),
  [nameOf('passwordConfirmation')]: Yup.string().oneOf(
    [Yup.ref('newPassword'), null],
    'Passwords must match',
  ),
});

const verificationCodeMessage = 'Invalid verification code provided, please try again.';

const useChangePassword = () => {
  const cognito = useCognito();
  const history = useHistory();
  const { enqueueSnackbar } = useSnackbar();
  const form = useForm<ChangePasswordFormValues>({
    defaultValues: {
      [nameOf('verificationCode')]: '',
      [nameOf('newPassword')]: '',
      [nameOf('passwordConfirmation')]: '',
    },
    resolver: yupResolver(validationSchema),
  });
  const [generalValidationErrors, setGeneralValidationErrors] = React.useState<string[]>([]);

  const handleSubmit = form.handleSubmit(
    (formValues) =>
      new Promise((resolve) => {
        if (formValues.newPassword !== formValues.passwordConfirmation) {
          // don't submit if passwords don't match
          resolve();
          return;
        }

        cognito.confirmPassword({
          verificationCode: formValues.verificationCode,
          newPassword: formValues.newPassword,

          onSuccess() {
            enqueueSnackbar('Password successfully changed', { variant: 'success' });
            history.push('/');
            resolve();
          },

          onFailure(message) {
            if (message === verificationCodeMessage) {
              form.setError(
                nameOf('verificationCode'),
                { type: 'custom', message },
                { shouldFocus: true },
              );
            } else {
              setGeneralValidationErrors([message]);
            }

            resolve();
          },
        });
      }),
  );

  return { form, generalValidationErrors, handleSubmit };
};

const ConfirmPasswordForm = () => {
  const { form, handleSubmit, generalValidationErrors } = useChangePassword();

  return (
    <FormProvider {...form}>
      <form onSubmit={handleSubmit} noValidate style={{ width: '100%' }}>
        <FormValidationSummary validationErrors={generalValidationErrors} />

        <HookFormTextField
          name={nameOf('verificationCode')}
          required
          label="Verification Code"
          variant="outlined"
          margin="dense"
        />

        <HookFormPasswordField
          name={nameOf('newPassword')}
          required
          label="New Password"
          variant="outlined"
          margin="dense"
        />

        <HookFormPasswordField
          name={nameOf('passwordConfirmation')}
          required
          label="Confirm Password"
          variant="outlined"
          margin="dense"
        />

        <FormSubmitBar
          onSubmit={handleSubmit}
          submitting={form.formState.isSubmitting}
          cancelTo="/sign-in"
        />
      </form>
    </FormProvider>
  );
};

export default ConfirmPasswordForm;
