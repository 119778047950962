import React, { ReactNode } from 'react';
import useUserState, { UserState } from '../hooks/user/userState';
import { UserSettingsDialog } from './UserSettingsDialog';

type Props = {
  children: ReactNode;
};

export const UserStateContext = React.createContext({} as UserState);

const UserStateProvider = ({ children }: Props) => {
  const userState = useUserState();
  return (
    <UserStateContext.Provider value={userState}>
      <UserSettingsDialog
        id="user-settings-dialog"
        keepMounted
        open={userState.open}
        onClose={() => {
          userState.setOpen(false);
        }}
      />
      {children}
    </UserStateContext.Provider>
  );
};

export default UserStateProvider;
