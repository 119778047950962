import { intersection } from 'lodash';
import { useSelector } from 'react-redux';

import { SystemRoles } from '../enums';
import { currentUserSliceSelector } from '../selectors';

const useAuth = () => {
  const currentUser = useSelector(currentUserSliceSelector);
  const userIsInRole = (role: SystemRoles) => {
    if (!currentUser || !currentUser.authenticated) return false;
    return currentUser.user.roles.includes(role);
  };

  const isAuthorized = (routeRoles: SystemRoles[]) => {
    if (!routeRoles || !routeRoles.length) return true;

    // does user have at least one of the routes required roles?
    return intersection(currentUser.user.roles, routeRoles).length > 0;
  };

  return {
    isAuthenticated: currentUser.authenticated,
    userIsInRole,
    isAuthorized,
  };
};

export default useAuth;
