import React, { useRef } from 'react';

import { Add } from '@mui/icons-material';

import { Chip, Grid, Popover } from '@mui/material';

import { startCase } from 'lodash';
import TagsAddInline from './TagsAddInline';
import { colorTag } from '@/shared/services/colorTag';
import { ClipModel, Tag } from '@/features/clips/types';

export type ClipTag = {
  clipid: number;
  cliptagid: number;
  tag: Tag;
};

type Props = {
  clip: ClipModel;
  readonly: boolean;
  sx?: any;
};

function DisplayTags({ clip, readonly, sx= {} }: Props) {
  const clipTags: ClipTag[] = clip?.tags ?? [];

  const tags: Tag[] = clipTags.map((tag) => ({ label: tag.tag.name, id: tag.tag.id }));
  const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(null);
  const open = Boolean(anchorEl);
  const id = open ? 'simple-popover' : undefined;
  const gridRef = useRef(null);

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(gridRef.current);
    return event;
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <Grid container spacing={2} sx={{ ...sx }}>
      <Grid item ref={gridRef}>
        {!!tags &&
          tags.map((item) => (
            <Chip
              key={item.id}
              style={{
                padding: 0,
                margin: 4,
                fontSize: 10,
                color: colorTag(item.label).c,
                background: colorTag(item.label).b,
              }}
              label={startCase(item.label)}
              size="small"
            />
          ))}
        {!readonly && (
          // @ts-ignore
          <Chip
            icon={<Add />}
            style={{ margin: 4 }}
            label="Add Tag"
            variant="outlined"
            size="small"
            onClick={handleClick}
          />
        )}
        <Popover
          id={id}
          open={open}
          anchorEl={anchorEl}
          onClose={handleClose}
          anchorOrigin={{
            vertical: 'top',
            horizontal: 'left',
          }}
        >
          <Grid container spacing={4} padding={2}>
            <Grid item>
              <TagsAddInline clip={clip} />
            </Grid>
          </Grid>
        </Popover>
      </Grid>
    </Grid>
  );
}

export default DisplayTags;
