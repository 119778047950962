import React, { useState } from 'react';

import {
  Button,
  Typography,
  Stack,
  CircularProgress,
  Paper,
  Backdrop,
  DialogTitle,
  DialogContent,
  Dialog,
  DialogActions,
  DialogContentText,
} from '@mui/material';

import { LoadingButton } from '@mui/lab';

type ButtonColorType = 'primary' | 'error' | 'info' | 'secondary' | 'success' | 'inherit';
type Props = {
  cancelButtonColor?: ButtonColorType;
  cancelButtonText?: string;
  cancellable?: boolean;
  closable?: boolean;
  closeButtonColor?: ButtonColorType;
  closeButtonText?: string;
  message: string;
  onClose?: () => void;
  onRetry?: () => void;
  onYes: () => void;
  open: boolean;
  requiresConfirmation: boolean;
  retryable?: boolean;
  retryButtonColor?: ButtonColorType;
  retryButtonText?: string;
  secondaryMessage?: string;
  title: string;
  useDialog?: boolean;
};

type ConfirmationButtonsProps = {
  onYes: () => void;
  onNo: () => void;
  noText?: string;
  yesText?: string;
  questionText?: string;
};

const ConfirmationButtons = ({
  onYes,
  onNo,
  yesText = 'Yes',
  noText = 'No',
  questionText = 'Are you sure?',
}: ConfirmationButtonsProps) => (
  <Stack
    direction="row-reverse"
    width="100%"
    alignItems="center"
    justifyContent="center"
    sx={{ mt: 2 }}
  >
    <Button size="small" color="success" onClick={onYes}>
      {yesText}
    </Button>
    <Button size="small" color="error" onClick={onNo}>
      {noText}
    </Button>
    <Typography variant="caption">{questionText}</Typography>
  </Stack>
);

const ServerStatus = ({
  open,
  title,
  message,
  cancelButtonText = 'Cancel',
  closeButtonText = 'Close',
  cancellable = false,
  retryable = false,
  retryButtonText = 'Retry',
  closable = false,
  closeButtonColor = 'error',
  retryButtonColor = 'info',
  cancelButtonColor = 'error',
  onRetry,
  secondaryMessage,
  onClose,
  onYes,
  requiresConfirmation = true,
  useDialog = false,
}: Props) => {
  const [confirm, setConfirm] = useState(false);
  const [loadingButton, setLoadingButton] = useState(false);
  const loading = !!(retryable || cancellable);

  const paperStyle = {
    backgroundColor: 'transparent',
    borderRadius: 2,
    px: 5,
    pt: 2,
    pb: 1,
    minWidth: 700,
    minHeight: 700,
    // backgroundImage: `url(${BackgroundImage})`,
    backgroundPosition: 'center',
    backgroundRepeat: 'no-repeat',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'column',
  };

  return (
    <Backdrop
      sx={{
        color: '#fff',
        backdropFilter: 'blur(10px)',
        zIndex: (theme) => theme.zIndex.drawer + 1,
      }}
      open={open}
    >
      {!useDialog && (
        <Paper sx={paperStyle}>
          <Typography variant="h5" sx={{ mb: 10 }}>
            {title}
          </Typography>
          {loading && !retryable && <CircularProgress color="inherit" />}
          <Typography variant="body2" sx={{ mt: 10 }}>
            {message}
          </Typography>
          {!!secondaryMessage && (
            <Typography
              mt={2}
              variant="body2"
              sx={{
                paddingTop: 0.5,
                overflowWrap: 'break-word',
                width: '100%',
              }}
            >
              {secondaryMessage}
            </Typography>
          )}

          {cancellable && !confirm && (
            <LoadingButton
              sx={{ mt: 1 }}
              color={cancelButtonColor}
              loading={loadingButton}
              onClick={() => {
                if (requiresConfirmation) setConfirm(true);
                else onYes();
              }}
              autoFocus
            >
              {cancelButtonText}
            </LoadingButton>
          )}
          {retryable && (
            <LoadingButton
              color={retryButtonColor}
              loading={loadingButton}
              onClick={onRetry}
              autoFocus
            >
              {retryButtonText}
            </LoadingButton>
          )}
          {confirm && (
            <ConfirmationButtons
              onNo={() => {
                setConfirm(false);
              }}
              onYes={() => {
                onYes();
                setLoadingButton(true);
                setConfirm(false);
              }}
            />
          )}
          {closable && (
            <Button color={closeButtonColor} onClick={onClose}>
              {closeButtonText}
            </Button>
          )}
        </Paper>
      )}
      {!!useDialog && (
        <Dialog
          open={open}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title">
            {loading && !loadingButton && (
              <CircularProgress size={15} sx={{ marginRight: 3 }} color="inherit" />
            )}
            {title}
          </DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              <Typography variant="body2">{message}</Typography>
              {!!secondaryMessage && (
                <Typography
                  mt={2}
                  variant="body2"
                  sx={{
                    overflowWrap: 'break-word',
                    width: '100%',
                  }}
                >
                  {secondaryMessage}
                </Typography>
              )}
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            {cancellable && !confirm && (
              <LoadingButton
                sx={{ mt: 1 }}
                color={cancelButtonColor}
                loading={loadingButton}
                onClick={() => {
                  if (requiresConfirmation) setConfirm(true);
                  else onYes();
                }}
                autoFocus
              >
                {cancelButtonText}
              </LoadingButton>
            )}
            {retryable && (
              <LoadingButton
                color={retryButtonColor}
                loading={loadingButton}
                onClick={onRetry}
                autoFocus
              >
                {retryButtonText}
              </LoadingButton>
            )}
            {confirm && (
              <ConfirmationButtons
                onNo={() => {
                  setConfirm(false);
                }}
                onYes={() => {
                  onYes();
                  setLoadingButton(true);
                  setConfirm(false);
                }}
              />
            )}
            {closable && (
              <Button color={closeButtonColor} onClick={onClose}>
                {closeButtonText}
              </Button>
            )}
          </DialogActions>
        </Dialog>
      )}
    </Backdrop>
  );
};

export default ServerStatus;
