import React from 'react';

// showing this inline so that it renders with the correct font
const OneSecondBackwardIcon = () => (
  <svg version="1.1" width="18px" height="24px" viewBox="0 0 18.0 24.0">
    <defs>
      <clipPath id="i0">
        <path d="M1920,0 L1920,1080 L0,1080 L0,0 L1920,0 Z" />
      </clipPath>
      <clipPath id="i1">
        <path d="M1,0 C1.55000001,0 2,0.450000002 2,1 L2,4 C2,4.55000002 1.55000001,5 1,5 C0.450000002,5 0,4.55000002 0,4 L0,1 C0,0.450000002 0.450000002,0 1,0 Z" />
      </clipPath>
      <clipPath id="i2">
        <path d="M1,0 C1.55000001,0 2,0.450000002 2,1 L2,4 C2,4.55000002 1.55000001,5 1,5 C0.450000002,5 0,4.55000002 0,4 L0,1 C0,0.450000002 0.450000002,0 1,0 Z" />
      </clipPath>
      <clipPath id="i3">
        <path d="M1,0 C1.55000001,0 2,0.450000002 2,1 L2,4 C2,4.55000002 1.55000001,5 1,5 C0.450000002,5 0,4.55000002 0,4 L0,1 C0,0.450000002 0.450000002,0 1,0 Z" />
      </clipPath>
      <clipPath id="i4">
        <path d="M5,0 C5.55000002,0 6,0.450000002 6,1 L6,4 C6,4.55000002 5.55000002,5 5,5 L1,5 C0.450000002,5 0,4.55000002 0,4 L0,1 C0,0.450000002 0.450000002,0 1,0 L5,0 Z" />
      </clipPath>
    </defs>
    <g transform="translate(-50.0 -939.0)">
      <g clipPath="url(#i0)">
        <g transform="translate(49.999999962500084 939.0)">
          <g transform="translate(1.0000000375002855 0.0)">
            <text
              transform="translate(0.0 15.0)"
              fontFamily="'Stolzl Book', Arial"
              fontSize="14.0"
              fontWeight="800"
              fill="#FFFFFF"
              textAnchor="left"
              letterSpacing="0.1"
            >
              1S
            </text>
          </g>
          <g transform="translate(18.00000007499984 19.00000002083334) scale(-1.0 1.0)">
            <g clipPath="url(#i1)">
              <polygon points="0,0 2,0 2,5 0,5 0,0" stroke="none" fill="#FFFFFF" />
            </g>
            <g transform="translate(4.000000016665581 0.0)">
              <g clipPath="url(#i2)">
                <polygon points="0,0 2,0 2,5 0,5 0,0" stroke="none" fill="#FFFFFF" />
              </g>
            </g>
            <g transform="translate(8.00000003333298 0.0)">
              <g clipPath="url(#i3)">
                <polygon points="0,0 2,0 2,5 0,5 0,0" stroke="none" fill="#FFFFFF" />
              </g>
            </g>
            <g transform="translate(12.00000005000038 0.0)">
              <g clipPath="url(#i4)">
                <polygon points="0,0 6,0 6,5 0,5 0,0" stroke="none" fill="#FFFFFF" />
              </g>
            </g>
          </g>
        </g>
      </g>
    </g>
  </svg>
);

export default React.memo(OneSecondBackwardIcon);
