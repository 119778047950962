import React from 'react';
import { Redirect, Route, Switch } from 'react-router';

import { AccessDenied, NotFound, SystemError } from '@/shared/components';

import {
  ChangePasswordPage,
  ForgotPasswordPage,
  SignOutPage,
} from '../../authentication/components';
import ResetPasswordPage from '../../authentication/components/ResetPasswordPage';
import SignInPage from '../../authentication/components/SignInPage';
import { ClipsPage } from '../../clips/components';
import { GamePage, GamesPage } from '../../games/components';
import AuthenticateRoute from './AuthenticateRoute';
import { OrganizationSettingsPage, AccountSettingsPage } from '../../settings/components';

export default () => (
  <Switch>
    <Route exact path="/organization-settings">
      <AuthenticateRoute>
        <OrganizationSettingsPage />
      </AuthenticateRoute>
    </Route>
    <Route exact path="/account-settings">
      <AuthenticateRoute>
        <AccountSettingsPage />
      </AuthenticateRoute>
    </Route>
    <Route exact path="/">
      <Redirect to="/games" />
    </Route>
    <Route exact path="/games/:gameId">
      <AuthenticateRoute>
        <GamePage />
      </AuthenticateRoute>
    </Route>
    <Route exact path="/games">
      <AuthenticateRoute>
        <GamesPage />
      </AuthenticateRoute>
    </Route>
    <Route exact path="/clips">
      <AuthenticateRoute>
        <ClipsPage />
      </AuthenticateRoute>
    </Route>
    <Route path="/sign-in">
      <SignInPage />
    </Route>
    <Route path="/sign-out">
      <SignOutPage />
    </Route>
    <Route path="/forgot-password">
      <ForgotPasswordPage />
    </Route>
    <Route path="/reset-password">
      <ResetPasswordPage />
    </Route>
    <Route path="/change-password">
      <ChangePasswordPage />
    </Route>

    <Route path="/access-denied">
      <AccessDenied />
    </Route>
    <Route path="/system-error">
      <SystemError />
    </Route>

    <Route>
      <NotFound />
    </Route>
  </Switch>
);
