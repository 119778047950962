import { format, parseISO } from 'date-fns';
import * as React from 'react';

import { FileDownload } from '@mui/icons-material';
import { Box, Chip, Stack } from '@mui/material';

import {
  DataGrid,
  GridActionsCellItem,
  GridColumnHeaderParams,
  GridRenderCellParams,
  GridToolbarContainer,
  GridToolbarExport,
  GridValueGetterParams,
} from '@mui/x-data-grid';
import useEnqueueClipForExportMutation from '../hooks/useEnqueueClipForExportMutation';
import { useTeamLogoPath } from '@/shared/hooks/useTeamLogoPath';

type Props = {
  rows: any[];
  loading: boolean;
  pageSize: number;
  setPageSize: (newPageSize: any) => void;
  page: number;
  setPage: (newPageSize: any) => void;
  rowCount: number;
};

const getChipStatusColor = (status: string) => {
  switch (status.toUpperCase()) {
    case 'READY':
      return 'success';
    case 'ERROR':
      return 'error';
    case 'CANCELED':
    case 'PENDING':
    case 'UNPROCESSED':
      return 'info';
    default:
      return 'warning';
  }
};

const ClipsTable = ({ rows, loading, pageSize, setPageSize, page, setPage, rowCount }: Props) => {
  const exportClipMutation = useEnqueueClipForExportMutation();
  const columns: GridEnrichedColDef[] = [
    {
      field: 'note',
      headerName: 'Name',
      width: 270,
      renderHeader: (params: GridColumnHeaderParams) => (
        <Box style={{ textTransform: 'uppercase' }}>{params.colDef.headerName}</Box>
      ),
      renderCell: (params: GridRenderCellParams<any>) => (
        <Box style={{ textTransform: 'capitalize' }} sx={{ overflowWrap: 'break-word' }}>
          {params.row.note}
        </Box>
      ),
    },
    {
      field: 'type',
      headerName: 'Type',
      width: 100,
      renderHeader: (params: GridColumnHeaderParams) => (
        <Box style={{ textTransform: 'uppercase' }}>{params.colDef.headerName}</Box>
      ),
      renderCell: (params: GridRenderCellParams<any>) => (
        <Box style={{ textTransform: 'capitalize' }}>{params.row.type}</Box>
      ),
      valueGetter: (params: GridValueGetterParams) => params.row.type.toUpperCase(),
    },
    {
      field: 'gameName',
      headerName: 'Game',
      width: 200,
      renderHeader: (params: GridColumnHeaderParams) => (
        <Box style={{ textTransform: 'uppercase' }}>{params.colDef.headerName}</Box>
      ),
      renderCell: (params: GridRenderCellParams<any>) => (
        <Stack spacing={1}>
          <Stack direction="row" spacing={2}>
            <img
              // eslint-disable-next-line react-hooks/rules-of-hooks
              src={useTeamLogoPath(params.row.gameAwayTeam)}
              width="30"
              loading="lazy"
              style={{ marginRight: 10 }}
              alt={params.row.gameAwayTeam.abbreviation}
            />
            {params.row.gameAwayTeam.abbreviation}
          </Stack>
          <Stack direction="row" spacing={2}>
            <img
              // eslint-disable-next-line react-hooks/rules-of-hooks
              src={useTeamLogoPath(params.row.gameHomeTeam)}
              width="30"
              loading="lazy"
              style={{ marginRight: 10 }}
              alt={params.row.gameHomeTeam.abbreviation}
            />
            {params.row.gameHomeTeam.abbreviation}
          </Stack>
        </Stack>
      ),
      valueGetter: (params: GridValueGetterParams) =>
        `${params.row.gameAwayTeam.abbreviation} @ ${params.row.gameHomeTeam.abbreviation}`,
    },
    {
      field: 'gameDate',
      headerName: 'Game Date',
      type: 'date',
      width: 200,
      renderHeader: (params: GridColumnHeaderParams) => (
        <Box style={{ textTransform: 'uppercase' }}>{params.colDef.headerName}</Box>
      ),
      valueFormatter: ({ value }) => {
        const isoDate = parseISO(value);
        return `${format(isoDate, 'MMM dd, yyyy') || ''}`;
      },
    },
    {
      field: 'createdAt',
      headerName: 'Date Created',
      type: 'date',
      width: 200,
      renderHeader: (params: GridColumnHeaderParams) => (
        <Box style={{ textTransform: 'uppercase' }}>{params.colDef.headerName}</Box>
      ),
      valueFormatter: ({ value }) => {
        const isoDate = parseISO(value);
        return `${format(isoDate, 'MMM dd, yyyy') || ''}`;
      },
    },
    {
      field: 'updatedAt',
      headerName: 'Date Modified',
      type: 'date',
      width: 200,
      renderHeader: (params: GridColumnHeaderParams) => (
        <Box style={{ textTransform: 'uppercase' }}>{params.colDef.headerName}</Box>
      ),
      valueFormatter: ({ value }) => {
        const isoDate = parseISO(value);
        return `${format(isoDate, 'MMM dd, yyyy') || ''}`;
      },
    },
    {
      field: 'status',
      headerName: 'Status',
      width: 150,
      renderHeader: (params: GridColumnHeaderParams) => (
        <Box style={{ textTransform: 'uppercase' }}>{params.colDef.headerName}</Box>
      ),
      renderCell: (params: GridRenderCellParams<any>) => {
        if (params.row.type === 'clip') {
          return (
            <Chip
              label={params.row.status.toUpperCase()}
              size="small"
              color={getChipStatusColor(params.row.status)}
              style={{
                borderRadius: 5,
                padding: 0,
                marginLeft: 4,
                fontSize: 10,
              }}
            />
          );
        }
        return <></>;
      },
      valueGetter: (params: GridValueGetterParams) => params.row.status.toUpperCase(),
    },
    {
      field: 'actions',
      type: 'actions',
      getActions: (params) => [
        <GridActionsCellItem
          disabled={params.row.type === 'bookmark'}
          icon={<FileDownload />}
          label="Export"
          onClick={() => {
            exportClipMutation.mutate(params.id);
          }}
        />,
      ],
    },
  ];

  function CustomToolbar() {
    return (
      <GridToolbarContainer>
        <GridToolbarExport />
      </GridToolbarContainer>
    );
  }

  return (
    <div style={{ height: 600, width: '100%' }}>
      <DataGrid
        rows={rows}
        loading={loading}
        components={{
          Toolbar: CustomToolbar,
        }}
        pageSize={pageSize}
        rowHeight={80}
        autoHeight
        columns={columns}
        rowCount={rowCount}
        rowsPerPageOptions={[5]}
        pagination
        page={page}
        // checkboxSelection
        paginationMode="server"
        onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
        onPageChange={(newPage) => setPage(newPage)}
      />
    </div>
  );
};

export default ClipsTable;
