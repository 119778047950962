import * as rudderanalytics from 'rudder-sdk-js';

import { enableRudderstackTracker, rudderDataPlaneUrl, rudderWriteKey } from '@/shared/constants';

import { Tracker } from './Tracker';

export class Rudderstack implements Tracker {
  private static instance: Rudderstack;

  private ready = false;

  private name = 'Rudderstack';

  private constructor() {
    if (!enableRudderstackTracker) {
      console.debug('Skipped Rudderstack analytics');
      return;
    }

    if (!rudderWriteKey || !rudderDataPlaneUrl) {
      console.warn('Skipped Rudderstack analytics (No rudderWriteKey/rudderDataPlaneUrl provided)');
      return;
    }
    try {
      rudderanalytics.load(rudderWriteKey, rudderDataPlaneUrl);
      rudderanalytics.ready(() => {
        this.ready = true;
        console.debug('Loaded rdudderstack analytics');
      });
    } catch (e) {
      console.error('Skipped rudderstack analytics');
    }
  }

  signIn(props): void {
    this.track('sign-in', props);
  }

  signOut(props): void {
    this.track('sign-out', props);
  }

  navigation(location: string) {
    this.track('navigation', { location });
  }

  public toString() {
    return this.name;
  }

  public static getInstance(): Rudderstack {
    if (!Rudderstack.instance) {
      Rudderstack.instance = new Rudderstack();
    }

    return Rudderstack.instance;
  }

  public identify(userId: string, userProps: any) {
    if (!this.ready) return;
    const userTraits = JSON.parse(JSON.stringify(userProps));
    userTraits['cognito-group'] = userTraits.group;
    delete userTraits.group;
    rudderanalytics.identify(userId, userTraits);
  }

  public track(event: string, payload?: any) {
    if (!this.ready) return;
    rudderanalytics.track(event, payload);
  }
}
