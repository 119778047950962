import Tracking from '@/shared/services/tracking';

const identifyUser = (cognitoContext, ldClient) => {
  try {
    if (!cognitoContext.authenticated) return;
    const [group] = cognitoContext.user.signInUserSession.idToken.payload['cognito:groups'];

    if (group && cognitoContext.userAttributes && cognitoContext.user) {
      const { email } = cognitoContext.userAttributes;
      const { username } = cognitoContext.user;
      Tracking.getInstance().identify(username, { email, group });
      ldClient.identify({ email, key: username, custom: { group } });
    }
  } catch (e) {
    console.error(e);
    throw new Error('Unable to identify user');
  }
};

export default identifyUser;
