import { useMutation } from '@tanstack/react-query';
/* eslint-disable import/prefer-default-export */
import { put } from '@truefit/http-utils';
import useGameTeamsQuery from '@/features/games/hooks/useGameTeamsQuery';
import useAllUsersQuery from '@/features/settings/hooks/useAllUsersQuery';
import { DEFAULT_VALUES } from '../../../features/games/components/GamesForm';
import { useState } from 'react';
import { useGamesQuery } from '@/features/games/hooks/useGameQuery';
import { useListSeasonsQuery } from '@/features/games/hooks/useListSeasonsQuery';
import { useQueryClient } from '@tanstack/react-query';
import getCurrentUserQuery from './getCurrentUser';

interface UpdateSessionData {
  league: number;
  org: number;
}

export const useUpdateSession = () => {
  const [formValues, setFormValues] = useState(DEFAULT_VALUES);
  const queryClient = useQueryClient();
  const getCurrentUser = getCurrentUserQuery();
  const teamsQuery = useGameTeamsQuery(Number(formValues.leagueId));
  const allUsersQuery = useAllUsersQuery(Number(formValues.orgId));
  const gamesQuery = useGamesQuery(formValues);
  const listSeasonsQuery = useListSeasonsQuery(Number(formValues.leagueId));

  return useMutation({
    mutationFn: async (data: UpdateSessionData) => {
      const response = await put<UpdateSessionData>('session', data);
      return response.data;
    },
    onSuccess: () => {
      getCurrentUser.refetch();
      teamsQuery.refetch();
      allUsersQuery.refetch();
      listSeasonsQuery.refetch();
      gamesQuery.refetch();

      queryClient.invalidateQueries({ queryKey: ['User'] });
      queryClient.invalidateQueries({ queryKey: ['GameTeams'] });
      queryClient.invalidateQueries({ queryKey: ['AllUsers'] });
      queryClient.invalidateQueries({ queryKey: ['Games'] });
      queryClient.invalidateQueries({ queryKey: ['Seasons'] });
    },
  });
};

export default useUpdateSession;
