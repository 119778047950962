import { Tooltip } from '@mui/material';
import React from 'react';
import VideocamTwoToneIcon from '@mui/icons-material/VideocamTwoTone';

export const cameraSize = 16;

// Component for rendering camera icons
export const CameraIcon = ({ camera, xVal, yVal, theme, isActive, onClick }) => (
  <Tooltip title={camera.name} arrow>
    <VideocamTwoToneIcon
      onClick={onClick}
      style={{
        position: 'absolute',
        left: xVal || 0,
        bottom: yVal || 0,
        transform: `rotate(${360 - camera.angle}deg)`,
        width: cameraSize,
        height: cameraSize,
        cursor: 'pointer',
        color: isActive ? theme.palette.primary.main : '#888888',
      }}
    />
  </Tooltip>
);
