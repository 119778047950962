import React from 'react';
import { Tooltip as MuiTooltip, TooltipProps, Avatar } from '@mui/material';

import styled from '@emotion/styled';
import { blueGrey, green, lightBlue, grey, purple, red, orange } from '@mui/material/colors';

interface ClipStatusProps {
  status: string;
}

interface StyledTooltipProps extends TooltipProps {
  color: string;
}

const StyledTooltip = styled(MuiTooltip)<StyledTooltipProps>`
  background-color: ${(props) => props.color};
`;

const CustomTooltip: React.FC<StyledTooltipProps> = ({ color, ...props }) => (
  <StyledTooltip color={color} {...props} />
);

const getChipStatusColor = (status: string): string => {
  switch (status.toUpperCase()) {
    case 'READY':
      return green['A400'];
    case 'ERROR':
      return red[500];
    case 'CANCELED':
      return grey[800];
    case 'PENDING':
      return orange[500];
    case 'UNPROCESSED':
      return grey[700];
    default:
      return purple[500];
  }
};

const ClipStatus: React.FC<ClipStatusProps> = ({ status }) => {
  const color = getChipStatusColor(status);
  const title = status.charAt(0).toUpperCase() + status.slice(1);

  return (
    <CustomTooltip color={color} title={title} arrow>
      <Avatar sx={{ bgcolor: color, width: 10, height: 10, fontSize: 4 }}> </Avatar>
    </CustomTooltip>
  );
};

export default ClipStatus;
