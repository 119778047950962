import { apiGatewayUrl } from '../shared/constants';

export type WebsocketConfig = {
  path?: string;
  jwt?: string;
};

const WebsocketbaseConfig: WebsocketConfig = {};

export function GetWebsocketUrl(endpoint: string): string {
  const { path, jwt } = WebsocketbaseConfig;
  const gatewayUrl = new URL(apiGatewayUrl);
  gatewayUrl.pathname = path.concat(endpoint);
  gatewayUrl.protocol = gatewayUrl.protocol.replace('http', 'ws');
  gatewayUrl.searchParams.append('token', jwt);
  return gatewayUrl.toString();
}

export function configureWebSocket(path: string, jwt?: string) {
  WebsocketbaseConfig.jwt = jwt;
  WebsocketbaseConfig.path = path;
}
