import React, { useEffect, useState } from 'react';
import { TextField, Button, Box, Typography } from '@mui/material';

interface UserFormProps {
  initialFirstName?: string;
  initialLastName?: string;
  onSubmit: (firstName: string, lastName: string) => void;
}

export const UserForm: React.FC<UserFormProps> = ({
  initialFirstName = '',
  initialLastName = '',
  onSubmit,
}) => {
  const [firstName, setFirstName] = useState(initialFirstName);
  const [lastName, setLastName] = useState(initialLastName);
  const [error, setError] = useState('');

  const validateField = (field: string) => {
    return field.length === 0 || field.length >= 3;
  };

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    e.stopPropagation();

    if (!validateField(firstName) || !validateField(lastName)) {
      setError('First name and last name must be at least 3 characters long if provided');
      return;
    }

    setError('');
    onSubmit(firstName, lastName);
  };

  useEffect(() => {
    setFirstName(initialFirstName);
    setLastName(initialLastName);
  }, [initialFirstName, initialLastName]);

  return (
    <Box
      component="form"
      noValidate
      autoComplete="off"
      onSubmit={handleSubmit}
      sx={{ maxWidth: 400, mt: 4 }}
    >
      <TextField
        label="First Name"
        fullWidth
        margin="normal"
        value={firstName}
        onChange={(e) => setFirstName(e.target.value)}
        error={firstName.length > 0 && firstName.length < 3}
        helperText={
          firstName.length > 0 && firstName.length < 3
            ? 'First name must be at least 3 characters'
            : ''
        }
      />
      <TextField
        label="Last Name"
        fullWidth
        margin="normal"
        value={lastName}
        onChange={(e) => setLastName(e.target.value)}
        error={lastName.length > 0 && lastName.length < 3}
        helperText={
          lastName.length > 0 && lastName.length < 3
            ? 'Last name must be at least 3 characters'
            : ''
        }
      />
      {error && (
        <Typography color="error" variant="body2" sx={{ mb: 2 }}>
          {error}
        </Typography>
      )}
      <Button
        type="submit"
        variant="contained"
        color="primary"
        sx={{ mt: 2, mx: 'auto', display: 'block' }}
      >
        Update
      </Button>
    </Box>
  );
};
