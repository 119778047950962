import React from 'react';
import TimezoneSelect, { allTimezones } from '@mahhoura/react-timezone-select-mui';
import { UserStateContext } from '@/shared/components/UserStateProvider';
import { Box, Button } from '@mui/material';

export default function TimeZoneSelector() {
  const { isLoading, user, updateUserTimezone } = React.useContext(UserStateContext);
  const [selectedTimezone, setSelectedTimezone] = React.useState(user?.tz);

  const handleChange = (changedTZ) => {
    setSelectedTimezone(changedTZ.value);
  };

  const { timeZone } = Intl.DateTimeFormat().resolvedOptions();

  if (user?.tz === undefined) {
    return <></>;
  }

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    updateUserTimezone(selectedTimezone);
  };

  return (
    <Box component="form" onSubmit={handleSubmit} sx={{ maxWidth: 400, mt: 4 }}>
      <TimezoneSelect
        disabled={isLoading}
        onChange={handleChange}
        timezones={allTimezones}
        label="Timezone"
        variant="filled"
        helperText={`Your browser's timezone is ${timeZone}`}
        value={selectedTimezone}
      />

      <Button
        type="submit"
        variant="contained"
        color="primary"
        sx={{ mt: 2, mx: 'auto', display: 'block' }}
      >
        Update
      </Button>
    </Box>
  );
}
