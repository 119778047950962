import { keyframes } from '@mui/styled-engine';

const generateKeyframes = () => {
  let keyframesCSS = '';

  for (let i = 0; i <= 60; i++) {
    const percentage = (i / 60) * 100;
    const hue = (i * 6) % 360; // Spread the gradient evenly across the color wheel
    keyframesCSS += `
          ${percentage}% {
            background: linear-gradient(${hue}deg, #30c5ff,#00FFC5);
          }
        `;
  }

  return keyframesCSS;
};

// Define the animation keyframes
export const animateBorder = keyframes`
${generateKeyframes()}
`;
