import { useHotkeys } from 'react-hotkeys-hook';

import { KeyboardShortcuts } from '@/shared/constants/keyboardShortcuts';

const useClipHotkeys = (
  onAddBookmark: () => void,
  onAddClip: () => void,
  onSetClipStart: () => void,
  onSetClipEnd: () => void,
) => {
  useHotkeys(
    KeyboardShortcuts.AddBookmark.hotkey,
    (e) => {
      e.preventDefault();
      onAddBookmark();
    },
    [onAddBookmark],
  );
  useHotkeys(
    KeyboardShortcuts.AddClip.hotkey,
    (e) => {
      e.preventDefault();
      onAddClip();
    },
    [onAddClip],
  );
  useHotkeys(
    KeyboardShortcuts.SetClipStart.hotkey,
    (e) => {
      e.preventDefault();
      onSetClipStart();
    },
    [onSetClipStart],
  );
  useHotkeys(
    KeyboardShortcuts.SetClipEnd.hotkey,
    (e) => {
      e.preventDefault();
      onSetClipEnd();
    },
    [onSetClipEnd],
  );
};

export default useClipHotkeys;
