import * as React from 'react';
import { Divider, ListItemText, Stack, Typography } from '@mui/material';
import useGameTypeMetadataQuery from '@/shared/hooks/useGameTypeMetadataQuery';
import { useTeamLogoPath } from '@/shared/hooks/useTeamLogoPath';
import { useGameState } from '@/shared/hooks/websocket/useGameState';
import { GameTiming } from '@/shared/types/GameStateModel';
import { PeriodNamingMapping } from '@/features/autoIso/types/GameTypeMetadataModel';

const getOrdinalSuffix = (i: number) => {
  const j = i % 10;
  const k = i % 100;

  if (j === 1 && k !== 11) {
    return 'st';
  }
  if (j === 2 && k !== 12) {
    return 'nd';
  }
  if (j === 3 && k !== 13) {
    return 'rd';
  }
  return 'th';
};

function splitNumber(n: number): [number, number] {
  const base = Math.floor(n / 100) * 100;
  const remainder = n % 100;
  return [base, remainder];
}

type TeamData = {
  abbreviation: string;
  externalid: string;
  name: string;
  score: number;
};

type Props = {
  data: {
    awayTeam: TeamData;
    homeTeam: TeamData;
    periodOfPlay: number;
    periodTimeRemaining: string;
    periodTimeElapsed: string;
    periodOrdinal: number;
    gameClock: string;
  };
};

const ScoreboardData: React.FC<Props> = ({ data }) => {
  const [gameState] = useGameState();
  const gameTypeMetadataQuery = useGameTypeMetadataQuery();

  const formatPeriodName = React.useCallback(
    (periodOfPlay: number) => {
      const periodsMappings = gameTypeMetadataQuery.data
        ?.periodNamingMapping[0] as PeriodNamingMapping;
      if (!periodsMappings) {
        return `Period ${periodOfPlay}`;
      }

      const { mappings } = periodsMappings;
      const [base, remainder] = splitNumber(periodOfPlay);

      if (base === 0 && remainder === 0) {
        return `NA`;
      }

      let periodName = mappings[base] || periodsMappings.periodName;
      const periodOrdinal = `${remainder}${getOrdinalSuffix(remainder)} ${periodName}`;

      return periodOrdinal;
    },
    [gameTypeMetadataQuery.data],
  );

  if (!gameState?.game) {
    return (
      <Stack
        direction="row"
        spacing={3}
        sx={{
          flexGrow: 1,
          whiteSpace: 'nowrap',
          textOverflow: 'ellipsis',
          overflow: 'hidden',
        }}
      />
    );
  }

  const { game: gameStateData } = gameState as { game: GameTiming };

  if (!data) {
    return (
      <Stack
        direction="row"
        spacing={3}
        sx={{
          flexGrow: 1,
          whiteSpace: 'nowrap',
          textOverflow: 'ellipsis',
          overflow: 'hidden',
        }}
      />
    );
  }

  return (
    <Stack
      direction="row"
      spacing={3}
      alignItems="center"
      divider={<Divider orientation="vertical" flexItem />}
      sx={{
        flexGrow: 1,
        whiteSpace: 'nowrap',
        textOverflow: 'ellipsis',
        overflow: 'hidden',
      }}
    >
      <Stack direction="row" spacing={3} alignItems="center">
        <Stack direction="row" spacing={2} alignItems="center">
          <img
            loading="lazy"
            width="40"
            src={useTeamLogoPath(data.awayTeam)}
            alt={data.awayTeam.abbreviation}
          />
          <ListItemText primary={data.awayTeam.abbreviation} secondary={data.awayTeam.name} />
          <Typography
            variant="h4"
            fontWeight="bold"
            minWidth={50}
            textAlign={'center'}
            sx={(theme) => {
              return {
                color:
                  gameStateData.score.home <= gameStateData.score.away
                    ? theme.palette.grey[50]
                    : theme.palette.grey[500],
              };
            }}
          >
            {gameStateData.score.away}
          </Typography>
        </Stack>
        <Typography
          variant="subtitle1"
          sx={(theme) => ({
            color: theme.palette.grey[600],
          })}
        >
          @
        </Typography>
        <Stack direction="row" spacing={2} alignItems="center">
          <Typography
            variant="h4"
            fontWeight="bold"
            minWidth={50}
            textAlign={'center'}
            sx={(theme) => {
              return {
                color:
                  gameStateData.score.home >= gameStateData.score.away
                    ? theme.palette.grey[50]
                    : theme.palette.grey[500],
              };
            }}
          >
            {gameStateData.score.home}
          </Typography>
          <ListItemText
            primary={data.homeTeam.abbreviation}
            secondary={data.homeTeam.name}
            sx={{ '&.MuiListItemText-root': { textAlign: 'right' } }}
          />
          <img
            loading="lazy"
            width="40"
            src={useTeamLogoPath(data.homeTeam)}
            alt={data.homeTeam.abbreviation}
          />
        </Stack>
      </Stack>
      <Stack direction="row" spacing={2} alignItems="center">
        <Typography variant="h4" fontWeight="bold" minWidth={100}>
          {gameStateData.clock}
        </Typography>
        <Typography
          variant="subtitle1"
          fontWeight="bold"
          textTransform="uppercase"
          sx={(theme) => ({
            color: theme.palette.grey[400],
          })}
        >
          {formatPeriodName(gameStateData.period)}
        </Typography>
      </Stack>
    </Stack>
  );
};

export default ScoreboardData;
