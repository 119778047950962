import React from 'react';
// import { useBeforeunload } from 'react-beforeunload';
import { Provider } from 'react-redux';
import { Store } from 'redux';

// import { Tracking } from '@/shared/services/tracking';
import { CognitoProvider } from './features/authentication/components';

import { App } from './features/scaffolding/components';

type Props = {
  store: Store;
};

const Root = ({ store }: Props) => (
  /*
  useBeforeunload(() => {
    Tracking.getInstance().drain();
    return 'Are you sure you want to Leave ?';
  }); */
  <Provider store={store}>
    <CognitoProvider>
      <App />
    </CognitoProvider>
  </Provider>
);
export default Root;
