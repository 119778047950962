import { isEqual, set } from 'lodash';
import React, { useEffect, useMemo, useState } from 'react';
import { alpha, Stack, ToggleButton, ToggleButtonGroup, Typography } from '@mui/material';

import Autocomplete, { AutocompleteRenderInputParams } from '@mui/material/Autocomplete';
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import { useFlags } from 'launchdarkly-react-client-sdk';

import useServerStateContext from '@/shared/hooks/useServerStateContext';
import { NO_TRACKING_ID } from '@/shared/hooks/useServerState';
import useGameTypeMetadataQuery from '@/shared/hooks/useGameTypeMetadataQuery';

import useAutoIsoEntitiesQuery from '../../autoIso/hooks/useAutoIsoEntitiesQuery';
import {
  AutoIsoModel,
  AutoIsoObjectType,
  AutoIsoPlayer,
  getTeam,
  OFFICIAL_PEOPLE_GROUP,
} from '../../autoIso/types';
import { TeamModel } from '../../autoIso/types/TeamModel';
import PtzControls from '../../games/components/PtzControls';
import { useGameStateRef } from '@/shared/hooks/websocket/useGameState';
import { ViewType } from '../types/VideoSource';

type PlayerOption = AutoIsoPlayer & { isActive: boolean };

const filterByTeam = (team: TeamModel) => (e: AutoIsoModel) =>
  e.type === AutoIsoObjectType.Player && e.playerTeamId && e.playerTeamId === team.id;

const setIsActive = (activeEntities: string[] | number[]) => (e: AutoIsoModel) =>
  ({
    ...e,
    isActive:
      activeEntities.find((id) => Number.parseInt(id, 10) === Number.parseInt(e.id, 10)) !==
      undefined,
  }) as PlayerOption;

const sortPlayers = (a: PlayerOption, b: PlayerOption) => {
  const aIsActive = a.isActive ? 1 : -1;
  const bIsActive = b.isActive ? 1 : -1;
  return bIsActive - aIsActive;
};

const AutoIsoSelect = () => {
  const [initialized, setInitialized] = useState(false);
  const { enableRefTracking } = useFlags();
  const { trackEntity, singleCurrentlyTrackedObjectId, viewType } = useServerStateContext();

  const [quadViewSelected, setQuadViewSelected] = useState(viewType === ViewType.MOSAIC);

  const gameMetadataQuery = useGameTypeMetadataQuery();

  const selectedId = singleCurrentlyTrackedObjectId;

  const autoIsoQuery = useAutoIsoEntitiesQuery();
  const { homeTeam, awayTeam } = autoIsoQuery.data;
  const autoIsoEntityLookup = autoIsoQuery?.data?.lookup || {};
  const autoIsoEntities = autoIsoQuery?.data?.list || [];
  const selectedEntity = autoIsoEntityLookup ? autoIsoEntityLookup[selectedId] : undefined;
  const ballPuck = autoIsoEntities.find((e) => e.type === AutoIsoObjectType.BallOrPuck);
  const hasOfficial = autoIsoEntities.some((e) => e.type === AutoIsoObjectType.Official);

  const [selectedObjectType, setSelectedObjectType] = useState<AutoIsoObjectType>(
    selectedEntity?.type,
  );

  const [selectedTeam, setSelectedTeam] = useState<string>(getTeam(selectedEntity));

  const [activeEntities, setActiveEntities] = useState<number[]>([]);

  useGameStateRef({
    onChange: (data) => {
      const entities = data.tracking.entities;
      const newIds = entities.map((e) => e.id);
      if (!isEqual(newIds, activeEntities)) {
        setActiveEntities(newIds);
      }
    },
  });

  useEffect(() => {
    if (viewType === ViewType.MOSAIC) {
      setQuadViewSelected(true);
      if (selectedObjectType === AutoIsoObjectType.None)
        if (ballPuck) trackEntity({ id: '1' }, 'Automatic Switch');
    } else {
      setQuadViewSelected(false);
    }
  }, [viewType, ballPuck]);

  useEffect(() => {
    setTimeout(() => {
      if (!initialized) {
        if (ballPuck) {
          trackEntity({ id: '1' }, 'Initial Switch');
        }
        setInitialized(true);
      }
    }, 1000);
  }, [ballPuck]);

  useEffect(() => {
    const selectedEntitiIsUndefined = typeof selectedEntity === 'undefined';
    const selectedEntityIsNull = selectedEntity?.type === null;
    const selectedEntityNullOrUndefined = selectedEntitiIsUndefined || selectedEntityIsNull;

    setSelectedObjectType(
      selectedEntityNullOrUndefined ? AutoIsoObjectType.None : selectedEntity?.type,
    );
    setSelectedTeam(getTeam(selectedEntity));
  }, [selectedEntity]);

  const playerSelectorsLookup = useMemo(() => {
    const handleChange = (_e: unknown, value: AutoIsoModel | string) => {
      const source = 'Players Dropdown';
      if (!value) {
        trackEntity('none', source);
      } else if (typeof value === 'string') {
        const entity = autoIsoEntityLookup[value];
        const id = entity ? entity.id : 'none';
        trackEntity(entity || { id }, source);
      } else {
        trackEntity(value, source);
      }
    };

    const officialOptions =
      autoIsoEntities?.filter((e) => e.type === AutoIsoObjectType.Official) || [];

    const sizeProp: { size: 'small' } = {
      size: 'small',
    };

    const sharedProps = {
      disableClearable: true,
      blurOnSelect: true,
      ...sizeProp,
      autoHighlight: true,
      isOptionEqualToValue: (option: AutoIsoModel, val: AutoIsoModel) => option.id === val.id,
      onChange: handleChange,
    };

    const getPlayerSelectorProps = (team: TeamModel) => {
      const options =
        autoIsoEntities
          ?.filter(filterByTeam(team))
          .map(setIsActive(activeEntities))
          .sort(sortPlayers) || [];

      return {
        ...sharedProps,
        //key: `${team.id}-selector`,
        options,
        getOptionLabel: (option: string | PlayerOption) =>
          typeof option === 'string'
            ? option
            : `${option.playerNumber} - ${option.displayName} (${option.playerPosition})`,
        renderOption: (props: React.HTMLAttributes<HTMLLIElement>, option: PlayerOption) => (
          <Box
            component="li"
            {...props}
            sx={(theme) => ({
              color: option.isActive ? 'rgb(255, 255, 255)' : 'rgba(255, 255, 255, 0.7)',
              borderBottom: `1px solid ${theme.palette.divider}`,
            })}
          >
            {option.playerNumber} -{option.displayName} ({option.playerPosition})
          </Box>
        ),
        value: options.find((o) => o.id === selectedId) || null,
        onChange: handleChange,
        renderInput: (params: AutocompleteRenderInputParams) => (
          <TextField
            {...params}
            label="Player"
            inputProps={{
              ...params.inputProps,
              autoComplete: 'new-password', // disable autocomplete and autofill
            }}
          />
        ),
      };
    };

    return {
      [homeTeam?.id]: <Autocomplete {...getPlayerSelectorProps(homeTeam)} />,
      [awayTeam?.id]: <Autocomplete {...getPlayerSelectorProps(awayTeam)} />,
      [AutoIsoObjectType.Official]: (
        <Autocomplete
          {...sharedProps}
          key={`${OFFICIAL_PEOPLE_GROUP}-selector`}
          options={officialOptions}
          getOptionLabel={(option) => (typeof option === 'string' ? option : option.displayName)}
          renderOption={(props, option) => (
            <Box component="li" {...props}>
              {option.displayName}
            </Box>
          )}
          value={officialOptions.find((o) => o.id === selectedId) || null}
          renderInput={(params) => (
            <TextField
              {...params}
              label="Refs"
              inputProps={{
                ...params.inputProps,
                autoComplete: 'new-password', // disable autocomplete and autofill
              }}
            />
          )}
        />
      ),
    };
  }, [
    autoIsoEntities,
    autoIsoEntityLookup,
    activeEntities.join(','), // slight hack because we need a deep compare for this array
    awayTeam,
    homeTeam,
    selectedId,
    trackEntity,
  ]);

  const getTeamColor = (team: TeamModel, index: number, theme) => {
    if (index != 0 && index != 1) throw new Error('Invalid index');
    const color =
      team.teamColors[index]?.hex_color ||
      (index === 0 ? theme.palette.primary.main : theme.palette.secondary.main);
    return color;
  };

  return (
    <Box mb={2}>
      <Stack flexDirection="row" sx={{ m: 3, mt: 0 }}>
        <ToggleButtonGroup
          color="primary"
          value={selectedObjectType}
          exclusive
          onChange={(_e, group) => {
            if (group !== null) {
              setSelectedObjectType(group);
            }
          }}
          size="small"
          fullWidth
        >
          <ToggleButton
            value={AutoIsoObjectType.None}
            onClick={() => {
              trackEntity(NO_TRACKING_ID, 'Toggle Button');
            }}
            disabled={quadViewSelected}
            size="small"
          >
            Manual
          </ToggleButton>

          {!!enableRefTracking && (
            <ToggleButton value={AutoIsoObjectType.Official} disabled={!hasOfficial}>
              Ref
            </ToggleButton>
          )}
          <ToggleButton
            value={AutoIsoObjectType.BallOrPuck}
            onClick={() => trackEntity(ballPuck?.id, 'Toggle Button')}
          >
            {gameMetadataQuery.data?.objectOfPlay || 'Ball/Puck'}
          </ToggleButton>
          <ToggleButton value={AutoIsoObjectType.Player}>Player</ToggleButton>
        </ToggleButtonGroup>
      </Stack>

      {selectedObjectType === AutoIsoObjectType.Player && (
        <Box sx={{ mx: 3 }}>
          <Typography component="label" variant="subtitle2" id="teams-label" htmlFor="teams-field">
            Teams
          </Typography>
          <Stack direction="row" sx={{ marginTop: 1 }}>
            <ToggleButtonGroup
              color="primary"
              value={selectedTeam}
              exclusive
              onChange={(_e, peopleGroup) => setSelectedTeam(peopleGroup)}
              size="small"
              aria-labelledby="teams-label"
              id="teams-field"
            >
              <ToggleButton
                sx={(theme) => ({
                  px: 2,
                  borderColor: getTeamColor(homeTeam, 0, theme),
                  backgroundColor: alpha(getTeamColor(homeTeam, 1, theme), 0.5),
                  color: getTeamColor(homeTeam, 0, theme),
                  opacity: 0.5,
                  '&.Mui-selected': {
                    borderColor: getTeamColor(homeTeam, 1, theme),
                    color: getTeamColor(homeTeam, 1, theme),
                    backgroundColor: alpha(getTeamColor(homeTeam, 0, theme), 0.5),
                    opacity: 1,
                  },
                  '&.Mui-selected:hover': {
                    borderColor: getTeamColor(homeTeam, 1, theme),
                    color: getTeamColor(homeTeam, 1, theme),
                    backgroundColor: alpha(getTeamColor(homeTeam, 0, theme), 0.7),
                  },
                })}
                value={homeTeam.id}
              >
                {homeTeam.abbreviation}
              </ToggleButton>
              <ToggleButton
                sx={(theme) => ({
                  px: 2,
                  borderColor: getTeamColor(awayTeam, 0, theme),

                  backgroundColor: alpha(getTeamColor(awayTeam, 1, theme), 0.5),
                  color: getTeamColor(awayTeam, 0, theme),
                  opacity: 0.5,
                  '&.Mui-selected': {
                    borderColor: getTeamColor(awayTeam, 1, theme),
                    color: getTeamColor(awayTeam, 1, theme),
                    backgroundColor: alpha(getTeamColor(awayTeam, 0, theme), 0.5),
                    opacity: 1,
                  },
                  '&.Mui-selected:hover': {
                    borderColor: getTeamColor(awayTeam, 1, theme),
                    color: getTeamColor(awayTeam, 1, theme),
                    backgroundColor: alpha(getTeamColor(awayTeam, 1, theme), 0.4),
                  },
                })}
                value={awayTeam.id}
              >
                {awayTeam.abbreviation}
              </ToggleButton>
            </ToggleButtonGroup>
            <Box flex={1} ml={1}>
              {playerSelectorsLookup[selectedTeam]}
            </Box>
          </Stack>
        </Box>
      )}

      {selectedObjectType === AutoIsoObjectType.Official && (
        <Box sx={{ mx: 3 }}>{playerSelectorsLookup[AutoIsoObjectType.Official]}</Box>
      )}

      {selectedObjectType === AutoIsoObjectType.None && !quadViewSelected ? <PtzControls /> : null}
    </Box>
  );
};

export default AutoIsoSelect;
