/* eslint-disable no-case-declarations */
import { DateTime, Interval } from 'luxon';
import { enableBugSnag } from '@/shared/constants';

import * as Trackers from './providers';
import { Tracker } from './providers/Tracker';

import Bugsnag from '@bugsnag/js';

const capitalize = (s) =>
  s[0].toUpperCase() + s.slice(1).replace(/-./g, (x) => ` ${x[1].toUpperCase()}`);

const TIMED_EVENTS = ['Navigation', 'Switch Tracked Entity', 'Switch View', 'Playback Progress'];

export class Tracking implements Tracker {
  private static instance: Tracking;

  private name = 'Tracking';

  private trackers = [];

  private signedIn = false;

  private identified = false;

  private games = {};

  private timedEvents = [];

  private lastSwitchViewEvent = {};

  private trackInactivityTime() {
    let time: string | number | NodeJS.Timeout;

    const updateLastActivityTimer = () => {
      this.lastActivity = DateTime.now();
    };

    const resetTimer = () => {
      clearTimeout(time);
      time = setTimeout(updateLastActivityTimer, 10000);
    };

    document.onload = resetTimer;

    document.onmousemove = resetTimer;
    document.onmousedown = resetTimer; // touchscreen presses
    document.ontouchstart = resetTimer;
    document.onclick = resetTimer; // touchpad clicks
    document.onkeydown = resetTimer; // onkeypress is deprectaed
    document.addEventListener('scroll', resetTimer, true); // improved; see comments
  }

  private constructor() {
    Object.keys(Trackers).forEach((m) => {
      this.trackers.push(Trackers[m].getInstance());
    });

    this.trackInactivityTime();
  }

  private trackTimedEvent(timedEvent) {
    const { event: lastEvent, payload, date } = timedEvent;
    const now = DateTime.now();
    const duration = Interval.fromDateTimes(date, now);
    const lastEventDuration = duration.length('seconds');
    this.abstractTrack(lastEvent, { ...payload, duration: lastEventDuration });
  }

  private abstractTrack(trackingEvent, abstractPayload) {
    const payload = abstractPayload || {};
    try {
      if (payload.duration && payload.duration > 3600 * 2) {
        // should we discard this ?
        payload.duration = 3600 * 2;
        return;
      }

      this.trackers.forEach((t) => {
        t.track(capitalize(trackingEvent), payload);
      });
    } catch (e) {
      console.error(e);
      console.error(`Failed to track ${trackingEvent}`);
    }
  }

  private getLastLocation() {
    return this.timedEvents.find((el) => el.event === 'Navigation')?.payload.location;
  }

  private eventIsDuplicate(trackingEvent, payload) {
    try {
      switch (trackingEvent) {
        case 'Playback Progress':
          const playbackTrackedEvent = this.timedEvents.find(
            (el) => el.event === 'Playback Progress',
          );
          return playbackTrackedEvent?.payload.minutes === payload.minutes;
        case 'Navigation':
          const navaigationTrackedEvent = this.timedEvents.find((el) => el.event === 'Navigation');
          return navaigationTrackedEvent?.payload.location === payload.location;
        case 'Switch View':
          const switchViewTrackingEvent = this.timedEvents.find((el) => el.event === 'Switch View');
          if (switchViewTrackingEvent?.payload.fullscreenStatus) return false;
          return (
            switchViewTrackingEvent?.payload.camera?.id === payload.camera?.id &&
            switchViewTrackingEvent?.payload.source === payload.source &&
            switchViewTrackingEvent?.payload.type === payload.type
          );
        case 'Switch Tracked Entity':
          const trackedEvent = this.timedEvents.find((el) => el.event === 'Switch Tracked Entity');
          return (
            trackedEvent?.payload.entity.id === payload.entity.id &&
            trackedEvent?.payload.source === payload.source
          );
        default:
          return false;
      }
    } catch (e) {
      return false;
    }
  }

  public drain() {
    this.timedEvents.forEach((timedEvent) => {
      this.trackTimedEvent(timedEvent);
    });

    if (this.signedIn) {
      this.signOut();
    }
  }

  signIn(): void {
    if (this.signedIn) return;
    this.track('Sign In', { category: 'Auth' });
    this.signedIn = true;
  }

  signOut(): void {
    if (!this.signedIn) return;
    this.track('Sign Out', { category: 'Auth' });
    this.signedIn = false;
  }

  public static getInstance(): Tracking {
    if (!Tracking.instance) {
      Tracking.instance = new Tracking();
    }

    return Tracking.instance;
  }

  public navigation(newLocation: string, props?: any) {
    const lastLocation = this.getLastLocation();

    const payload = { category: 'Access & Navigation', location: newLocation, ...props };

    // if it's a game page
    if (/\/games\/\d+/i.test(newLocation)) {
      const match = /\/games\/(\d+)/i.exec(newLocation);
      payload.game = {
        id: match[1],
        isLive: this.games[match[1]]?.isLive,
      };
    }

    this.track('Navigation', payload);

    // leaving a game page
    // send the last timed tracking event
    if (/\/games\/\d+/i.test(lastLocation)) {
      console.debug('Was in game page .. draining tracking data');
      this.timedEvent('Switch Tracked Entity', {}, true);
      this.timedEvent('Switch View', {}, true);
    }
  }

  public identify(userId: string, userProps: any) {
    if (this.identified) return;
    if (!this.signedIn) this.signedIn = true;

    this.trackers.forEach((t) => {
      t.identify(userId, userProps);
    });

    if (enableBugSnag) {
      Bugsnag.setUser(userId, userProps.email, userProps.email);
    }

    this.track('New Session', { category: 'Auth' });

    this.identified = true;
  }

  public timedEvent(trackingEvent, payload, eviction = false) {
    if (this.eventIsDuplicate(trackingEvent, payload)) {
      console.debug(`${trackingEvent} is duplicate`);
      return;
    }
    const newTimedEvent = { event: trackingEvent, date: DateTime.now(), payload };
    const trackedEventIndex = this.timedEvents.findIndex((el) => el.event === trackingEvent);
    if (trackedEventIndex >= 0) {
      this.trackTimedEvent(this.timedEvents[trackedEventIndex]);
      if (eviction) {
        // if it's an eviction, remove the event from the list of timed events
        this.timedEvents = this.timedEvents.filter((el) => el.event === trackingEvent);
      } else if (trackingEvent === 'Switch View' && payload?.fullscreenStatus === 'off') {
        // if we received the exit from the fullscreen
        // replace the last event received with the last view switch view event (single OR quad)
        this.lastSwitchViewEvent.date = DateTime.now();
        this.timedEvents[trackedEventIndex] = this.lastSwitchViewEvent;
      } else {
        // else replact the event with the new one
        this.timedEvents[trackedEventIndex] = newTimedEvent;
      }
    } else {
      // if it's an eviction .. do nothing !
      // eslint-disable-next-line no-lonely-if
      if (!eviction) this.timedEvents.push(newTimedEvent);
    }
  }

  public track(trackingEvent: string, payload?: any): void {
    const isTimedEvent = TIMED_EVENTS.includes(trackingEvent);

    if (trackingEvent === 'Click Watch Game') {
      const { isLive, game } = payload;
      this.games[`${game}`] = { isLive };
    }

    if (trackingEvent === 'Switch View') {
      const { fullscreenStatus } = payload;
      if (!fullscreenStatus) {
        this.lastSwitchViewEvent = {
          event: trackingEvent,
          payload,
        };
      }
    }

    if (isTimedEvent) {
      return this.timedEvent(trackingEvent, payload);
    }
    // immediate track
    return this.abstractTrack(trackingEvent, payload);
  }

  public toString(): string {
    return this.name;
  }
}

export default Tracking;
