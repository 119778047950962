export const getFirstLettersOfName = (email: string) => {
  if (!email) return null;
  const name = email.split('@')[0].replace('.', ' ');
  if (name.split(' ').length === 1) {
    return name.substring(0, 2).toUpperCase();
  }
  const [first, last] = name.split(' ');
  return `${first.substring(0, 1).toUpperCase()}${last.substring(0, 1).toUpperCase()}`;
};

export default getFirstLettersOfName;
