import { MutableRefObject, useEffect, useState } from 'react';

export type WebSocketStateHookReturn<TValue> = [TValue, (value: TValue) => unknown] | [TValue];

export type WebSocketRefHookReturn<TValue> =
  | [MutableRefObject<TValue>, (value: TValue) => unknown]
  | [MutableRefObject<TValue>];

export type WebSocketRefHookOptions<TValue> = {
  onChange?: (value: TValue) => unknown;
};

export type WebSocketRefHook<TValue> = (
  options?: WebSocketRefHookOptions<TValue>,
) => WebSocketRefHookReturn<TValue>;

export const withStateFromRef =
  <TValue>(useRefHook: WebSocketRefHook<TValue>) =>
  (): WebSocketStateHookReturn<TValue> => {
    const [refValue, setRefValue] = useRefHook({
      // onChange: (value) => {
      //   setState(value);
      // },
    });

    const [state, setState] = useState<TValue>(refValue.current);

    const setValue = (value: TValue) => {
      setRefValue(value);
    };

    useEffect(() => {
      let isRunning = true;

      const tick = () => {
        if (refValue.current !== state) {
          setState(refValue.current);
        }

        if (isRunning) {
          window.requestAnimationFrame(tick);
        }
      };

      window.requestAnimationFrame(tick);

      return () => {
        isRunning = false;
      };
    }, [state]);

    return [state, setValue];
  };
