import React from 'react';

import ErrorTwoTone from '@mui/icons-material/ErrorTwoTone';
import Box from '@mui/material/Box';
import { Theme } from '@mui/material/styles';
import Typography from '@mui/material/Typography';
import { makeStyles } from '@mui/styles';

export type Props = {
  validationErrors: string[];
};

const useStyles = makeStyles((theme: Theme) => ({
  errorText: {
    marginLeft: theme.spacing(1),
  },
}));

const FormValidationSummary = ({ validationErrors }: Props) => {
  const classes = useStyles();
  if (!validationErrors || validationErrors.length <= 0) {
    return null;
  }

  return (
    <>
      {validationErrors.map((ve) => (
        <Box display="flex" alignItems="center" marginTop={1} key={ve}>
          <ErrorTwoTone fontSize="small" color="error" />
          <Typography variant="body2" color="error" className={classes.errorText}>
            {ve}
          </Typography>
        </Box>
      ))}
    </>
  );
};

export default FormValidationSummary;
