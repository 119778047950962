import { useQuery } from '@tanstack/react-query';
/* eslint-disable import/prefer-default-export */
import { get } from '@truefit/http-utils';
import useCurrentGameId from '@/shared/hooks/useCurrentGameId';

export const getQueryKey = (gameId: number) => ['Games', gameId, 'Cameras'];

const useCamerasQuery = () => {
  const gameId = useCurrentGameId();

  return useQuery({
    queryKey: getQueryKey(gameId),
    queryFn: async (): Promise<any> => {
      if (typeof gameId === 'undefined' || gameId === null || Number.isNaN(gameId)) {
        return [];
      }

      return await get<any>(`games/${gameId}/cameras`);
    },
    enabled: false,
    gcTime: Infinity,
    placeholderData: {},
  });
};

export default useCamerasQuery;
