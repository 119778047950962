/* eslint-disable import/prefer-default-export */
import { useMutation } from '@tanstack/react-query';
import { get } from '@truefit/http-utils';

const useEnqueueClipForExportMutation = () =>
  useMutation({
    mutationFn: async (clipId: number) => {
      await get(`/clips/${clipId}/export`);
    },
    gcTime: 0,
  });

export default useEnqueueClipForExportMutation;
