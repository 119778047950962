/* eslint @typescript-eslint/no-unused-vars: "off" */

import React, { createContext, useState, ReactNode } from 'react';

export const LeagueContext = createContext({
  leagueLogo: null,
  setLeagueLogo: (logo: string) => {},
});

export const LeagueProvider = ({ children }: { children: ReactNode }) => {
  const [leagueLogo, setLeagueLogo] = useState('');

  return (
    <LeagueContext.Provider value={{ leagueLogo, setLeagueLogo }}>
      {children}
    </LeagueContext.Provider>
  );
};
