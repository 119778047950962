import * as React from 'react';

import { Box, Typography } from '@mui/material';

import { CognitoContext } from '@/features/authentication/components/CognitoProvider';
import { KeyboardShortcutsDialog } from '@/shared/components';
import useClipStateContext from '@/shared/hooks/useClipStateContext';
import useServerStateContext from '@/shared/hooks/useServerStateContext';

import useCameraPtzKeyboardBindings from '../../camera/hooks/useCameraPtzKeyboardBindings';
import { PlayerControls } from '../../video/components';
import useFullscreen from '../../video/hooks/useFullscreen';
import usePlayer from '../../video/hooks/usePlayer';
import {
  ACCEPTABLE_GRID_SIZES,
  CLIP_MODE_ALERT_HEIGHT,
  PLAYER_CONTROLS_HEIGHT,
  TOOLBAR_HEIGHT,
  VIDEO_PLAYER_ID,
} from '../constants';
import WheelZoom from './WheelZoom';
import WebrtcMetrics from './WebrtcMetrics';
import RotatingIcon from '@/shared/components/RotatingIcon';
import { usePTZStore } from '@/shared/hooks/store/usePTZStore';
import { usePTZSocket } from '@/shared/hooks/websocket/usePTZSocket';
import { ViewType } from '@/features/camera/types/VideoSource';
import { useFlags } from 'launchdarkly-react-client-sdk';

const adjust = (value: number | undefined, diff: number | undefined, defaultValue: number) => {
  if (value === undefined) {
    return defaultValue;
  }

  return diff === undefined ? value : value + diff;
};

const GamePageContents = () => {
  const { ref: fullscreenRef, requestFullscreen, isFullscreen, exitFullscreen } = useFullscreen();
  const videoControls = React.useRef<HTMLDivElement>(null);

  const singleCameraRef = React.useRef(null);
  const { cognitoContext } = React.useContext(CognitoContext);
  const [statsReport, setStatsReport] = React.useState<RTCStatsReport>();

  const serverState = useServerStateContext();
  const { selectedCamera, selectCamera, viewType, availableCameras } = serverState;
  const clipState = useClipStateContext();

  const [toggleStatsVisibility, setToggleStatsVisibility] = React.useState(false);

  const { getValue, store } = usePTZStore();
  const { setValue } = usePTZSocket();

  const [selectedCameraPTZ, setSelectedCameraPTZ] = React.useState({ pan: 0, tilt: 0, zoom: 0 });

  const { debugData } = useFlags();

  const streamUrl = `wss://${cognitoContext?.sessionHandler}.aws.c360live.com/app/stream`;

  usePlayer({
    elId: VIDEO_PLAYER_ID,
    streamUrl,
    onWebrtcStatsChange: setStatsReport,
  });

  const updateWindowDimensions = () => {
    if (videoControls.current && singleCameraRef.current) {
      const videoContainerBoundaries = singleCameraRef.current.getBoundingClientRect();
      const videoControlsBoundaries = videoControls.current.getBoundingClientRect();
      const w = videoContainerBoundaries.width;
      const h = videoContainerBoundaries.height - videoControlsBoundaries.height;
    }
  };

  React.useEffect(() => {
    updateWindowDimensions();
  }, [videoControls.current, singleCameraRef.current]);

  // Update dimensions when the component mounts
  React.useEffect(() => {
    // Add event listener for window resize
    window.addEventListener('resize', updateWindowDimensions);

    // Cleanup the event listener when the component unmounts
    return () => {
      window.removeEventListener('resize', updateWindowDimensions);
    };
  }, []); // Empty dependency array ensures this effect runs only once on mount

  const gridSize = ACCEPTABLE_GRID_SIZES.find((size) => size >= availableCameras.length);
  const gridEdgeSize = Math.max(gridSize / 2, 1);

  useCameraPtzKeyboardBindings((ptzDiff) => {
    if (viewType === ViewType.MOSAIC) {
      return;
    }

    const { pan, tilt, zoom } = getValue(selectedCamera.idx);

    setValue({
      pan: adjust(pan, ptzDiff.panDiff, 0),
      tilt: adjust(tilt, ptzDiff.tiltDiff, 0),
      zoom: adjust(zoom, ptzDiff.zoomDiff, 100),
    });
  });

  const getCameraName = React.useCallback(() => selectedCamera?.name || '', [selectedCamera]);

  React.useEffect(() => {
    // What is the point of this ?
    if (!availableCameras.length) return;
    availableCameras.forEach((c) => {
      if (store[c.id] !== undefined) {
        c.pan = store[c.id].pan;
      }
    });
  }, [store]);

  React.useEffect(() => {
    if (getValue) {
      setSelectedCameraPTZ(getValue(selectedCamera.idx));
    }
  }, [getValue, selectedCamera.idx]);

  return (
    <Box
      ref={fullscreenRef}
      sx={() => ({
        display: 'flex',
        overflow: 'hidden',
        alignItems: 'center',
        justifyContent: 'center',
        flexDirection: 'column',
      })}
    >
      <Box
        sx={{
          width: '100%',
          maxWidth: `calc((100vh - ${
            (isFullscreen ? PLAYER_CONTROLS_HEIGHT : PLAYER_CONTROLS_HEIGHT + TOOLBAR_HEIGHT) +
            (clipState?.state.isEditing ? CLIP_MODE_ALERT_HEIGHT : 0)
          }px) * 1.7777)`, // keeps a 16/9 aspect ratio based on available height
          maxHeight: '100vh',
          position: 'relative',
        }}
        ref={singleCameraRef}
      >
        <WheelZoom>
          <Box id={VIDEO_PLAYER_ID} />

          {viewType === ViewType.MOSAIC && (
            <Box
              sx={{
                position: 'absolute',
                top: 0,
                left: 0,
                width: '100%',
                aspectRatio: '16/9',
                display: 'grid',
                gridTemplateColumns: `repeat(${gridEdgeSize}, 1fr)`,
              }}
            >
              {availableCameras.length > 0 &&
                availableCameras.map((c) => (
                  <Box
                    key={c.id}
                    onClick={() => selectCamera(c, 'Video Player')}
                    sx={{
                      color: 'white',
                      display: 'flex',
                      alignItems: 'flex-start',
                      justifyContent: 'space-between',
                      flexDirection: 'column',
                      fontWeight: 'bold',
                      fontSize: 26,
                      cursor: 'pointer',
                      position: 'relative',
                      transition: 'background-color 0.2s ease-in-out',
                      '&:hover': {
                        backgroundColor: 'rgba(0,0,0,0.1)',
                      },
                    }}
                  >
                    <Box
                      m={1}
                      sx={{
                        padding: '1px',
                        borderRadius: '4px',
                        position: 'absolute',
                        bottom: 0,
                        left: 0,
                        opacity: 0.2,
                        '&:hover': {
                          opacity: 1,
                        },
                        transition: 'opacity 0.2s ease-in-out',
                      }}
                    >
                      <RotatingIcon size={24} angle={c.pan - 45} />
                    </Box>
                    <Box
                      m={1}
                      sx={{
                        backgroundColor: 'rgba(0,0,0,0.4)',
                        padding: '5px',
                        borderRadius: '4px',
                        position: 'absolute',
                        bottom: 0,
                        right: 0,
                      }}
                    >
                      <Typography fontSize={10}>{c.name} </Typography>
                    </Box>
                  </Box>
                ))}
            </Box>
          )}

          {viewType !== ViewType.MOSAIC && (
            <Box
              m={1}
              sx={{
                backgroundColor: 'rgba(0,0,0,0.4)',
                padding: '5px',
                borderRadius: '4px',
                position: 'absolute',
                bottom: 100,
                right: 0,
              }}
            >
              {debugData && (
                <>
                  <pre>P {selectedCameraPTZ?.pan} </pre>
                  <pre>T {selectedCameraPTZ?.tilt} </pre>
                  <pre>Z {selectedCameraPTZ?.zoom} </pre>
                </>
              )}
              <Typography fontSize={12}>{getCameraName()} </Typography>
            </Box>
          )}
        </WheelZoom>

        <WebrtcMetrics toggleStatsVisibility={toggleStatsVisibility} statsReport={statsReport} />

        <Box
          display="flex"
          alignItems="stretch"
          flexDirection="column"
          sx={{ mb: 2 }}
          ref={videoControls}
        >
          <PlayerControls
            requestFullscreen={requestFullscreen}
            exitFullscreen={exitFullscreen}
            isFullscreen={isFullscreen}
            onStatsToggle={() => setToggleStatsVisibility(!toggleStatsVisibility)}
          />
        </Box>
      </Box>

      {/* this has to be inside of the fullscreen component so that it will show in fullscreen mode */}
      <KeyboardShortcutsDialog />
    </Box>
  );
};

export default GamePageContents;
