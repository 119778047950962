import React, { ReactNode } from 'react';
import { Controller, useFormContext } from 'react-hook-form';

import { FilledInputProps } from '@mui/material/FilledInput';
import { FormHelperTextProps as FormHelperTextPropsType } from '@mui/material/FormHelperText';
import { InputProps as StandardInputProps } from '@mui/material/Input';
import { InputBaseComponentProps } from '@mui/material/InputBase';
import { InputLabelProps as InputLabelPropsType } from '@mui/material/InputLabel';
import { OutlinedInputProps } from '@mui/material/OutlinedInput';
import TextField from '@mui/material/TextField';

type Props = {
  name: string;
  autoComplete?: string;
  autoFocus?: boolean;
  defaultValue?: string;
  disabled?: boolean;
  FormHelperTextProps?: Partial<FormHelperTextPropsType>;
  fullWidth?: boolean;
  helperText?: ReactNode;
  id?: string;
  InputLabelProps?: Partial<InputLabelPropsType>;
  InputProps?:
    | Partial<StandardInputProps>
    | Partial<FilledInputProps>
    | Partial<OutlinedInputProps>;
  inputProps?: InputBaseComponentProps;
  label?: ReactNode;
  margin?: 'none' | 'dense' | 'normal';
  multiline?: boolean;
  placeholder?: string;
  required?: boolean;
  rows?: string | number;
  rowsMax?: string | number;
  size?: 'small' | 'medium';
  type?: string;
  variant?: 'standard' | 'outlined' | 'filled';
  onBlur?: (e: React.FocusEvent<HTMLInputElement | HTMLTextAreaElement>) => void;
  onChange?: (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => void;
};

const HookFormTextField = ({
  name,
  autoComplete = 'off',
  autoFocus = false,
  defaultValue = '',
  disabled = false,
  FormHelperTextProps = {},
  fullWidth = true,
  helperText = '',
  id,
  InputLabelProps = {},
  InputProps = {},
  inputProps = {},
  label = '',
  margin = 'normal',
  multiline = false,
  placeholder = '',
  required = false,
  rows = 1,
  rowsMax = 4,
  size = 'medium',
  type = 'text',
  variant = 'outlined',
  onBlur = () => {},
  onChange = () => {},
}: Props) => {
  const {
    formState: { errors },
  } = useFormContext();
  const error = errors[name];
  const displayError = Boolean(error?.message);

  return (
    <Controller
      name={name}
      defaultValue={defaultValue}
      render={({ field: { onBlur: formOnBlur, onChange: formOnChange, value } }) => (
        <TextField
          name={name}
          autoComplete={autoComplete}
          autoFocus={autoFocus}
          disabled={disabled}
          error={displayError}
          FormHelperTextProps={FormHelperTextProps}
          fullWidth={fullWidth}
          helperText={displayError ? String(error.message) : helperText}
          id={id ?? name}
          InputLabelProps={InputLabelProps}
          InputProps={InputProps}
          inputProps={inputProps}
          label={label}
          margin={margin}
          multiline={multiline}
          onBlur={(e: React.FocusEvent<HTMLInputElement | HTMLTextAreaElement>) => {
            if (onBlur) onBlur(e);
            formOnBlur();
          }}
          onChange={(e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
            if (onChange) onChange(e);
            formOnChange(e);
          }}
          placeholder={placeholder}
          required={required}
          rows={rows}
          maxRows={rowsMax}
          size={size}
          type={type}
          value={value ?? ''}
          variant={variant}
        />
      )}
    />
  );
};

export default HookFormTextField;