import { useSnackbar } from 'notistack';
import React from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useHistory } from 'react-router-dom';
import * as Yup from 'yup';

import { yupResolver } from '@hookform/resolvers/yup';
import { nameofFactory } from '@/shared/services';

import {
  FormSubmitBar,
  FormValidationSummary,
  HookFormPasswordField,
} from '../../forms/components';
import useCognito from '../hooks/useCognito';
import { Box } from '@mui/material';

type ChangePasswordFormValues = {
  oldPassword: string;
  newPassword: string;
  passwordConfirmation: string;
};

const nameOf = nameofFactory<ChangePasswordFormValues>();

const validationSchema = Yup.object().shape({
  [nameOf('oldPassword')]: Yup.string().required().label('Current Password'),
  [nameOf('newPassword')]: Yup.string().required().max(50).min(8).label('New Password'),
  [nameOf('passwordConfirmation')]: Yup.string().oneOf(
    [Yup.ref('newPassword'), null],
    'Passwords must match',
  ),
});

const verificationCodeMessage = 'Invalid verification code provided, please try again.';

const useChangePassword = () => {
  const cognito = useCognito();
  const history = useHistory();
  const { enqueueSnackbar } = useSnackbar();
  const form = useForm<ChangePasswordFormValues>({
    defaultValues: {
      [nameOf('oldPassword')]: '',
      [nameOf('newPassword')]: '',
      [nameOf('passwordConfirmation')]: '',
    },
    resolver: yupResolver(validationSchema),
  });
  const [generalValidationErrors, setGeneralValidationErrors] = React.useState<string[]>([]);

  const handleSubmit = form.handleSubmit(
    (formValues: ChangePasswordFormValues) =>
      new Promise((resolve) => {
        if (!formValues) return;
        if (formValues.newPassword !== formValues.passwordConfirmation) {
          // don't submit if passwords don't match
          resolve(null);
          return;
        }

        cognito.changePassword({
          oldPassword: formValues.oldPassword,
          newPassword: formValues.newPassword,

          onSuccess() {
            enqueueSnackbar('Password successfully changed', { variant: 'success' });
            history.push('/');
            resolve(null);
          },

          onFailure(message) {
            if (message === verificationCodeMessage) {
              form.setError(
                nameOf('oldPassword'),
                { type: 'custom', message },
                { shouldFocus: true },
              );
            } else {
              setGeneralValidationErrors([message]);
            }

            resolve(null);
          },
        });
      }),
  );

  return { form, generalValidationErrors, handleSubmit };
};

const ChangePasswordForm = () => {
  const { form, handleSubmit, generalValidationErrors } = useChangePassword();

  return (
    <Box sx={{ padding: 2 }}>
      <FormProvider {...form}>
        <form onSubmit={handleSubmit} noValidate>
          <FormValidationSummary validationErrors={generalValidationErrors} />

          <HookFormPasswordField
            name={nameOf('oldPassword')}
            required
            label="Current Password"
            variant="outlined"
            margin="dense"
            autoComplete="current-password"
          />

          <HookFormPasswordField
            name={nameOf('newPassword')}
            required
            label="New Password"
            variant="outlined"
            margin="dense"
            autoComplete="new-password"
          />

          <HookFormPasswordField
            name={nameOf('passwordConfirmation')}
            required
            label="Confirm Password"
            variant="outlined"
            margin="dense"
            autoComplete="new-password-repeat"
          />

          <FormSubmitBar onSubmit={handleSubmit} submitting={form.formState.isSubmitting} />
        </form>
      </FormProvider>
    </Box>
  );
};

export default ChangePasswordForm;
