import React, { useEffect } from 'react';
import { Controller, FormProvider, useForm } from 'react-hook-form';

import Autocomplete from '@mui/material/Autocomplete';
import Box from '@mui/material/Box';
import Chip from '@mui/material/Chip';
import MenuItem from '@mui/material/MenuItem';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import useTagsQuery from '@/features/tags/hooks/useTagsQuery';
import useGameTeamsQuery from '@/features/games/hooks/useGameTeamsQuery';

import { HookFormDatePicker, HookFormSelect } from '../../forms/components';
import { useTeamLogoPath } from '@/shared/hooks/useTeamLogoPath';

type Props = {
  onChange: (values: FormValues) => unknown;
};

export type FormValues = {
  date: Date;
  team: string;
  sort: string;
  clipType: string;
  tags: string[];
};

export const DEFAULT_VALUES: FormValues = {
  date: null,
  team: null,
  sort: 'newest',
  clipType: 'all',
  tags: [],
};

type FieldsetProps = {
  legend: string;
  children: React.ReactNode;
};

const Fieldset = ({ legend, children }: FieldsetProps) => (
  <Box
    component="fieldset"
    sx={{
      border: 'none',
      display: 'flex',
      gap: 1,
      alignItems: 'center',
      margin: 0,
      padding: 0,
    }}
  >
    <Typography variant="overline">{legend}</Typography>
    {children}
  </Box>
);

const ClipsForm = ({ onChange }: Props) => {
  const methods = useForm<FormValues>({ defaultValues: DEFAULT_VALUES });
  const { watch, handleSubmit } = methods;
  const tagsQuery = useTagsQuery();
  const teamsQuery = useGameTeamsQuery();

  // submits the form onChange
  // from https://stackoverflow.com/a/70119332
  useEffect(() => {
    const subscription = watch(handleSubmit(onChange));
    return () => subscription.unsubscribe();
  }, [handleSubmit, watch]);

  return (
    <FormProvider {...methods}>
      <Box component="form" sx={{ display: 'flex', alignItems: 'center', marginTop: 4 }}>
        <Controller
          name="clipType"
          control={methods.control}
          render={({ field: { onChange: LocalOnChange, value } }) => {
            const handleClick = (newValue: string) => () => LocalOnChange(newValue);
            const variant = (testValue: string) => (value === testValue ? 'filled' : 'outlined');
            const color = (testValue: string) => (value === testValue ? 'primary' : 'default');

            return (
              <Box sx={{ display: 'flex', gap: 1 }}>
                <Chip
                  label="All"
                  variant={variant('all')}
                  onClick={handleClick('all')}
                  color={color('all')}
                />

                <Chip
                  label="Bookmarks"
                  variant={variant('bookmark')}
                  onClick={handleClick('bookmark')}
                  color={color('bookmark')}
                />

                <Chip
                  label="Clips"
                  variant={variant('clip')}
                  onClick={handleClick('clip')}
                  color={color('clip')}
                />
              </Box>
            );
          }}
        />

        <Box
          sx={{
            display: 'flex',
            gap: 2,
            flexGrow: 1,
            justifyContent: 'flex-end',
          }}
        >
          <Fieldset legend="Filter">
            <HookFormDatePicker
              name="date"
              TextFieldProps={{ size: 'small', label: 'Date', sx: { width: '200px' } }}
              DatePickerProps={{ label: 'Date', inputFormat: 'MM/dd/yyyy' }}
            />

            <Controller
              name="team"
              control={methods.control}
              render={({ field }) => (
                <Autocomplete
                  onChange={(_e, v) => {
                    field.onChange(v?.abbreviation || '');
                  }}
                  isOptionEqualToValue={(option, value) => option.abbreviation === value}
                  value={field.value}
                  id="team-selector"
                  getOptionLabel={(option: any) =>
                    option?.abbreviation ? option.abbreviation : option
                  }
                  options={teamsQuery.data}
                  renderOption={(props, option: any) => (
                    <Box component="li" sx={{ '& > img': { mr: 2, flexShrink: 0 } }} {...props}>
                      <img
                        loading="lazy"
                        width="35"
                        src={useTeamLogoPath(option)}
                        alt={option.abbreviation}
                      />
                      {option.abbreviation}
                    </Box>
                  )}
                  renderInput={(params) => <TextField {...params} label="Team" size="large" />}
                  sx={{ width: '200px' }}
                />
              )}
            />

            <Controller
              name="tags"
              control={methods.control}
              render={({ field }) => (
                <Autocomplete
                  size="small"
                  multiple
                  disablePortal
                  onChange={(_e, v) => {
                    field.onChange(v);
                  }}
                  value={field.value ?? ''}
                  id="tags-selector"
                  options={tagsQuery.data.map((i) => i.name)}
                  renderInput={(params) => <TextField {...params} label="Tags" size="large" />}
                  sx={{ width: '450px' }}
                />
              )}
            />
          </Fieldset>

          <Fieldset legend="Sort">
            <HookFormSelect name="sort" label="Time" size="large" margin="dense">
              <MenuItem value="newest">Newest</MenuItem>
              <MenuItem value="oldest">Oldest</MenuItem>
            </HookFormSelect>
          </Fieldset>
        </Box>
      </Box>
    </FormProvider>
  );
};

export default ClipsForm;
