import { useQuery } from '@tanstack/react-query';

// Define the expected response structure
export interface TimeApiResponse {
  abbreviation: string;
  client_ip: string;
  datetime: string;
  day_of_week: number;
  day_of_year: number;
  dst: boolean;
  dst_from: string | null;
  dst_offset: number;
  dst_until: string | null;
  raw_offset: number;
  timezone: string;
  unixtime: number;
  utc_datetime: string;
  utc_offset: string;
  week_number: number;
}

export let timeApiData = {} as TimeApiResponse;
// Function to fetch and return the data from the API
export async function getTimeApiData(): Promise<TimeApiResponse> {
  const response = await fetch('https://worldtimeapi.org/api/ip');
  if (!response.ok) {
    throw new Error('Failed to fetch data');
  }

  const data: TimeApiResponse = await response.json();
  return data;
}

export const useTimeApiData = () =>
  useQuery({
    queryKey: ['timeApiData'],
    queryFn: getTimeApiData,
    enabled: true,
  });
