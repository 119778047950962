import { SnackbarProvider } from 'notistack';
import React, { ReactNode } from 'react';

import { makeStyles } from '@mui/styles';

type Props = {
  children: ReactNode;
};

const useStyles = makeStyles({
  variantSuccess: {},
  variantError: {},
  variantWarning: {},
  variantInfo: {},
});

const StyledSnackbarProvider = ({ children }: Props) => {
  const classes = useStyles();

  return (
    <SnackbarProvider anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }} classes={classes}>
      <>{children}</>
    </SnackbarProvider>
  );
};

export default StyledSnackbarProvider;
