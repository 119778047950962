import * as React from 'react';

import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import InfoTwoToneIcon from '@mui/icons-material/InfoTwoTone';
import { Box, Button, Drawer, IconButton, Stack, Tooltip, Typography } from '@mui/material';
import { styled, useTheme } from '@mui/material/styles';
import useClipStateContext from '@/shared/hooks/useClipStateContext';
import useGameTypeMetadataQuery from '@/shared/hooks/useGameTypeMetadataQuery';

import { GamePositionsRepresentation } from '../../autoIso/components';
import useAutoIsoEntitiesQuery from '../../autoIso/hooks/useAutoIsoEntitiesQuery';
import { AutoIsoSelect } from '../../camera/components';
import { ClipsAndBookmarksPanel } from '../../clips/components';
import useClipHotkeys from '../../clips/hooks/useClipHotkeys';
import ViewingToolsMenuExpandablePanel from './ViewingToolsMenuExpandablePanel';
import PtzControls from './PtzControls';

import 'simplebar-react/dist/simplebar.min.css';
import useCurrentGameId from '@/shared/hooks/useCurrentGameId';
import useGameDetailsQuery from '../hooks/useGameDetailsQuery';
import { PANEL_PADDING } from '../constants';
import { closeSnackbar, enqueueSnackbar } from 'notistack';
type Props = {
  open: boolean;
  setOpen: (open: boolean) => unknown;
  width: number;
};

const DrawerHeader = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  padding: theme.spacing(0, 1),
  ...theme.mixins.toolbar,
  justifyContent: 'flex-start',
  background: 'rgba(40, 40, 40)',
  width: '100%',
  zIndex: theme.zIndex.drawer,
}));

const ViewingToolsMenu = ({ open, setOpen, width }: Props) => {
  const gameId = useCurrentGameId();

  const { data: game, isSuccess: isGameDataFetchedSuccessfully } = useGameDetailsQuery(gameId);
  const [enableTracking, setEnableTracking] = React.useState(true);
  const {
    handleAddBookmark,
    handleAddClip,
    handleSetStartTime,
    handleSetEndTime,
    state: { isEditing },
  } = useClipStateContext();

  useClipHotkeys(handleAddBookmark, handleAddClip, handleSetStartTime, handleSetEndTime);

  const theme = useTheme();
  const [autoTallyStatus, setAutoTally] = React.useState('on');
  const gameTypeMetadataQuery = useGameTypeMetadataQuery();
  const autoIsoQuery = useAutoIsoEntitiesQuery();
  const gameTypeMetadataWidth = width - Number(theme.spacing(6).slice(0, -2));
  const gameTypeMetadataHeight = gameTypeMetadataQuery.data
    ? gameTypeMetadataWidth / gameTypeMetadataQuery.data.surfaceAspectRatio
    : 0;

  const handleDrawerClose = () => {
    setOpen(false);
  };

  const handleAutoTallyChange = () => {
    if (autoTallyStatus === 'on') setAutoTally('off');
    else setAutoTally('on');
  };

  const action = (snackbarId) => (
    <>
      <Button
        color="error"
        onClick={() => {
          closeSnackbar(snackbarId);
        }}
      >
        Dismiss
      </Button>
    </>
  );

  React.useEffect(() => {
    if (game) {
      setEnableTracking(game.enableTracking);
    }
  }, [game]);

  React.useEffect(() => {
    if (isGameDataFetchedSuccessfully && !enableTracking) {
      enqueueSnackbar(
        'Automatic Tracking is unavailable. Manual tracking is only available for single camera view.',
        {
          variant: 'info',
          preventDuplicate: true,
          action,
        },
      );
    }
  }, [enableTracking]);

  const titleStyle: React.CSSProperties = {
    textTransform: 'capitalize',
  };

  return (
    <Drawer
      sx={{
        width,
        flexShrink: 0,
        '& .MuiDrawer-paper': {
          width,
        },
        overflow: 'hidden',
      }}
      variant="persistent"
      anchor="right"
      open={open}
    >
      {/* Provide spacing for header */}
      <DrawerHeader />

      <DrawerHeader
        sx={(sxTheme) => ({
          position: 'fixed',
          backgroundColor: sxTheme.palette.background.paper,
        })}
      >
        <IconButton onClick={handleDrawerClose}>
          <ChevronRightIcon />
        </IconButton>
        {!isEditing && (
          <Typography variant="body1" sx={{ fontWeight: 'bold' }}>
            Viewing Tools
          </Typography>
        )}
        {isEditing && (
          <Typography variant="body1" sx={{ fontWeight: 'bold' }}>
            Clipping Mode
          </Typography>
        )}
      </DrawerHeader>

      {!isEditing && enableTracking && !!autoIsoQuery.data && (
        <ViewingToolsMenuExpandablePanel
          title={
            <Stack direction="row" justifyContent="center">
              <Typography variant="subtitle2">Track</Typography>

              <Tooltip
                title="Manual tracking is only available for single camera view."
                arrow
                componentsProps={{
                  tooltip: {
                    sx: { textAlign: 'center' },
                  },
                }}
              >
                <InfoTwoToneIcon fontSize="small" sx={{ ml: 1 }} />
              </Tooltip>
            </Stack>
          }
        >
          <AutoIsoSelect />
        </ViewingToolsMenuExpandablePanel>
      )}

      {enableTracking && !!gameTypeMetadataQuery.data && !!autoIsoQuery.data && (
        <ViewingToolsMenuExpandablePanel
          title={`Virtual ${gameTypeMetadataQuery.data.surfaceName}`}
        >
          <Box display="flex" justifyContent="center" m={1}>
            <GamePositionsRepresentation
              elementSize={{ width: gameTypeMetadataWidth, height: gameTypeMetadataHeight }}
            />
          </Box>
        </ViewingToolsMenuExpandablePanel>
      )}

      {(!!gameTypeMetadataQuery.data && !autoIsoQuery.data) || (!enableTracking && <PtzControls />)}

      <Stack
        direction="row"
        sx={{ mt: 2, ml: PANEL_PADDING, mr: PANEL_PADDING - 1, mb: 2 }}
        alignItems="center"
        justifyContent="space-between"
      >
        {!isEditing && (
          <Typography variant="subtitle2" style={titleStyle}>
            Bookmarks & Clips
          </Typography>
        )}

        {isEditing && (
          <Typography variant="subtitle2" style={titleStyle}>
            Clip Details
          </Typography>
        )}
      </Stack>

      <ClipsAndBookmarksPanel />
    </Drawer>
  );
};

export default ViewingToolsMenu;
