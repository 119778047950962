import { DurationWithSubseconds } from '../types';

const displayDurationWithSubseconds = (
  duration: DurationWithSubseconds,
  includeSubseconds?: boolean,
) => {
  const display = [
    duration.years > 0 ? duration.hours : null,
    duration.weeks > 0 ? duration.hours : null,
    duration.days > 0 ? duration.hours : null,
    duration.hours > 0 ? duration.hours : null,
    duration.minutes,
    duration.seconds,
  ]
    .filter((n) => n !== null)
    .map((n) => `${n}`.padStart(2, '0'))
    .join(':');

  return includeSubseconds
    ? `${display}.${duration.subSecondPercent.toFixed(3).split('.')[1]}`
    : display;
};

export default displayDurationWithSubseconds;
