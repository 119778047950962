import React from 'react';

import {
  QueryCache,
  QueryClient,
  QueryClientProvider as BaseQueryClientProvider,
} from '@tanstack/react-query';

type Props = {
  children: React.ReactNode;
};

const QueryClientProvider = ({ children }: Props) => {
  const queryClient = new QueryClient({
    defaultOptions: {
      queries: {
        staleTime: 0,
        retry: false,
      },
    },

    queryCache: new QueryCache({
      onError: (_error) => {
        console.error(_error);
      },
    }),
  });

  return <BaseQueryClientProvider client={queryClient}>{children}</BaseQueryClientProvider>;
};

export default QueryClientProvider;
