import React from 'react';

import Typography from '@mui/material/Typography';

import AuthenticationPage from './AuthenticationPage';
import ForgotPasswordForm from './ForgotPasswordForm';

const ForgotPasswordPage = () => (
  <AuthenticationPage>
    <Typography variant="h6">Forgot Password</Typography>

    <Typography variant="subtitle2" sx={{ my: 3 }}>
      Enter your email address, click Submit, and if you have an account, we will send you a code to
      reset your password.
    </Typography>

    <ForgotPasswordForm />
  </AuthenticationPage>
);

export default ForgotPasswordPage;
