import { useRef } from 'react';

import { Dim2, SelectMosaicMessage } from '@/types/control';

import useWebSocket from './useWebSocket';
import { WebSocketRefHook, withStateFromRef } from '../withStateFromRef';

const SELECT_MOSAIC_TYPE = 'SelectMosaic';

export type SelectMosaicRefType = {
  dims: Dim2;
  idxs: number[];
};

export const useSelectMosaicRef: WebSocketRefHook<SelectMosaicRefType> = (options = {}) => {
  const ref = useRef<SelectMosaicRefType>({ dims: { width: 0, height: 0 }, idxs: [] });

  const handleChange = (dims: Dim2, idxs: number[]) => {
    ref.current = { dims, idxs };

    if (options.onChange) {
      options.onChange({ dims, idxs });
    }
  };

  const socket = useWebSocket<SelectMosaicMessage>('/control/0.1.0', {
    onMessage: (message) => {
      if (message.type !== SELECT_MOSAIC_TYPE) return;
      handleChange(message.dims, message.idxs);
    },
  });

  const setValue = (mosaicMessage: SelectMosaicRefType) => {
    const { dims, idxs } = mosaicMessage;
    socket.send({
      type: SELECT_MOSAIC_TYPE,
      dims,
      idxs,
    });
    handleChange(dims, idxs);
  };

  return [ref, setValue];
};

export const usePlaybackSpeedState = withStateFromRef(useSelectMosaicRef);
