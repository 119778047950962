import { useRef } from 'react';

import { PlaybackSpeedMessage } from '@/types/control';

import useWebSocket from './useWebSocket';
import { WebSocketRefHook, withStateFromRef } from '../withStateFromRef';

const PLAYBACKSPEED_TYPE = 'PlaybackSpeed';

export const usePlaybackSpeedRef: WebSocketRefHook<number> = (options = {}) => {
  const ref = useRef(0);

  const handleChange = (value: number) => {
    ref.current = value;

    if (options.onChange) {
      options.onChange(value);
    }
  };

  const socket = useWebSocket<PlaybackSpeedMessage>('/control/0.1.0', {
    onMessage: (message) => {
      if (message.type !== PLAYBACKSPEED_TYPE) return;
      handleChange(message.rate);
    },
  });

  const setValue = (value: number) => {
    socket.send({
      type: PLAYBACKSPEED_TYPE,
      rate: value,
    });
    handleChange(value);
  };

  return [ref, setValue];
};

export const usePlaybackSpeedState = withStateFromRef(usePlaybackSpeedRef);
