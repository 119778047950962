import { useCallback, useContext, useEffect, useRef, useState } from 'react';
import ReconnectingEventSource from 'reconnecting-eventsource';
import { useQueryClient } from '@tanstack/react-query';
import { CognitoContext } from '@/features/authentication/components/CognitoProvider';
import { getQueryKey as getCurrentGameClipsQueryKey } from '@/features/clips/hooks/useCurrentGameClipsQuery';
import { GetITMSSEUrl } from '@/util';
import { ClipStatus } from '@/features/clips/types';
import useCurrentGameId from '@/shared/hooks/useCurrentGameId';

export type TimemachineState = {
  locked: boolean;
  clip?: string;
  clipId?: number;
  status?: string;
};

export const TIMEMACHINE_STATE: TimemachineState = {
  locked: false,
};

const useTimemachineState = (): { timemachineState: TimemachineState } => {
  const { cognitoContext } = useContext(CognitoContext);
  const currentGameId = useCurrentGameId();
  const queryClient = useQueryClient();

  // states
  const [timemachineState, setTimemachineState] = useState<TimemachineState>(TIMEMACHINE_STATE);

  // refs
  const unlockTimeoutRef = useRef<number | null>(null);
  const sseRef = useRef<ReconnectingEventSource | null>(null);

  const [clipExportStatus, setClipExportStatus] = useState<ClipStatus | null>(
    ClipStatus.UNPROCESSED,
  );

  useEffect(() => {
    setTimeout(() => {
      queryClient.invalidateQueries(getCurrentGameClipsQueryKey(currentGameId));
    }, 250);
  }, [clipExportStatus]);

  // callbacks
  const unlockUI = useCallback(() => {
    clearTimeout(unlockTimeoutRef.current);
    setTimemachineState(() => ({
      locked: false,
    }));
  }, []);

  const lockUI = useCallback((stale: number) => {
    setTimemachineState((currentState) => ({
      ...currentState,
      locked: true,
    }));

    if (stale) {
      // @ts-ignore
      unlockTimeoutRef.current = setTimeout(() => {
        setTimemachineState((currentState) => ({
          ...currentState,
          locked: false,
        }));
      }, stale * 1000);
    }
  }, []);

  const handleRealtimeData = useCallback((data: any) => {
    const { event, payload } = data;

    if (event === 'status-update') {
      const {
        clip: { note, id },
        status,
      } = payload;

      setClipExportStatus(status);

      setTimemachineState((currentState) => ({
        ...currentState,
        clip: note,
        clipId: id,
        status,
      }));

      //      if (status === ClipStatus.READY || status === ClipStatus.ERROR || status === ClipStatus.CANCELED) {
      //        unlockUI();
      //      }
    }

    if (event === 'unlock') {
      setTimeout(() => {
        unlockUI();
      }, 1000 * 2);
      return;
    }

    if (event === 'lock') {
      const { stale } = payload;
      lockUI(stale);
    }

    if (event === 'game-switch-update') {
      const { message } = payload;

      /**
       * TODO: handle game switch update
       * message is one of :
       * LOADING_GAME_INFO
       * CREATING_GAME_CONFIG
       * RUNNING_CONFIGURATION_CHANGES
       * CHECKING_REGISTRATIONS
       * ESTABLISHING_WEBRTC_HANDSHAKE
       * DONE
       * ERROR
       */

      console.debug('game-switch-update', message);
    }
  }, []);

  const cleanup = useCallback(() => {
    if (unlockTimeoutRef.current) {
      clearTimeout(unlockTimeoutRef.current);
    }

    if (sseRef.current) {
      sseRef.current.close();
      sseRef.current = null;
    }
  }, []);

  useEffect(() => {
    if (cognitoContext.authenticated) {
      if (!sseRef.current) {
        const SSEHref = GetITMSSEUrl();
        sseRef.current = new ReconnectingEventSource(SSEHref);
        sseRef.current.onmessage = (e) => handleRealtimeData(JSON.parse(e.data));
        sseRef.current.onerror = () => {
          // error log here
          // console.error(e);
          // noop
        };
      }
    } else {
      cleanup();
    }

    return cleanup;
  }, [cognitoContext.authenticated, handleRealtimeData, cleanup]);

  return { timemachineState };
};

export type TimemachineStateType = ReturnType<typeof useTimemachineState>;
export default useTimemachineState;
