import { SocketHookReturn } from '@/types/camspec';
import { PTZMessage } from '@/types/control';
import useWebSocket from './useWebSocket';

const PTZ_MESSAGE_TYPE = 'PTZ';

export const usePTZSocket = (): SocketHookReturn => {
  // WebSocket initialization
  const socket = useWebSocket<PTZMessage>('/control/0.1.0');

  const init = () => {
    // wrong as we need to read the default values from the camera before sending them.
    const message = { tilt: 0, pan: 0, zoom: 0 };
    socket.send({ ...message, type: PTZ_MESSAGE_TYPE });
  };

  const setValue = (newPTZMessage: PTZMessage) => {
    const { pan, tilt, zoom } = newPTZMessage;
    if (!Number.isFinite(pan) || !Number.isFinite(tilt) || !Number.isFinite(zoom)) {
      console.error('Invalid PTZ message', newPTZMessage);
      return;
    }

    console.log('🌭🌭🌭 Sending PTZ message', newPTZMessage);

    socket.send({ ...newPTZMessage, type: PTZ_MESSAGE_TYPE });
  };

  return {
    init,
    setValue,
    reset: init,
  };
};
