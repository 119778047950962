import React, { ReactNode, useCallback, useState } from 'react';

import ConfirmationDialog, { Props as ConfirmationDialogProps } from './ConfirmationDialog';

type Props = {
  children: ReactNode;
  showing?: boolean;
};

export const ConfirmationDialogContext =
  React.createContext<(state: Omit<ConfirmationDialogProps, 'showing'>) => void>(undefined);

const ConfirmationDialogProvider = ({ children }: Props) => {
  const [state, setState] = useState<ConfirmationDialogProps | null>(null);
  const handleReset = () => {
    setState(null);
  };
  const handleSetState = useCallback(
    (_state: Omit<ConfirmationDialogProps, 'showing'>) => setState((s) => ({ ...s, ..._state })),
    [],
  );

  const onConfirmHandler = async () => {
    await state.onConfirm();
    handleReset();
  };

  return (
    <ConfirmationDialogContext.Provider value={handleSetState}>
      {!!state && (
        <ConfirmationDialog
          {...state}
          onConfirm={onConfirmHandler}
          onCancel={() => {
            if (state.onCancel) state.onCancel();
            handleReset();
          }}
        />
      )}
      {children}
    </ConfirmationDialogContext.Provider>
  );
};

export default ConfirmationDialogProvider;
