import { get } from '@truefit/http-utils';
import { useSnackbar } from 'notistack';
import React, { ReactNode, useCallback, useEffect } from 'react';
import useTimemachineState, { TimemachineState } from '../hooks/useTimemachineState';
import ServerStatus from './ServerStatus';
import { ClipStatus } from '@/features/clips/types';
import DownloadClipDialog from '@/shared/components/DownloadClipDialog';

type Props = {
  children: ReactNode;
};

export const TimemachineStateContext = React.createContext<TimemachineState>(undefined);

function formatStatus(state) {
  if (state.status) return `Exporting ${state.clip} : ${state.status}`;
  return '';
}

const TimeMachineStatusProvider = ({ children }: Props) => {
  const { timemachineState } = useTimemachineState();

  const { enqueueSnackbar } = useSnackbar();
  const [downloadClipDialogOpen, setDownloadClipDialogOpen] = React.useState({
    open: false,
    clipId: undefined,
  });

  useEffect(() => {
    if (timemachineState && timemachineState.status === ClipStatus.READY) {
      enqueueSnackbar('Export complete', { variant: 'success', preventDuplicate: true });
      setDownloadClipDialogOpen({ open: true, clipId: timemachineState.clipId });
    }
    if (timemachineState && timemachineState.status === ClipStatus.ERROR) {
      enqueueSnackbar('Export failed', { variant: 'error', preventDuplicate: true });
    }
    if (timemachineState && timemachineState.status === ClipStatus.CANCELED) {
      enqueueSnackbar('Export Canceled', { variant: 'info', preventDuplicate: true });
    }
  }, [timemachineState]);

  const handleCancelClick = useCallback(() => {
    (async () => {
      try {
        await get('unlock');
      } catch (e) {
        enqueueSnackbar('Network error', { variant: 'error', preventDuplicate: true });
      }
    })();
  }, [enqueueSnackbar]);

  return (
    <TimemachineStateContext.Provider value={timemachineState}>
      {timemachineState && timemachineState.locked && (
        <ServerStatus
          title="Export operation in progress"
          message="Please hold tight while we process your export task. Your clips will be trimmed to a length of 60 seconds."
          secondaryMessage={formatStatus(timemachineState)}
          cancellable
          open
          onYes={handleCancelClick}
          useDialog
          requiresConfirmation
        />
      )}
      <DownloadClipDialog
        open={downloadClipDialogOpen.open}
        clipId={downloadClipDialogOpen.clipId}
        onClose={() => setDownloadClipDialogOpen({ open: false, clipId: undefined })}
        onExport={() => {}}
      />
      {children}
    </TimemachineStateContext.Provider>
  );
};

export default TimeMachineStatusProvider;
