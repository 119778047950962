import React, { ElementType, HTMLAttributes, ReactNode, useRef } from 'react';
import { Controller, useFormContext } from 'react-hook-form';

import FormControl from '@mui/material/FormControl';
import FormHelperText from '@mui/material/FormHelperText';
import InputLabel from '@mui/material/InputLabel';
import { MenuProps as MenuPropsInterface } from '@mui/material/Menu';
import Select, { SelectChangeEvent } from '@mui/material/Select';

type Props = {
  name: string;
  children: ReactNode;
  autoFocus?: boolean;
  autoWidth?: boolean;
  defaultValue?: unknown;
  disabled?: boolean;
  displayEmpty?: boolean;
  fullWidth?: boolean;
  helperText?: ReactNode;
  id?: string;
  IconComponent?: ElementType;
  label?: ReactNode;
  margin?: 'none' | 'dense' | 'normal';
  MenuProps?: Partial<MenuPropsInterface>;
  multiple?: boolean;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  renderValue?: (value: any) => ReactNode;
  required?: boolean;
  SelectDisplayProps?: HTMLAttributes<HTMLDivElement>;
  size?: 'small' | 'medium';
  variant?: 'standard' | 'outlined' | 'filled';
  onChange?: (event: SelectChangeEvent) => void;
};

const HookFormSelect = ({
  name,
  children,
  onChange: onChangeExternal,
  autoFocus = false,
  autoWidth = false,
  defaultValue = '',
  disabled = false,
  displayEmpty = false,
  fullWidth = true,
  helperText = '',
  IconComponent = undefined,
  id = undefined,
  label = '',
  margin = 'normal',
  MenuProps = undefined,
  multiple = false,
  renderValue = undefined,
  required = false,
  SelectDisplayProps = undefined,
  size = 'medium',
  variant = 'outlined',
  onChange = undefined,
}: Props) => {
  const {
    formState: { errors },
  } = useFormContext();
  const error = errors[name];
  const displayError = Boolean(error?.message);
  const inputLabel = useRef<HTMLLabelElement>(null);

  const controlId = id ?? name;
  const labelId = `${controlId}-label`;

  return (
    <FormControl
      disabled={disabled}
      error={displayError}
      fullWidth={fullWidth}
      margin={margin}
      required={required}
      size={size}
      variant={variant}
    >
      <InputLabel ref={inputLabel} id={labelId}>
        {label}
      </InputLabel>
      <Controller
        name={name}
        defaultValue={defaultValue}
        render={({ field: { onChange, onBlur, value } }) => (
          <Select
            autoFocus={autoFocus}
            autoWidth={autoWidth}
            displayEmpty={displayEmpty}
            IconComponent={IconComponent}
            id={controlId}
            labelId={labelId}
            label={label}
            MenuProps={MenuProps}
            multiple={multiple}
            name={name}
            onBlur={onBlur}
            onChange={(e) => {
              onChange(e);
              if (onChangeExternal) onChangeExternal(e);
            }}
            renderValue={renderValue}
            SelectDisplayProps={SelectDisplayProps}
            value={value}
          >
            {children}
          </Select>
        )}
      />
      <FormHelperText>{displayError ? String(error.message) : helperText}</FormHelperText>
    </FormControl>
  );
};

export default HookFormSelect;
