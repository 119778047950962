import { formatInTimeZone, utcToZonedTime, format } from 'date-fns-tz';
import { isTomorrow, isYesterday, isToday } from 'date-fns';

export const formatDate = (date, tz) => {
  const browserTz = Intl.DateTimeFormat().resolvedOptions().timeZone;
  const strFormat = browserTz === tz ? 'MMM dd, yyyy • h:mmaaa' : 'MMM dd, yyyy • h:mmaaa zzz';
  return formatInTimeZone(date, tz, strFormat);
};

export const formatStart = (targetDate, tz) => {
  const now = new Date();
  const { timeZone } = Intl.DateTimeFormat().resolvedOptions();

  const gameIsToday = isToday(targetDate);
  if (gameIsToday) {
    return `Today • ${format(targetDate, 'h:mm a', { timeZone })}`;
  }

  const gameIsTomorrow = isTomorrow(targetDate);

  if (gameIsTomorrow) {
    return `Tomorrow • ${format(targetDate, 'h:mm a', { timeZone })}`;
  }

  const gameIsYesterday = isYesterday(targetDate);

  if (gameIsYesterday) {
    return `Yesterday • ${format(targetDate, 'h:mm a', { timeZone })}`;
  }

  return formatDate(targetDate, tz); // 'MMM dd, yyyy • h:mmaaa zzz'
};
