import React, { ReactNode } from 'react';

import useServerState, { ServerStateAndFunctions } from '../hooks/useServerState';

type Props = {
  children: ReactNode;
};

export const ServerStateContext = React.createContext<ServerStateAndFunctions>(undefined);

const ServerStateProvider = ({ children }: Props) => {
  const serverState = useServerState();

  return <ServerStateContext.Provider value={serverState}>{children}</ServerStateContext.Provider>;
};

export default ServerStateProvider;
