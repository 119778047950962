import { useQuery } from '@tanstack/react-query';
/* eslint-disable import/prefer-default-export */
import { get } from '@truefit/http-utils';
import useCurrentGameId from '@/shared/hooks/useCurrentGameId';

import { AutoIsoId, AutoIsoModel, AutoIsoObjectType, TrackingRegistrationModel } from '../types';

export const getQueryKey = (gameId: number) => ['Games', gameId, 'AutoIsoLookup'];
export const DEFAULT_DATA: { list: AutoIsoModel[]; lookup: Record<AutoIsoId, AutoIsoModel> } & Pick<
  TrackingRegistrationModel,
  'homeTeam' | 'awayTeam'
> = {
  list: [{ type: AutoIsoObjectType.BallOrPuck, displayName: '', id: 'ballPuck' }],
  lookup: {
    ballPuck: {
      displayName: '',
      id: 'ballPuck',
      type: AutoIsoObjectType.BallOrPuck,
    },
  },
  homeTeam: { id: 'home', displayName: '', abbreviation: '' },
  awayTeam: { id: 'away', displayName: '', abbreviation: '' },
};

const useAutoIsoEntitiesQuery = () => {
  const gameId = useCurrentGameId();

  return useQuery({
    queryKey: getQueryKey(gameId),
    queryFn: async () => {
      const response = await get<TrackingRegistrationModel>(`games/${gameId}/registration`);
      if (!response.data) return DEFAULT_DATA;
      return {
        awayTeam: response.data.awayTeam,
        homeTeam: response.data.homeTeam,
        list: response.data.autoIsoEntities,
        lookup: response.data.autoIsoEntities.reduce<Record<AutoIsoId, AutoIsoModel>>(
          (lookup, entity) => ({
            ...lookup,
            [entity.id]: entity,
          }),
          {},
        ),
      };
    },
    gcTime: Infinity,
    placeholderData: DEFAULT_DATA,
  });
};

export default useAutoIsoEntitiesQuery;
