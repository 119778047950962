import * as React from 'react';

import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { Box, Collapse, Divider, IconButton, Stack, Typography } from '@mui/material';
import { Theme } from '@mui/material/styles';
import { makeStyles } from '@mui/styles';

import { PANEL_PADDING } from '../constants';

type Props = {
  title: string | React.ReactNode;
  children: React.ReactNode;
  CollapseProps?: CollapseProps;
  initialExpandedState?: boolean;
};

const useStyles = makeStyles((theme: Theme) => ({
  dropdown: {
    transition: theme.transitions.create(['transform'], {
      duration: theme.transitions.duration.standard,
    }),
    color: theme.palette.grey[400],
  },
  dropdownOpen: {
    transform: 'rotate(-180deg)',
  },
  dropdownClosed: {
    transform: 'rotate(0)',
  },
}));

const ViewingToolsMenuExpandablePanel = React.forwardRef(
  (
    { title, children, CollapseProps = {}, initialExpandedState = true }: Props,
    ref: React.RefObject<HTMLDivElement>,
  ) => {
    const classes = useStyles();
    const [expanded, setExpanded] = React.useState(initialExpandedState);
    const titleStyle: React.CSSProperties = {
      textTransform: 'capitalize',
    };

    return (
      <>
        <Stack
          direction="row"
          sx={{ mt: 2, ml: PANEL_PADDING, mr: PANEL_PADDING - 1 }}
          alignItems="center"
          justifyContent="space-between"
        >
          <Typography variant="subtitle2" style={titleStyle}>
            {title}
          </Typography>

          <IconButton size="small" onClick={() => setExpanded(!expanded)}>
            <ExpandMoreIcon
              className={[
                classes.dropdown,
                expanded ? classes.dropdownOpen : classes.dropdownClosed,
              ].join(' ')}
            />
          </IconButton>
        </Stack>
        <Divider />
        {expanded && (
          <Collapse
            unmountOnExit
            {...CollapseProps}
            sx={{ flexShrink: 0, ...(CollapseProps.sx || {}) }}
            in={expanded}
          >
            <Box mt={2}>{children}</Box>
          </Collapse>
        )}
      </>
    );
  },
);

export default ViewingToolsMenuExpandablePanel;
