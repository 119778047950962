import React, { ReactNode, useEffect, useState } from 'react';
import { Controller, FormProvider, useForm } from 'react-hook-form';

import Autocomplete from '@mui/material/Autocomplete';
import Box from '@mui/material/Box';
import Chip from '@mui/material/Chip';
import MenuItem from '@mui/material/MenuItem';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';

import { HookFormDatePicker, HookFormSelect } from '../../forms/components';
import useGameTeamsQuery from '../hooks/useGameTeamsQuery';
import { useTeamLogoPath } from '@/shared/hooks/useTeamLogoPath';
import { FormControl, InputLabel, Select, SelectChangeEvent, Stack } from '@mui/material';
import useListSeasonsQuery from '../hooks/useListSeasonsQuery';
import { UserStateContext } from '@/shared/components/UserStateProvider';
import { TeamsCarousel } from '@/features/teams/components/TeamsCarousel';

type Props = {
  onChange: (values: FormValues) => unknown;
};

export type FormValues = {
  date: Date;
  team: string;
  sort: string;
  streamType: string;
  seasonId: string;
  leagueId: string;
  orgId: string;
};

export const DEFAULT_VALUES: FormValues = {
  date: null,
  team: null,
  sort: 'newest',
  streamType: 'all',
  seasonId: ' ',
  leagueId: null,
  orgId: null,
};

type FieldsetProps = {
  legend: string;
  children: React.ReactNode;
};

const Fieldset = ({ legend, children }: FieldsetProps) => (
  <Box
    component="fieldset"
    sx={{
      border: 'none',
      display: 'flex',
      gap: 1,
      alignItems: 'center',
      margin: 0,
      padding: 0,
    }}
  >
    <Typography variant="overline">{legend}</Typography>
    {children}
  </Box>
);

const GamesForm = ({ onChange }: Props) => {
  const methods = useForm<FormValues>({ defaultValues: DEFAULT_VALUES });
  const { watch, handleSubmit, control, register } = methods;

  const [currentSeason, setDefaultSeason] = useState('');

  const { currentLeague, currentOrg } = React.useContext(UserStateContext);

  const teamsQuery = useGameTeamsQuery(currentLeague.id);
  const listSeasonsQuery = useListSeasonsQuery(currentLeague.id);

  useEffect(() => {
    if (listSeasonsQuery.status === 'success') {
      const defaultSeason = listSeasonsQuery.data.find((season) => season.is_active === true);
      if (defaultSeason) {
        setDefaultSeason(defaultSeason.id.toString());
        methods.setValue('seasonId', defaultSeason.id.toString());
      } else {
        setDefaultSeason(' ');
        methods.setValue('seasonId', ' ');
      }
    }
  }, [listSeasonsQuery.data]);

  useEffect(() => {
    if (currentLeague?.id) {
      methods.setValue('leagueId', currentLeague.id.toString());
      listSeasonsQuery.refetch();
      teamsQuery.refetch();
    }

    if (currentOrg?.id) {
      methods.setValue('orgId', currentOrg.id.toString());
    }
  }, [currentLeague, currentOrg]);

  // submits the form onChange
  // from https://stackoverflow.com/a/70119332
  useEffect(() => {
    const subscription = watch(handleSubmit(onChange));
    return () => subscription.unsubscribe();
  }, [handleSubmit, watch, onChange]);

  return (
    <FormProvider {...methods}>
      <input value={currentLeague.id} type="hidden" {...register('leagueId')} />
      <input value={currentOrg.id} type="hidden" {...register('orgId')} />

      <Stack direction="column" justifyContent="space-between" spacing={2} p={3}>
        <Box sx={{ width: 300 }}>
          <Controller
            name="seasonId"
            control={control}
            render={({ field: { onChange: lOnChange, value } }) => {
              const onChange = (newValue: number) => {
                return lOnChange(newValue);
              };

              function handleSeasonChange(
                event: SelectChangeEvent<string>,
                child: ReactNode,
              ): void {
                setDefaultSeason(event.target.value);
                onChange(event.target.value);
              }

              return (
                <FormControl fullWidth>
                  <InputLabel>Season</InputLabel>

                  <Select
                    labelId="season-select-label"
                    id="season-select"
                    value={currentSeason}
                    label="Season"
                    onChange={handleSeasonChange}
                  >
                    <MenuItem value=" ">All Seasons</MenuItem>
                    {listSeasonsQuery.data &&
                      listSeasonsQuery.data.map((season) => (
                        <MenuItem key={season.id} value={season.id}>
                          {season.name} - {season.seasonType}
                        </MenuItem>
                      ))}
                  </Select>
                </FormControl>
              );
            }}
          ></Controller>
        </Box>

        <Box
          component="form"
          sx={{
            display: 'flex',
            alignItems: 'center',
            marginTop: 4,
            justifyContent: 'space-between',
            borderWidth: 1,
            borderColor: 'red',
          }}
        >
          <Controller
            name="streamType"
            control={control}
            render={({ field: { onChange: lOnChange, value } }) => {
              const handleClick = (newValue: string) => () => lOnChange(newValue);
              const variant = (testValue: string) => (value === testValue ? 'filled' : 'outlined');
              const color = (testValue: string) => (value === testValue ? 'primary' : 'default');

              return (
                <Box sx={{ display: 'flex', gap: 1 }}>
                  <Chip
                    label="All"
                    variant={variant('all')}
                    onClick={handleClick('all')}
                    color={color('all')}
                  />

                  <Chip
                    label="Live"
                    variant={variant('live')}
                    onClick={handleClick('live')}
                    color={color('live')}
                  />

                  <Chip
                    label="Recorded"
                    variant={variant('recorded')}
                    onClick={handleClick('recorded')}
                    color={color('recorded')}
                  />
                  <Chip
                    label="Upcoming"
                    variant={variant('upcoming')}
                    onClick={handleClick('scheduled')}
                    color={color('upcoming')}
                  />
                </Box>
              );
            }}
          />

          <Box
            sx={{
              display: 'flex',
              gap: 2,
              flexGrow: 1,
              justifyContent: 'flex-end',
            }}
          >
            <Fieldset legend="Filter">
              <HookFormDatePicker
                name="date"
                TextFieldProps={{ label: 'Date', sx: { width: '200px' } }}
                DatePickerProps={{ label: 'Date', inputFormat: 'MM/dd/yyyy' }}
              />

              <Controller
                name="team"
                control={control}
                render={({ field }) => (
                  <Autocomplete
                    onChange={(_e, v) => {
                      field.onChange(v?.abbreviation || '');
                    }}
                    groupBy={(option) => option.abbreviation[0].toUpperCase()}
                    isOptionEqualToValue={(option, value) => option.abbreviation === value}
                    value={field.value}
                    id="team-selector"
                    getOptionLabel={(option: any) =>
                      option?.abbreviation ? option.abbreviation : option
                    }
                    options={teamsQuery.data}
                    renderOption={(props, option: any) => (
                      <Box component="li" sx={{ '& > img': { mr: 2, flexShrink: 0 } }} {...props}>
                        <img
                          // loading="lazy"
                          width="35"
                          // eslint-disable-next-line react-hooks/rules-of-hooks
                          src={useTeamLogoPath(option)}
                          alt={option.abbreviation}
                        />
                        {option.abbreviation}
                      </Box>
                    )}
                    renderInput={(params) => <TextField {...params} label="Team" size="large" />}
                    sx={{ width: '200px' }}
                  />
                )}
              />
            </Fieldset>

            <Fieldset legend="Sort">
              <HookFormSelect name="sort" label="Time" margin="dense">
                <MenuItem value="newest">Newest</MenuItem>
                <MenuItem value="oldest">Oldest</MenuItem>
              </HookFormSelect>
            </Fieldset>
          </Box>
        </Box>
      </Stack>
    </FormProvider>
  );
};

export default GamesForm;
