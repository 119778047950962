import * as React from 'react';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import AppPage from '@/shared/components/PageComponents/AppPage';
import Container from '@mui/material/Container/Container';
import Divider from '@mui/material/Divider';
import { useDocumentTitle } from '@/shared/hooks/useDocumentTitle';
import AccountInfo from './AccountInfo';
import AccountSecurity from './AccountSecurity';

interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

function TabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`vertical-tabpanel-${index}`}
      aria-labelledby={`vertical-tab-${index}`}
      style={{ height: '100%', overflowY: 'auto' }}
      {...other}
    >
      {value === index && <Box sx={{ p: 3, height: '100%' }}>{children}</Box>}
    </div>
  );
}

function a11yProps(index: number) {
  return {
    id: `vertical-tab-${index}`,
    'aria-controls': `vertical-tabpanel-${index}`,
  };
}

const AccountSettingsPage = () => {
  const [value, setValue] = React.useState(0);

  const title = 'Account Settings';

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };

  useDocumentTitle(title);

  return (
    <AppPage title={title}>
      <Container maxWidth="xl" sx={{ marginTop: 4, marginBottom: 4 }}>
        <Typography variant="h6">{title}</Typography>
        <Typography variant="subtitle1" sx={{ marginTop: 2 }}>
          Manage information about you, including your name in CX Videohub and your authentication
          information
        </Typography>
        <Divider sx={{ marginTop: 3 }} />

        <Box
          sx={{
            width: '100%',
            flexGrow: 1,
            bgcolor: 'background.paper',
            display: 'flex',
            padding: 2,
          }}
        >
          <Tabs
            value={value}
            onChange={handleChange}
            aria-label="Account information tabs"
            centered
            orientation="vertical"
          >
            <Tab label="Account Infos" {...a11yProps(0)} />
            <Tab label="Security" {...a11yProps(1)} />
          </Tabs>
          <TabPanel value={value} index={0}>
            <AccountInfo></AccountInfo>
          </TabPanel>
          <TabPanel value={value} index={1}>
            <AccountSecurity></AccountSecurity>
          </TabPanel>
        </Box>
      </Container>
    </AppPage>
  );
};

export default AccountSettingsPage;
