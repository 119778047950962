import React, { ReactNode, useState } from 'react';

import { CognitoState } from '../types';

type Props = {
  children: ReactNode;
};

type CognitoStateContext = {
  cognitoContext: CognitoState;
  setCognitoContext: (newAuth: CognitoState) => unknown;
};

const DEFAULT_STATE: CognitoState = {
  authenticated: false,
  newPasswordRequired: false,
  sessionHandler: null,
};

export const CognitoContext = React.createContext<CognitoStateContext>({
  cognitoContext: DEFAULT_STATE,
  setCognitoContext: () => null,
});

const CognitoProvider = ({ children }: Props) => {
  const [cognitoContext, setCognitoContext] = useState<CognitoState>(DEFAULT_STATE);

  return (
    <CognitoContext.Provider value={{ cognitoContext, setCognitoContext }}>
      {children}
    </CognitoContext.Provider>
  );
};

export default CognitoProvider;
