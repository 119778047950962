import React, { useEffect, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import * as Yup from 'yup';

import { yupResolver } from '@hookform/resolvers/yup';
import { Button, Stack, TextField } from '@mui/material';
import LoadingButton from '@mui/lab/LoadingButton';
import SaveIcon from '@mui/icons-material/Save';
import useClipStateContext from '@/shared/hooks/useClipStateContext';
import { useTimestampState } from '@/shared/hooks/websocket/useTimestamp';
import { nameofFactory } from '@/shared/services';
import displayWallClock from '@/shared/services/displayWallClock';

import { HookFormTextField } from '../../forms/components';
import { ClipModel, ClipType } from '../types';

type Props = {
  clip: ClipModel;
  onSubmit: (newClipModel: ClipModel) => Promise<void>;
  onCancel: (newClipModel: ClipModel) => void;
};

export type BookmarkModelFormValues = {
  note: string;
  description: string;
};

const nameOfForm = nameofFactory<BookmarkModelFormValues>();

const DESCRIPTION_MAX = 512;

const validationSchema = Yup.object().shape({
  [nameOfForm('note')]: Yup.string().required().max(128).min(1).label('Name'),
  [nameOfForm('description')]: Yup.string().optional().max(DESCRIPTION_MAX).label('Description'),
});

const ClipRecordForm = ({ clip, onSubmit, onCancel }: Props) => {
  const {
    state: { editingModel },
    getState,
    handleSetBookmarkTime,
  } = useClipStateContext();
  const [timestamp] = useTimestampState();
  const [disabled, setDisabled] = useState(false);

  useEffect(() => {
    handleSetBookmarkTime();
  }, [handleSetBookmarkTime, timestamp]);

  const form = useForm<ClipModel>({
    defaultValues: {
      note: clip.note,
      description: clip.description,
    },
    mode: 'onSubmit',
    resolver: yupResolver(validationSchema),
  });

  const handleSubmit = form.handleSubmit(async (formValues) => {
    setDisabled(true);

    const updateClipModel: ClipModel = { ...getState().editingModel, ...formValues };
    await onSubmit(updateClipModel);

    setDisabled(false);
  });

  const handleCancel = () => onCancel({ ...getState().editingModel, ...form.getValues() });

  if (!editingModel) return null;

  const descriptionLength = form.watch(nameOfForm('description')).length;

  return (
    <>
      <FormProvider {...form}>
        <form onSubmit={handleSubmit}>
          <Stack direction="row" alignItems="center" justifyContent="flex-end" spacing={1}>
            <HookFormTextField
              name={nameOfForm('note')}
              label="Name"
              disabled={disabled}
              required
              size="small"
              margin="none"
              variant="standard"
            />
            {clip.type === ClipType.Bookmark && (
              <TextField
                label="Timestamp"
                required
                size="small"
                margin="none"
                disabled
                fullWidth
                variant="standard"
                value={displayWallClock(editingModel.bookmarkTimestamp, {
                  includeSubseconds: true,
                })}
              />
            )}
          </Stack>
          {clip.type === ClipType.Clip && (
            <Stack direction="row" alignItems="center" justifyContent="flex-end" spacing={1} mt={2}>
              <TextField
                label="Start Time"
                required
                size="small"
                margin="none"
                fullWidth
                disabled
                variant="standard"
                value={displayWallClock(editingModel.startTimestamp, { includeSubseconds: true })}
              />
              <TextField
                label="End Time"
                required
                size="small"
                margin="none"
                fullWidth
                disabled
                variant="standard"
                value={displayWallClock(editingModel.endTimestamp, { includeSubseconds: true })}
              />
            </Stack>
          )}
          <Stack direction="row" alignItems="center" justifyContent="flex-end" spacing={1} mt={2}>
            <HookFormTextField
              name={nameOfForm('description')}
              label="Description"
              size="small"
              margin="none"
              variant="standard"
              disabled={disabled}
              multiline
              helperText={`${descriptionLength.toLocaleString()}/${DESCRIPTION_MAX.toLocaleString()}`}
              FormHelperTextProps={{
                sx: (t) => ({
                  textAlign: 'right',
                  color:
                    descriptionLength > DESCRIPTION_MAX
                      ? t.palette.error.main
                      : t.palette.text.secondary,
                }),
              }}
            />
          </Stack>

          <Stack direction="row" alignItems="center" justifyContent="flex-end" my={1}>
            <Button
              style={{ marginRight: 10 }}
              size="small"
              variant="text"
              onClick={handleCancel}
              disabled={disabled}
            >
              Cancel
            </Button>

            <LoadingButton
              size="small"
              color="primary"
              onClick={handleSubmit}
              loading={disabled}
              loadingPosition="start"
              startIcon={<SaveIcon />}
              variant="contained"
            >
              <span>Save</span>
            </LoadingButton>
          </Stack>
        </form>
      </FormProvider>
    </>
  );
};

export default ClipRecordForm;
