import { League, Org, OrgWithGroups, User } from '@/types/user';

export function getOrgsWithAccess(data: any): Org[] {
  const organizations: Org[] = [];

  data.usergroups.forEach((userGroup: any) => {
    const { org } = userGroup.group;
    const leagues = org.access
      .map((access: any) => access.league)
      .filter((league: League) => league !== null);

    organizations.push({
      id: org.id,
      name: org.name,
      leagues,
    });
  });

  return organizations;
}
