import { parseISO } from 'date-fns';
import { motion } from 'framer-motion';
import React, { useEffect, useState, useContext } from 'react';
import { Link } from 'react-router-dom';

import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';

import { useQuery } from '@tanstack/react-query';
import { get } from '@truefit/http-utils';
import { GameModelFromServer } from '../types';

import { UserStateContext } from '@/shared/components/UserStateProvider';
import { useTeamLogoPath } from '@/shared/hooks/useTeamLogoPath';
import { formatStart } from '@/shared/services/formatStart';
import { getDuration } from '@/shared/services/getDuration';
import { Tracking } from '@/shared/services/tracking';
import { Stack } from '@mui/material';
import { useFlags } from 'launchdarkly-react-client-sdk';
import _ from 'lodash';

type Props = {
  game: GameModelFromServer;
};

const calculateRefetchInterval = (startDate: Date): number => {
  const now = new Date().getTime();
  const gameStart = new Date(startDate).getTime();
  const minutesUntilGame = (gameStart - now) / (1000 * 60);
  const baseInterval = 1000 * 3; // 3 seconds

  if (minutesUntilGame > 1440) return 1000 * 60 * 60; // 1 hour
  if (minutesUntilGame > 60) return 1000 * 60 * 10; // 10 minutes
  if (minutesUntilGame > 10) return 1000 * 60; // 1 minute
  return baseInterval;
};

const LiveIndicator = ({ color }: { color: string }) => (
  <Box sx={{ paddingLeft: 2, paddingRight: 2, color }} justifyContent="right">
    <div style={{ overflow: 'hidden', width: 30, height: 30 }}>
      <Typography variant="subtitle1" sx={{ lineHeight: 1.25, textTransform: 'capitalize' }}>
        Live
      </Typography>
      <motion.div
        style={{ backgroundColor: '#DB2B39', width: 20, height: 4 }}
        animate={{ x: ['-25px', '35px', '-25px'] }}
        transition={{ duration: 2, repeat: Infinity }}
      />
    </div>
  </Box>
);

function formatDateToUTCYYYYMMDD(date: Date): string {
  const year = date.getUTCFullYear();
  const month = String(date.getUTCMonth() + 1).padStart(2, '0');
  const day = String(date.getUTCDate()).padStart(2, '0');
  return `${year}_${month}_${day}`;
}

const GameCard = ({ game }: Props) => {
  const start = parseISO(game.startTimestamp);
  const end = parseISO(game.endTimestamp);
  const userContext = useContext(UserStateContext);

  const [isLive, setIsLive] = useState(game.status === 'live');
  const [isHovered, setIsHovered] = useState(false);
  const [opacity, setOpacity] = useState(game.status === 'scheduled' ? 0.4 : 1);
  const [gameStatus, setGameStatus] = useState(game.status);

  const [formattedStartDate, setFormattedStartDate] = useState<string | null>(
    formatStart(start, userContext?.user.tz),
  );

  const updateGameStatus = async () => {
    if (Number.isNaN(game.id)) return null;
    const { data } = await get<GameModelFromServer>(`games/${game.id}?polling=true`);
    setGameStatus(data.status);
    setIsLive(data.status === 'live');
    setOpacity(data.status === 'scheduled' ? 0.7 : 1);
    return data.status;
  };

  const queryKey = (gameId: number) => ['GamesStatus', gameId];

  useEffect(() => {
    setFormattedStartDate(formatStart(start, userContext?.user.tz));
  }, [start, userContext?.user.tz]);

  useEffect(() => {
    if (game.status === 'scheduled') {
      const interval = setInterval(
        () => {
          updateGameStatus();
        },
        calculateRefetchInterval(new Date(game.startTimestamp)),
      );

      return () => clearInterval(interval);
    }
  }, [game.id, game.status]);

  useEffect(() => {
    setOpacity(game.status === 'scheduled' ? 0.7 : 1);
  }, [game.status]);

  useQuery({
    queryKey: queryKey(game.id),
    queryFn: async () => updateGameStatus(),
    refetchOnWindowFocus: true,
    enabled: game.status === 'scheduled',
    refetchInterval: calculateRefetchInterval(new Date(game.startTimestamp)),
  });

  const { debugData } = useFlags();

  const gameKey = `${game.season.league.name.toUpperCase()}_${formatDateToUTCYYYYMMDD(
    new Date(game.startTimestamp),
  )}_${game.awayTeam.abbreviation}@${game.homeTeam.abbreviation}`;

  return (
    <Card
      sx={{
        display: 'flex',
        flexDirection: 'column',
        opacity: isHovered ? 1 : opacity,
        transition: 'opacity 0.3s',
      }}
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
    >
      <CardContent sx={{ flexGrow: 1, paddingBottom: 0, backgroundColor: '#323239' }}>
        <Box sx={{ flexDirection: 'column', display: 'flex', height: '100%' }}>
          <Box sx={{ flexDirection: 'row', display: 'flex', justifyContent: 'space-between' }}>
            <Box>{formattedStartDate}</Box>
            <Box>{isLive ? <LiveIndicator color="white" /> : getDuration(start, end)}</Box>
          </Box>

          <Box
            sx={{
              flexDirection: 'column',
              height: '100%',
              justifyContent: 'center',
              display: 'flex',
            }}
          >
            <Box sx={{ display: 'flex', alignItems: 'center' }}>
              <img
                src={useTeamLogoPath(game.awayTeam)}
                width={45}
                style={{ marginRight: 7 }}
                alt={game.awayTeam.abbreviation}
              />
              <Typography variant="h5">{game.awayTeam.abbreviation}</Typography>
              <Typography variant="h6" marginLeft={2} sx={{ opacity: 0.6 }}>
                {game.awayTeam.name}
              </Typography>
              <Box flexGrow="1">
                {game.externalid && gameStatus !== 'scheduled' && (
                  <Typography
                    variant="h4"
                    sx={{
                      textAlign: 'right',
                      paddingRight: 5,
                      fontWeight: 'bold',
                      opacity: game.awayTeamScore >= game.homeTeamScore ? 1 : 0.4,
                    }}
                  >
                    {game.awayTeamScore}
                  </Typography>
                )}
              </Box>
            </Box>

            <Box sx={{ display: 'flex', alignItems: 'center', marginTop: 2 }}>
              <img
                src={useTeamLogoPath(game.homeTeam)}
                width={45}
                style={{ marginRight: 7 }}
                alt={game.homeTeam.abbreviation}
              />
              <Typography variant="h5">{game.homeTeam.abbreviation}</Typography>
              <Typography variant="h6" marginLeft={2} sx={{ opacity: 0.6 }}>
                {game.homeTeam.name}
              </Typography>
              <Box flexGrow="1">
                {game.externalid && gameStatus !== 'scheduled' && (
                  <Typography
                    variant="h4"
                    sx={{
                      fontWeight: 'bold',
                      textAlign: 'right',
                      paddingRight: 5,
                      opacity: game.homeTeamScore >= game.awayTeamScore ? 1 : 0.4,
                    }}
                  >
                    {game.homeTeamScore}
                  </Typography>
                )}
              </Box>
            </Box>
          </Box>
        </Box>
      </CardContent>

      <CardActions disableSpacing sx={{ backgroundColor: '#2c2c34' }}>
        <Button
          component={Link}
          to={`/games/${game.id}`}
          sx={{
            textTransform: 'uppercase',
            letterSpacing: 1,
            margin: 0,
            color: 'secondary.main',
          }}
          disabled={!['live', 'recorded'].includes(gameStatus)}
          onClick={() => {
            Tracking.getInstance().track('Click Watch Game', {
              category: 'Access & Navigation',
              label: 'Button',
              isLive,
              game: game.id,
            });
          }}
        >
          {isLive ? 'Join Game' : 'Watch Game'}
        </Button>

        <Stack flexGrow={1} sx={{ flexDirection: 'row-reverse' }}>
          <Typography
            variant="subtitle2"
            sx={{ fontStyle: 'italic', fontSize: 10, paddingRight: 1 }}
          >
            {gameKey}
          </Typography>
        </Stack>
      </CardActions>
    </Card>
  );
};

export default GameCard;
