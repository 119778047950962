import React, { useEffect } from 'react';
import { Box, Typography, Stack } from '@mui/material';
import { ChangePasswordForm } from '@/features/authentication/components';
import SessionList from './SessionList';

const AccountSecurity = () => {
  return (
    <>
      <Box p={2} maxWidth={500}>
        <Typography variant="body1" mb={5}>
          Set new password
        </Typography>
        <ChangePasswordForm />
      </Box>
      <Box p={2}>
        <Typography variant="body1" mb={5}>
          Last Sessions
        </Typography>
        <SessionList />
      </Box>
    </>
  );
};

export default AccountSecurity;
