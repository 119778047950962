import React, { useEffect } from 'react';
import { Card, CardContent, Typography, Grid, Box, Stack, CardActions } from '@mui/material';
import { SessionData } from '../types';
import { Chip } from '@mui/material';
import { styled } from '@mui/system';
import { Place } from '@mui/icons-material';
import useIPLookupQuery from '../hooks/useIPLookup';

const ExpiredChip = styled(Chip)(({ theme }) => ({
  backgroundColor: theme.palette.error.main,
  color: 'white',
  marginTop: '5px',
}));

const CurrentChip = styled(Chip)(({ theme }) => ({
  backgroundColor: theme.palette.success.main,
  color: 'white',
  marginTop: '5px',
}));

interface StatusPillProps {
  isExpired: boolean;
}

const StatusPill: React.FC<StatusPillProps> = ({ isExpired }) => {
  return (
    <>
      {isExpired ? (
        <ExpiredChip
          size="small"
          label="Expired"
          sx={(theme) => ({ backgroundColor: theme.palette.error })}
        />
      ) : (
        <CurrentChip size="small" label="Current" />
      )}
    </>
  );
};

function formatDate(date: Date) {
  return new Intl.DateTimeFormat(navigator.language, {
    year: 'numeric',
    month: 'long',
    day: 'numeric',
    hour: 'numeric',
    minute: 'numeric',
    second: 'numeric',
    timeZoneName: 'short',
  }).format(date);
}

export const SessionInfoCard: React.FC<{ session: SessionData }> = ({ session }) => {
  const { sessionDetails, expired } = session;

  const ipLookup = useIPLookupQuery();

  useEffect(() => {
    if (session?.sessionDetails?.ip) {
      ipLookup.mutate(session.sessionDetails.ip);
    }
  }, []);

  return (
    <Card sx={{ marginBottom: 2 }}>
      <CardContent>
        <Typography variant="body1">{formatDate(new Date(sessionDetails.createdAt))}</Typography>
        {sessionDetails.computeInstance?.hostname && (
          <Typography color="grey" variant="body2">
            CXVH Instance: {sessionDetails.computeInstance?.hostname}
          </Typography>
        )}
      </CardContent>

      <CardActions>
        <Stack
          sx={{
            justifyContent: 'space-between', // Space between items
            alignItems: 'center',
            flexDirection: 'row', // Ensure items are laid out in a row
          }}
          width="100%" // Take up full width of parent container
        >
          {ipLookup?.data && (
            <Stack direction="row" alignItems="center" spacing={1}>
              <Place style={{ marginRight: 8 }} />
              <Typography color="white" variant="body2">
                {ipLookup?.data?.city}, {ipLookup?.data?.country_name}
              </Typography>
            </Stack>
          )}
          {!sessionDetails?.ip && (
            <Stack direction="row" alignItems="center" spacing={1}>
              <Place style={{ marginRight: 8 }} />
              <Typography color="white" variant="body2">
                N/A
              </Typography>
            </Stack>
          )}

          <StatusPill isExpired={expired}></StatusPill>
        </Stack>
      </CardActions>
    </Card>
  );
};

export default SessionInfoCard;
