import React from 'react';

import Typography from '@mui/material/Typography';

import AuthenticationPage from './AuthenticationPage';
import ConfirmPasswordForm from './ConfirmPasswordForm';

const ChangePasswordPage = () => (
  <AuthenticationPage>
    <Typography variant="h6">Change Password</Typography>

    <Typography variant="subtitle2" sx={{ my: 3 }}>
      Enter your verification code and your new password and click Submit.
    </Typography>

    <ConfirmPasswordForm />
  </AuthenticationPage>
);

export default ChangePasswordPage;
