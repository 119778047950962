import { useRef } from 'react';

import { SelectCamMessage } from '@/types/control';

import useWebSocket from './useWebSocket';
import { WebSocketRefHook, withStateFromRef } from '../withStateFromRef';

const SELECT_CAM_TYPE = 'SelectCam';

export const useSelectCamRef: WebSocketRefHook<number> = (options = {}) => {
  const ref = useRef(0);

  const handleChange = (value: number) => {
    ref.current = value;

    if (options.onChange) {
      options.onChange(value);
    }
  };

  const socket = useWebSocket<SelectCamMessage>('/control/0.1.0', {
    onMessage: (message) => {
      if (message.type !== SELECT_CAM_TYPE) return;
      handleChange(message.idx);
    },
  });

  const setValue = (value: number) => {
    socket.send({
      type: SELECT_CAM_TYPE,
      idx: value,
    });
    handleChange(value);
  };

  return [ref, setValue];
};

export const useSelectCamsState = withStateFromRef(useSelectCamRef);
