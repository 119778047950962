import ReactGA from 'react-ga';

import { enableGAtracker, gaTrackingId } from '@/shared/constants';

import { Tracker } from './Tracker';

const capitalize = (s) =>
  s[0].toUpperCase() + s.slice(1).replace(/-./g, (x) => ` ${x[1].toUpperCase()}`);

export class GoogleAnalytics implements Tracker {
  private static instance: GoogleAnalytics;

  private ready = false;

  private name = 'Google Analytics';

  private constructor() {
    if (!enableGAtracker) {
      console.debug('Skipped Google analytics');
      return;
    }

    if (!gaTrackingId) {
      console.warn('Skipped Google analytics (No tracking Id provided)');
      return;
    }

    try {
      ReactGA.initialize(gaTrackingId);
      this.ready = true;
      if (this.ready) console.debug(`Loaded Google analytics with ${gaTrackingId}`);
    } catch (e) {
      console.warn('Skipped Google analytics');
    }
  }

  signIn(props): void {
    this.track('sign-in', props);
  }

  signOut(props): void {
    this.track('sign-out', props);
  }

  public toString() {
    return this.name;
  }

  public static getInstance(): GoogleAnalytics {
    if (!GoogleAnalytics.instance) {
      GoogleAnalytics.instance = new GoogleAnalytics();
    }

    return GoogleAnalytics.instance;
  }

  public identify(userId: string, userProps: any) {
    try {
      if (!this.ready) return;
      ReactGA.set({ 'cognito-group': userProps?.group });
      ReactGA.set({ userId });
      ReactGA.set({ env: import.meta.env.NODE_ENV });
    } catch (error) {
      console.error(error);
    }
  }

  public navigation(location) {
    if (!this.ready) return;
    ReactGA.pageview(location);
  }

  public track(event: string, payload?: any) {
    if (!this.ready) return;
    if (event === 'Navigation') {
      this.navigation(payload.location);
    }
    const { label, category } = payload;
    const action = capitalize(event);
    ReactGA.event({ category, action, label });
  }
}
