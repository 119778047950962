import React from 'react';
import { Box, Typography, Stack } from '@mui/material';
import TimeZoneSelector from './TimeZoneSelector';
import { UserForm } from './UserForm';
import { UserStateContext } from '@/shared/components/UserStateProvider';
import { useSnackbar } from 'notistack';

const AccountInfoPage = () => {
  const snackbar = useSnackbar();
  const { user, updateUserFirstNameAndLastName } = React.useContext(UserStateContext);

  const handleUpdateUser = async (firstName: string, lastName: string) => {
    await updateUserFirstNameAndLastName(firstName, lastName);
    snackbar.enqueueSnackbar('User info updated', { variant: 'success' });
  };

  return (
    <>
      <Box p={2}>
        <Typography variant="body1" mb={5}>
          Update User Info
        </Typography>
        <UserForm
          initialFirstName={user.firstName}
          initialLastName={user.lastName}
          onSubmit={handleUpdateUser}
        />
      </Box>

      <Box p={2}>
        <Typography variant="body1" mb={5}>
          Timezone Settings
        </Typography>
        <TimeZoneSelector />
      </Box>
    </>
  );
};

export default AccountInfoPage;
