import { IconButton, Stack, Theme, Typography, useTheme } from '@mui/material';
import React from 'react';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { makeStyles } from '@mui/styles';

const useStyles = makeStyles((theme: Theme) => ({
  dropdown: {
    transition: theme.transitions.create(['transform'], {
      duration: theme.transitions.duration.standard,
    }),
    color: theme.palette.grey[400],
  },
  dropdownOpen: {
    transform: 'rotate(0)',
  },
  dropdownClosed: {
    transform: 'rotate(-90deg)',
  },
}));

type Props = {
  disabled?: boolean;
  expanded?: boolean;
  onMessage?: string;
  offMessage?: string;
  onClickHandler: () => void;
};

const ExpandToggleButton = ({
  disabled = false,
  expanded = false,
  onMessage = '',
  offMessage = '',
  onClickHandler,
}: Props) => {
  if (onMessage && !offMessage) console.warn('on Message and off message both must be specified');
  if (offMessage && !onMessage) console.warn('on Message and off message both must be specified');

  const hasLabel: boolean = onMessage !== undefined && offMessage !== undefined;

  const classes = useStyles();
  const theme = useTheme();

  const leftSpacing = theme.spacing(3);

  return (
    <IconButton
      onClick={onClickHandler}
      sx={{ '&:hover': { background: 'none' } }}
      size="small"
      disableRipple={hasLabel}
      disabled={disabled}
    >
      <Stack direction="row" alignItems="center">
        <ExpandMoreIcon
          sx={{ width: leftSpacing, height: leftSpacing }}
          className={[
            classes.dropdown,
            expanded ? classes.dropdownOpen : classes.dropdownClosed,
          ].join(' ')}
        />

        {hasLabel && (
          <Typography
            variant="subtitle2"
            sx={{
              textDecoration: 'underline',
              cursor: 'pointer',
              color: 'rgb(255, 255, 255)',
              textTransform: 'none',
            }}
          >
            {expanded ? onMessage : offMessage}
          </Typography>
        )}
      </Stack>
    </IconButton>
  );
};

export default ExpandToggleButton;
