import { AutoIsoId } from './AutoIsoId';

export enum AutoIsoObjectType {
  BallOrPuck = 'BallOrPuck',
  /** Referee/linesman/etc. */
  Official = 'Official',
  Player = 'Player',
  None = 'None',
}

export const OFFICIAL_PEOPLE_GROUP = 'official';

export const getTeam = (model: AutoIsoModel) => {
  if (model?.type === AutoIsoObjectType.Player) {
    return model.playerTeamId;
  }
  return null;
};

export type AutoIsoBallOrPuck = {
  displayName: string;
  id: AutoIsoId;
  type: AutoIsoObjectType.BallOrPuck;
};

export type AutoIsoPlayer = {
  displayName: string;
  id: AutoIsoId;
  playerTeamId: string;
  playerPosition: string;
  playerNumber: string;
  type: AutoIsoObjectType.Player;
};

export type AutoIsoOfficial = {
  displayName: string;
  id: AutoIsoId;
  type: AutoIsoObjectType.Official;
  playerNumber: string;
  playerPosition: string;
};

export type AutoIsoModel = AutoIsoBallOrPuck | AutoIsoPlayer | AutoIsoOfficial;
