let previousBytesReceived = 0;
let previousTimestamp = 0;

export const calculateBitrate = (stats) => {
  const now = Date.now();
  const { bytesReceived } = stats;

  if (bytesReceived === undefined || Number.isNaN(bytesReceived)) {
    return 0;
  }

  if (previousBytesReceived && previousTimestamp) {
    const bytesDelta = bytesReceived - previousBytesReceived;
    const timeDelta = now - previousTimestamp;

    const bitrate = (bytesDelta * 8) / timeDelta; // Bitrate in bits per millisecond
    return bitrate * 1000; // Bitrate in bits per second
  }

  previousBytesReceived = bytesReceived;
  previousTimestamp = now;
  return 0;
};
