import { TimeApiResponse } from '@/shared/services/getTimeApiDate';
import { useMutation } from '@tanstack/react-query';
import { post } from '@truefit/http-utils';

const useCreateSession = () =>
  useMutation({
    mutationFn: async (localizationData: TimeApiResponse): Promise<any> => {
      await post<void>('session', localizationData);
      return {};
    },
  });

export default useCreateSession;
