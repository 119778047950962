import React, { useEffect } from 'react';
import { Box, Button } from '@mui/material';
import { SessionData } from '../types';
import SessionInfoCard from './SessionInfoCard';
import useAccountSessionsQuery from '../hooks/useListAccountSessions';

interface Props {
  sessionData: SessionData[];
}

const SessionList = () => {
  const { data, fetchNextPage, hasNextPage, isFetchingNextPage } = useAccountSessionsQuery();

  useEffect(() => {
    fetchNextPage();
  }, []);

  return (
    <Box sx={{ padding: 2, minWidth: '600px' }}>
      {data?.pages.map((page, i) => (
        <React.Fragment>
          {page.data.map((session) => (
            <SessionInfoCard session={session} />
          ))}
        </React.Fragment>
      ))}

      <Button onClick={() => fetchNextPage()} disabled={!hasNextPage || isFetchingNextPage}>
        {isFetchingNextPage ? 'Loading more...' : 'Load More'}
      </Button>
    </Box>
  );
};

export default SessionList;
