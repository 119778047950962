import React, { useEffect, useState } from 'react';

import { Box, Button, Container, Divider, Typography } from '@mui/material';
import AppPage from '../../../shared/components/PageComponents/AppPage';
import GameCard from './GameCard';
import GamesForm, { DEFAULT_VALUES, FormValues as GamesFormValues } from './GamesForm';
import { useDocumentTitle } from '@/shared/hooks/useDocumentTitle';
import { ActivityIndicator } from './ActivityIndicator';
import { useGamesQuery } from '../hooks/useGameQuery';
import { getGridMaxWidth } from '../services/getGridMaxWidth';

const GamesPage = () => {
  const [formValues, setFormValues] = useState<GamesFormValues>(DEFAULT_VALUES);
  const gamesQuery = useGamesQuery(formValues);

  const total = gamesQuery.data?.pages[0].total;
  const allGames = gamesQuery.data?.pages.flatMap((p) => p.results);
  const hasMore = gamesQuery.hasNextPage && !gamesQuery.isError;
  const isLoading = gamesQuery.isLoading || gamesQuery.isFetchingNextPage;

  const handleFilterAndSortChange = (values: GamesFormValues) => {
    setFormValues(values);
  };

  useEffect(() => {
    gamesQuery.refetch();
  }, []);

  useDocumentTitle('Games');

  return (
    <AppPage title="Games">
      <Container maxWidth="xl" sx={{ marginTop: 4, marginBottom: 4 }}>
        <Typography variant="h6">Select a game</Typography>
        <Typography variant="body1">
          Choose between live and recorded games for a customized viewing experience.
        </Typography>
        <Divider sx={{ marginTop: 3 }} />

        <GamesForm onChange={handleFilterAndSortChange} />

        {total !== undefined ? (
          <Typography variant="h6" sx={{ marginTop: 2 }}>
            {total === 0 ? 'No games found.' : total > 1 ? `${total} Games` : `${total} Game`}
          </Typography>
        ) : null}

        {gamesQuery.isError ? (
          <Typography color="error" sx={{ textAlign: 'center' }}>
            An error ocurred while fetching games.
          </Typography>
        ) : null}

        <Box
          sx={{
            gap: 2,
            display: 'grid',
            gridTemplateColumns: 'repeat(auto-fit, minmax(380px, 1fr))',
            gridAutoRows: '230px',
            marginTop: 2,
            maxWidth: getGridMaxWidth(allGames?.length),
          }}
        >
          {allGames?.map((g) => (
            <GameCard
              key={g.id}
              game={g}
              // Apply a fade-in animation only to new games
            />
          ))}
        </Box>

        {isLoading ? (
          <ActivityIndicator />
        ) : (
          <Box sx={{ textAlign: 'center', marginTop: 3 }}>
            {hasMore && (
              <Button
                component="button"
                onClick={() => {
                  gamesQuery.fetchNextPage();
                }}
                variant="text"
              >
                Load More
              </Button>
            )}
          </Box>
        )}
      </Container>
    </AppPage>
  );
};

export default GamesPage;
