import React from 'react';

import CircularProgress from '@mui/material/CircularProgress';
import Fade from '@mui/material/Fade';
import Grid from '@mui/material/Grid';
import { makeStyles } from '@mui/styles';

import { useCurrentUser } from '../hooks';

const useStyles = makeStyles({
  container: {
    height: '100vh',
  },
});

const GetCurrentUserPage = () => {
  const classes = useStyles();
  useCurrentUser();

  return (
    <Fade
      in
      style={{
        transitionDelay: '800ms',
      }}
      unmountOnExit
    >
      <Grid
        container
        direction="column"
        alignContent="center"
        justifyContent="center"
        className={classes.container}
      >
        <Grid item>
          <CircularProgress size={200} />
        </Grid>
      </Grid>
    </Fade>
  );
};

export default GetCurrentUserPage;
