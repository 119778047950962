import { useQuery } from '@tanstack/react-query';
/* eslint-disable import/prefer-default-export */
import { get } from '@truefit/http-utils';

export const queryKey = (gameId: number) => ['SwitchGame', gameId];

const useGameSwitchQuery = (gameId: number) =>
  useQuery({
    queryKey: queryKey(gameId),
    queryFn: async () => get(`/games/${gameId}/switch`),
    gcTime: 0,
    enabled: false,
    refetchOnWindowFocus: false,
  });

export default useGameSwitchQuery;
