import { useInfiniteQuery } from '@tanstack/react-query';

import { FormValues as GamesFormValues } from '../components/GamesForm';
import { GetGamesResult } from '../types/GetGamesResult';
import { fetchGames } from '../services/fetchGames';

const LIMIT = 9;

function isNullOrUndefinedOrEmpty(value: any): boolean {
  return value === undefined || value === null || value === '';
}

export function getQueryKey(formValues: GamesFormValues) {
  return ['Games', { formValues }];
}

const getNextPageParam = (lastPage: GetGamesResult) =>
  lastPage.offset + lastPage.results.length < lastPage.total ? lastPage.offset + LIMIT : undefined;

export const useGamesQuery = (formValues: GamesFormValues) =>
  useInfiniteQuery({
    queryKey: getQueryKey(formValues),
    queryFn: async ({ pageParam: offset = 0 }) => {
      const { data } = await fetchGames({ offset, limit: LIMIT, ...formValues });
      return data;
    },
    refetchOnWindowFocus: true,
    getNextPageParam,
    initialPageParam: 0,
    enabled: !isNullOrUndefinedOrEmpty(formValues.leagueId), // Disable the query if leagueId is undefined
  });
