import { Dispatch } from 'redux';

import { createAction } from '@reduxjs/toolkit';
import { get } from '@truefit/http-utils';
import { ApiError } from '@/shared/types';

import { UserIdentity } from '../types';

export enum GetCurrentUserActions {
  Succeeded = 'CURRENT_USER/GET_SUCCEEDED',
}

const succeeded = createAction<UserIdentity>(GetCurrentUserActions.Succeeded);

export const getCurrentUser =
  (onError?: (error: ApiError) => void) =>
  async (dispatch: Dispatch): Promise<void> => {
    try {
      const response = await get('authentication/current-user/identity');
      dispatch(succeeded(response.data));
    } catch (e) {
      if (onError) onError(e);
    }
  };
