import { useState, useEffect } from 'react';

// Custom hook for surface dimensions and metadata
export const useSurfaceDimensions = (gameTypeMetadataQuery) => {
  const [surfaceWidth, setSurfaceWidth] = useState(0);
  const [surfaceHeight, setSurfaceHeight] = useState(0);
  const [surfaceName, setSurfaceName] = useState('Surface');
  const [objectOfPlay, setObjectOfPlay] = useState('Ball');

  useEffect(() => {
    const data = gameTypeMetadataQuery.data;
    if (data?.surfaceWidth && data?.surfaceHeight) {
      setSurfaceWidth(data.surfaceWidth);
      setSurfaceHeight(data.surfaceHeight);
      setSurfaceName(data.surfaceName);
      setObjectOfPlay(data.objectOfPlay);
    }
  }, [gameTypeMetadataQuery.data]);

  return { surfaceWidth, surfaceHeight, surfaceName, objectOfPlay };
};
