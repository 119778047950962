import React from 'react';
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  IconButton,
  Chip,
  Paper,
} from '@mui/material';
import { Delete } from '@mui/icons-material';
import { formatDistanceToNow } from 'date-fns';

interface User {
  id: string;
  name: string;
  email: string;
  lastSeen: string;
  groups: string[];
  lastName: string;
  firstName: string;
}

interface UsersTableProps {
  users: User[];
  setSelectedUser?: (user: User) => void;
  setGroupOpen?: (open: boolean) => void;
  handleDelete: (id: string) => void;
}

export const UsersTable: React.FC<UsersTableProps> = ({ users, setSelectedUser, handleDelete }) => {
  return (
    <TableContainer component={Paper}>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell>Name</TableCell>
            <TableCell>Email</TableCell>
            <TableCell>Last Seen</TableCell>
            <TableCell>Groups</TableCell>
            <TableCell>Actions</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {users &&
            users.map((user) => (
              <TableRow key={user.id}>
                <TableCell>
                  {user.firstName} {user.lastName}
                </TableCell>
                <TableCell>{user.email}</TableCell>
                <TableCell>{formatDistanceToNow(new Date(user.lastSeen))} ago</TableCell>
                <TableCell>
                  {user.groups.map((group) => (
                    <Chip key={group} label={group} color="secondary" size="small" />
                  ))}
                </TableCell>
                <TableCell>
                  <IconButton edge="end" onClick={() => handleDelete(user.id)}>
                    <Delete />
                  </IconButton>
                </TableCell>
              </TableRow>
            ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default UsersTable;
