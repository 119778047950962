import React from 'react';

import Typography from '@mui/material/Typography';

import AuthenticationPage from './AuthenticationPage';
import SignInForm from './SignInForm';
import { LeagueProvider } from './LeagueContext';

const SignInPage = () => (
  <LeagueProvider>
    <AuthenticationPage>
      <Typography variant="h6" sx={{ mb: 2 }}>
        Log In
      </Typography>

      <SignInForm />
    </AuthenticationPage>
  </LeagueProvider>
);

export default SignInPage;
