import Sintony from '@/assets/fonts/Sintony/Sintony-Regular.ttf';
import StolzBook from '@/assets/fonts/Stolzl/Stolzl-Book.otf';
import Sohne from '@/assets/fonts/Sohne/soehne-kraftig.woff2';
import Monument from '@/assets/fonts/Monument/MonumentExtended-Regular.otf';
import cosmStyles from './style.css?inline';
import generateCSSVarsObject from './stylesParser';

const { colors } = generateCSSVarsObject(cosmStyles);

const primaryColor = colors.tertiary500;

export const themeOptions = {
  typography: {
    fontFamily: '"Stolzl Book", Arial',
  },
  components: {
    MuiToggleButton: {
      styleOverrides: {
        root: {
          fontFamily: 'Sohne, arial, sans-serif',
          fontStyle: 'normal',
          textTransform: 'Capitalize',
          color: colors.wash100,
          borderColor: primaryColor,
          transition: 'all 0.1s ease', // This line adds the transition

          opacity: 0.8,
          '&.Mui-disabled': {
            borderColor: primaryColor,
            color: primaryColor,
            opacity: 0.4,
          },
          '&.Mui-selected': {
            backgroundColor: primaryColor,
            color: 'black',
            opacity: 1,
            '&:hover': {
              backgroundColor: primaryColor,
              opacity: 0.8,
            },
          },
          '&:hover': {
            opacity: 0.9,
          },
        },
      },
    },
    MuiButton: {
      styleOverrides: {
        root: {
          fontFamily: 'Sohne, arial, sans-serif',
          fontStyle: 'normal',
          textTransform: 'Capitalize',
          '&.primary': {
            backgroundColor: primaryColor,
            color: 'black',
          },
        },
      },
    },
    MuiCssBaseline: {
      styleOverrides: `
          @font-face {
            font-family: 'Sintony';
            font-style: normal;
            font-display: swap;
            font-weight: 400;
            src: local('Sintony'), local('Sintony-Regular'), url(${Sintony}) format('woff2');
          }

          @font-face {
            font-family: 'Stolzl Book';
            font-style: normal;
            font-display: swap;
            font-weight: 400;
            src:  local('Stolzl-Book'), url(${StolzBook}) format('otf');
          }

          @font-face {
            font-family: 'Sohne';
            font-style: normal;
            font-display: swap;
            font-weight: 400;
            src: local('Sohne'), url(${Sohne}) format('woff2');
          }
        
          @font-face {
            font-family: 'Monument';
            font-style: normal;
            font-display: swap;
            font-weight: 400;
            src: local('Monument'), url(${Monument}) format('otf');
          }

        `,
    },
  },
  palette: {
    primary: {
      main: primaryColor,
    },
    secondary: {
      main: primaryColor,
    },
    action: {
      active: 'rgba(255, 255, 255, 0.56)',
      hover: 'rgba(255, 255, 255, 0.08)',
      disabled: 'rgba(255, 255, 255, 0.3)',
      selected: 'rgba(255, 255, 255, 0.16)',
      focus: 'rgba(255, 255, 255, 0.12)',
      disabledBackground: 'rgba(255, 255, 255, 0.12)',
    },
    error: {
      main: colors.red500,
    },
    warning: {
      main: colors.yellow500,
    },
    info: {
      main: colors.tertiary600,
    },
    success: {
      main: colors.green500,
    },
    background: {
      paper: colors.wash950,
    },
  },
};

export default themeOptions;
