import { useEffect } from 'react';
import { nodeEnv } from '@/shared/constants';

const IS_PROD = ['prd', 'production', 'stg', 'staging'].includes(nodeEnv);

export const useDocumentTitle = (title) => {
  useEffect(() => {
    let appTitle = 'CX Video Hub';
    if (!IS_PROD) {
      appTitle = `${appTitle} - ${nodeEnv.toUpperCase()}`;
    }
    document.title = `${appTitle} · ${title}`;
  }, [title]);

  return null;
};
