import { parseISO } from 'date-fns';

import { ClipModel, ClipModelFromServer } from '../types';

const toClipModel = (clipModelFromServer: ClipModelFromServer): ClipModel => {
  const startTimestamp = parseISO(clipModelFromServer.startTimestamp);
  const endTimestamp = clipModelFromServer.endTimestamp
    ? parseISO(clipModelFromServer.endTimestamp)
    : null;
  const bookmarkTimestamp = clipModelFromServer.bookmarkTimestamp
    ? parseISO(clipModelFromServer.bookmarkTimestamp)
    : clipModelFromServer.startTimestamp;
  const createdAt = parseISO(clipModelFromServer.createdAt);

  return {
    ...clipModelFromServer,
    bookmarkTimestamp,
    startTimestamp,
    endTimestamp,
    createdAt,
    type: clipModelFromServer.mediatype,
  };
};

export default toClipModel;
