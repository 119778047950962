import asyncAwaitMiddleware from 'redux-async-await';
import thunkMiddleware from 'redux-thunk';

import { configureStore } from '@reduxjs/toolkit';
import createRootReducer from '@/rootReducer';
/* eslint-disable global-require */

// scaffolding
const IS_PRODUCTION = process.env.NODE_ENV === 'production';
const middleware = [thunkMiddleware, asyncAwaitMiddleware];
const reducer = createRootReducer();

// export configure func
export default () => {
  const store = configureStore({
    reducer,
    middleware,

    devTools: IS_PRODUCTION || {
      name: 'Cosm Web Viewer',
    },
  });

  return store;
};
