import { styled } from '@mui/material/styles';
import { Slider } from '@mui/material';

const PLAYER_Z_INDEX = 100;

export const MainSlider = styled(Slider)({
  '& .MuiSlider-thumb': {
    zIndex: PLAYER_Z_INDEX + 1,
  },
});

export const ClipSlider = styled(Slider)({
  '& .MuiSlider-thumb': {
    //    display: 'none',
  },
  '& .MuiSlider-rail': {
    opacity: 0,
  },
  '& .MuiSlider-track': {
    zIndex: PLAYER_Z_INDEX,
  },
});

export const getSliderStyle = (theme) => ({
  borderRadius: 0,
  color: '#ff0000',
  paddingBottom: 0,
  transition: '0.3s cubic-bezier(.47,1.64,.41,.8)',
  height: 4,
  marginBottom: 0, // Fixed `mb`
  '&:hover': {
    cursor: 'pointer',
    '& .MuiSlider-thumb': {
      width: 8,
      height: 8,
    },
  },
  '& .MuiSlider-thumb': {
    width: 0,
    height: 0,
    '&:hover': {
      width: 15,
      height: 15,
    },
    transition: '0.3s cubic-bezier(.47,1.64,.41,.8)',
    '&:before': {
      boxShadow: '0 2px 12px 0 rgba(0,0,0,0.4)',
    },
    '&:hover, &.Mui-focusVisible': {
      boxShadow: `0px 0px 0px 8px ${
        theme.palette.mode === 'dark' ? 'rgb(255 255 255 / 2%)' : 'rgb(0 0 0 / 2%)'
      }`,
    },
    '&.Mui-active': {
      width: 20,
      height: 20,
    },
  },
  '& .MuiSlider-rail': {
    opacity: 0.28,
  },
});
