import { TrackedEntity } from './TrackedEntity';
import { CameraIcon, cameraSize } from './CameraIcon';
import React, { useEffect, useState } from 'react';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { Box } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import useCurrentGameId from '@/shared/hooks/useCurrentGameId';
import useServerStateContext from '@/shared/hooks/useServerStateContext';
import useAutoIsoEntitiesQuery from '../hooks/useAutoIsoEntitiesQuery';
import useGameTypeMetadataQuery from '@/shared/hooks/useGameTypeMetadataQuery';
import { useGameState } from '@/shared/hooks/websocket/useGameState';
import { getBusinessApiEndPoint } from '../../../util';
import { useSurfaceDimensions } from './useSurfaceDimensions';
import { AutoIsoModel } from '../types';

type Props = {
  elementSize: { width: number; height: number };
};

// Main component
const GamePositionsRepresentation = ({ elementSize }: Props) => {
  const { availableCameras, selectedCameraId, selectCamera } = useServerStateContext();
  const { showCameraIcons } = useFlags();
  const autoIsoQuery = useAutoIsoEntitiesQuery();
  const { lookup: autoIsoEntityLookup, homeTeam } = autoIsoQuery.data;
  const [gameState] = useGameState();
  const theme = useTheme();
  const gameTypeMetadataQuery = useGameTypeMetadataQuery();
  const { surfaceWidth, surfaceHeight, surfaceName, objectOfPlay } =
    useSurfaceDimensions(gameTypeMetadataQuery);
  const [top, setTop] = useState<string>();

  const [, setTrackedEntities] = useState<AutoIsoModel[]>([]);

  useEffect(() => {
    if (gameState?.tracking.entities && autoIsoQuery.status === 'success') {
      const trackedEntities = gameState?.tracking.entities
        .map((pos) => {
          const entity = autoIsoEntityLookup[pos.id];
          if (!entity || pos.z < 0) return null;
          return entity;
        })
        .filter((entity) => entity);
      setTrackedEntities(trackedEntities);
    }
  }, [gameState?.tracking.entities, autoIsoQuery.status]);

  return (
    <Box
      sx={{
        touchAction: 'none',
        userSelect: 'none',
        width: elementSize.width,
        height: elementSize.height,
        position: 'relative',
      }}
    >
      <img
        alt={surfaceName}
        src={`${getBusinessApiEndPoint('surface')}/games/${useCurrentGameId()}/surface`}
        style={{ width: '100%', position: 'absolute' }}
      />

      {showCameraIcons && (
        <>
          {availableCameras.length > 0 &&
            availableCameras
              .filter((c) => Number.isFinite(c.x) && Number.isFinite(c.y))
              .map((camera) => {
                const xPercent = (camera.x / (surfaceWidth / 2) + 1) / 2.0;
                const yPercent = (camera.y / (surfaceHeight / 2) + 1) / 2.0;
                const xVal = (elementSize.width - cameraSize) * xPercent;
                const yVal = (elementSize.height - cameraSize) * yPercent;
                const computedX = Number.isFinite(xVal) ? xVal : 0;
                const computedY = Number.isFinite(yVal) ? yVal : 0;

                return (
                  <CameraIcon
                    key={camera.id}
                    camera={camera}
                    xVal={computedX}
                    yVal={computedY}
                    theme={theme}
                    isActive={selectedCameraId === camera.idx}
                    onClick={() => selectCamera(camera.idx, `Virtual ${surfaceName}`)}
                  />
                );
              })}
        </>
      )}

      {gameState?.tracking.entities.map((pos) => {
        const entity = autoIsoEntityLookup[pos.id];
        if (!entity || pos.z < 0) return null;
        return (
          <TrackedEntity
            key={pos.id}
            entity={entity}
            pos={pos}
            elementSize={elementSize}
            surfaceWidth={surfaceWidth}
            surfaceHeight={surfaceHeight}
            objectOfPlay={objectOfPlay}
            homeTeam={homeTeam}
            surfaceName={surfaceName}
            setTop={setTop}
            top={top}
          />
        );
      })}
    </Box>
  );
};

export default GamePositionsRepresentation;
