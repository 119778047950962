import { useContext } from 'react';

import { ClipStateContext } from '../components/ClipStateProvider';

const useClipStateContext = () => {
  const clipState = useContext(ClipStateContext);

  return clipState;
};

export default useClipStateContext;
