import { useTheme } from '@mui/material';
import { AutoIsoObjectType } from '../types/AutoIsoModel';
import { makeStyles } from '@mui/styles';
import useServerStateContext from '@/shared/hooks/useServerStateContext';

const useStyles = makeStyles(() => ({
  obj: {
    position: 'absolute',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    fontWeight: 'bold',
    color: 'black',
  },
}));

export const TrackedEntity = ({
  elementSize,
  entity,
  objectOfPlay,
  pos,
  homeTeam,
  setTop,
  surfaceHeight,
  surfaceWidth,
  surfaceName,
  top,
}) => {
  const { singleCurrentlyTrackedObjectId, trackEntity } = useServerStateContext();
  const isTracked = entity.id === singleCurrentlyTrackedObjectId;
  const theme = useTheme();
  const styles = useStyles();
  const getLabel = (entity) =>
    entity.type === AutoIsoObjectType.Player || entity.type === AutoIsoObjectType.Official
      ? entity.playerNumber
      : objectOfPlay?.charAt(0).toUpperCase();

  const getObjectSize = (entity) =>
    entity.type === AutoIsoObjectType.Player || entity.type === AutoIsoObjectType.Official
      ? 24
      : 15;

  // TODO rework this part to present the entities with the team colors.
  const getObjectColor = (entity) => {
    const onHomeTeam =
      entity.type === AutoIsoObjectType.Player && entity.playerTeamId === homeTeam.id;
    if (entity.id === singleCurrentlyTrackedObjectId) return 'white';
    if (entity.type === AutoIsoObjectType.Player)
      return onHomeTeam ? theme.palette.error.light : theme.palette.info.light;
    return entity.type === AutoIsoObjectType.Official
      ? theme.palette.grey[700]
      : theme.palette.primary.main;
  };

  const objectSize = getObjectSize(entity);
  const objectLabelFontSize = objectSize / 2;
  const xPercent = (pos.x / (surfaceWidth / 2) + 1) / 2.0;
  const yPercent = (pos.y / (surfaceHeight / 2) + 1) / 2.0;
  const xVal = (elementSize.width - objectSize) * xPercent;
  const yVal = (elementSize.height - objectSize) * yPercent;
  const color = getObjectColor(entity);
  const zIndex = isTracked || top === entity.id ? 2 : 1;

  console.log('TrackedEntity', entity, pos, xVal, yVal, color, objectSize, objectLabelFontSize);

  return (
    <div
      className={styles.obj}
      style={{
        cursor: 'pointer',
        width: objectSize,
        height: objectSize,
        borderRadius: objectSize / 2,
        backgroundColor: color,
        left: xVal,
        bottom: yVal,
        fontSize: objectLabelFontSize,
        color: 'black',
        border: '1px solid black',
        zIndex,
        boxShadow: '0px 0px 5px 5px #00000022',
        opacity: isTracked || top === entity.id ? 1 : 0.6,
      }}
      onClick={() => trackEntity(entity, `Virtual ${surfaceName}`)}
      onMouseOver={() => setTop(entity.id)}
      key={pos.id}
    >
      {getLabel(entity)}
    </div>
  );
};
