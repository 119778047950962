import { apiGatewayUrl, dopplerConfiguration, nodeEnv } from '../shared/constants';
import { HttpConfiguration } from './configureHttp';

const IS_PROD_OR_STAGING = ['prd', 'production', 'stg', 'staging'].includes(nodeEnv);

let SSEBaseConfig: HttpConfiguration = {};

export function GetGlobalSSEUrl(): string {
  const { identityToken } = SSEBaseConfig;
  const gatewayUrl = new URL(apiGatewayUrl);

  const prefix = IS_PROD_OR_STAGING ? '/unrestricted' : `/unrestricted-${dopplerConfiguration}`;
  gatewayUrl.pathname = `${prefix}/common-io`;
  gatewayUrl.searchParams.append('idToken', identityToken);
  return gatewayUrl.toString();
}

export function configureGlobalSSE(config: HttpConfiguration) {
  SSEBaseConfig = config;
}
