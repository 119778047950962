import React from 'react';

import { Box, Container, Divider, Typography } from '@mui/material';

import { AppPage } from '@/shared/components';
import { useDocumentTitle } from '@/shared/hooks/useDocumentTitle';
import UserManagement from './UserManagement';

const OrganizationSettingsPage = () => {
  const title = 'Organization Settings';

  useDocumentTitle(title);

  return (
    <AppPage title={title}>
      <Container maxWidth="xl" sx={{ marginTop: 4, marginBottom: 4 }}>
        <Typography variant="h6">{title}</Typography>
        <Typography variant="subtitle1" sx={{ marginTop: 2 }}>
          Manage your organization settings, including user management and organization members
        </Typography>
        <Divider sx={{ marginTop: 3 }} />
        <UserManagement />
      </Container>
    </AppPage>
  );
};

export default OrganizationSettingsPage;
