const enableGAtrackerStr = import.meta.env.VITE_TRACKER_ENABLE_GA;
const enableRudderstackTrackerStr = import.meta.env.VITE_TRACKER_ENABLE_RUDDERSTACK;
const enableLoggingTrackerStr = import.meta.env.VITE_TRACKER_ENABLE_LOGGING;
const enableBugSnagStr: string = import.meta.env.VITE_ENABLE_BUGSNAG;
const enableMixpanelStr: string = import.meta.env.VITE_TRACKER_ENABLE_MIXPANEL;

export const apiGatewayUrl: string = import.meta.env.VITE_API_GATEWAY_BASE_URL;
export const nodeEnv: string = import.meta.env.VITE_ENV;
export const isProduction: boolean = nodeEnv === 'production';
// GA
export const enableGAtracker: boolean = enableGAtrackerStr === 'true';
export const gaTrackingId: string = import.meta.env.VITE_GA_TRACKER_ID;

// rudderstack
export const enableRudderstackTracker: boolean = enableRudderstackTrackerStr === 'true';
export const rudderDataPlaneUrl: string = import.meta.env.VITE_RUDDER_DATA_PLANE_URL;
export const rudderWriteKey: string = import.meta.env.VITE_RUDDER_WRITE_KEY;

// sentry
export const enableBugSnag: boolean = enableBugSnagStr === 'true';
export const bugsnagApiKey: string = import.meta.env.VITE_BUGSNAG_API_KEY;

// mixpanel
export const enableMixpanel: boolean = enableMixpanelStr === 'true';
export const mixpanelApiKey: string = import.meta.env.VITE_TRACKER_MIXPANEL_API_KEY;

// launch darkly
export const launchDarkly: string = import.meta.env.VITE_LAUNCH_DARKLY_SDK_KEY;

export const dopplerConfiguration: string = import.meta.env.VITE_DOPPLER_CONFIGURATION;
export const enableLoggingTracker: boolean = enableLoggingTrackerStr === 'true';

export const cognitoPoolId: string = import.meta.env.VITE_COGNITO_POOL_ID;
export const cognitoClientId: string = import.meta.env.VITE_COGNITO_CLIENT_ID;
