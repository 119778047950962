import { useMutation } from '@tanstack/react-query';
/* eslint-disable import/prefer-default-export */
import { get } from '@truefit/http-utils';

export const useIPLookupQuery = () =>
  useMutation({
    mutationKey: ['ip-lookup'],
    mutationFn: async (ip: string) => {
      const { data } = await get<unknown>(`lookup/${ip}`);
      return data;
    },
    gcTime: 3600,
  });

export default useIPLookupQuery;
